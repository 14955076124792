.event-page {
  .Sticky-year {
    position: absolute;
    top: 0;
    left: 0;
    color: rgb(0 0 0 / 10%);
    transform-origin: bottom right;
    transform: translate3d(-50%, -50%, 0) rotate(-90deg);
    font-size: 180px;
    line-height: 0.7;
    height: 0.7em;
    @include secondaryFont;

    &.isSticky {
      position: fixed;
      left: 50%;
      z-index: 0;
    }

    html:lang(ja_JP) &,
    &:lang(ja_JP) {
      font-family: dinCondReg, arial !important;
    }

    @media (max-width: $legacy-screen-l) {
      margin-left: auto;
      font-size: 68px;
      top: 16vw;
      transform: translate3d(-53%, -50%, 0) rotate(-90deg);
    }
  }

  .event-page-container {
    position: relative;
    max-width: 1366px;
    margin: 0 auto;
    @media (max-width: $screen-medium) {
      margin: 0 auto 0;
    }

    .breadcrumbs {
      margin: 0 30px;

      @media (max-width: $screen-medium) {
        margin: 20px;
      }
    }

    .event-page-container-landings {
      overflow: hidden;
      box-sizing: border-box;
      margin-left: auto;
      margin-right: auto;
      max-width: 1280px;
      padding-left: 20px;
      padding-right: 20px;
      @media (max-width: $screen-medium) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .event-page-container-seemore {
      margin: 0 auto;
      margin-bottom: 60px;

      button,
      .button {
        @include primaryFont;
        font-weight: 500;
        padding: 10px 20px;
        border: 2px solid $black;
      }
    }

    .Item-infos-wrapper .cta-button {
      margin-top: 20px;
      line-height: 24px;
      border: none;
      &:hover {
        border: none;
      }
      @media (max-width: $screen-medium) {
        padding: 12px 40px !important;
      }
    }

    .title-h1 {
      text-align: center;
      margin-bottom: 24px;
      font-size: 68px;

      @media (max-width: $legacy-screen-l) {
        font-size: 38px;
      }
    }

    &.actualites-index-index {
      .title-h1 {
        font-size: 100px;
        text-align: left;
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 0;

        @media (max-width: $legacy-screen-l) {
          font-size: 50px;
        }
      }
    }
  }

  .Blog-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 143px;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    @media (max-width: $legacy-screen-l) {
      padding: 0 40px;
    }
  }

  .Blog-item {
    width: calc(50% - 10px);
    margin-bottom: 8px;
    @media (max-width: $legacy-screen-l) {
      width: 100%;
      margin-bottom: 40px;
    }

    .Blog-item-wrapper {
      display: flex;
      flex-direction: column;

      .Blog-item-image {
        background-color: #522323;
        width: 100%;

        &.Item--4_3 {
          background-color: #303446;
          position: relative;

          &::before {
            content: '';
            position: relative;
            width: 100%;
            padding-top: 75%;
            display: block;
          }

          .content-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;

            > img,
            video {
              object-fit: cover;
              position: relative;
              width: 100%;
              height: 100%;
            }

            > iframe {
              position: relative;
              width: 100%;
              height: 100%;
            }
          }
        }

        &.Item--16_9 {
          position: relative;
          background-color: #39b9a1;

          &::before {
            content: '';
            position: relative;
            width: 100%;
            padding-top: 56.5%;
            display: block;
          }

          .content-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;

            > img,
            video {
              object-fit: cover;
              position: relative;
              width: 100%;
              height: 100%;
            }

            > iframe {
              position: relative;
              width: 100%;
              height: 100%;
            }
          }
        }

        a {
          display: block;
          position: absolute;
        }

        .content-wrapper {
          overflow: hidden;
        }

        img {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform-origin: center;
          transition: transform 0.3s ease;
          will-change: transform;
        }
      }

      .Blog-item-video {
        background-color: #522323;
        width: 100%;

        video {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }

      .Blog-item-info-wrapper {
        display: flex;
        position: relative;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        padding: 36px 0;

        .Blog-item-type {
          font-size: 14px;
          @include primaryFont;
          font-weight: 500;
          text-transform: uppercase;
          margin-bottom: 10px;

          @media (max-width: $legacy-screen-l) {
            margin-top: 30px;
          }
        }

        .Blog-item-date {
          text-transform: uppercase;
          @include primaryFont;
          font-weight: 300;
          font-size: 12px;
          letter-spacing: 0.12px;
          color: #00000080;
          position: absolute;
          top: 10px;
          right: 0;

          @media (max-width: $legacy-screen-l) {
            top: 4px;
          }
        }

        .Blog-item-title {
          @include primaryFont;
          font-weight: 500;
          font-size: 17px;
          color: $black;
          margin: 0;
          margin-bottom: 4px;
          text-transform: uppercase;
          letter-spacing: 0.85px;
        }

        .Blog-item-description {
          font-size: 17px;
          color: $black;
          @include primaryFont;
          font-weight: 300;
          line-height: 1.4;
        }

        @media (max-width: $legacy-screen-l) {
          padding: 23px 0;
        }
      }

      a {
        text-decoration: none;

        &:hover {
          .Blog-item-image {
            img {
              transform: scale(1.05);
            }
          }

          .Blog-item-info-wrapper .Blog-item-title {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
