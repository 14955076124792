.personal-information {
  .heading {
    display: flex;
    gap: 10px;

    h2 {
      flex: 1;
    }

    a,
    button {
      display: flex;
      text-decoration: underline;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .content-description {
    margin-bottom: 24px;
  }

  .content {
    display: flex;
    gap: 24px;

    .informations {
      flex: 1;

      p {
        font-size: 18px;
      }

      p + p {
        margin-top: 4px;
      }

      .fullname {
        text-shadow: 0 0 0.05rem $black;
      }

      .email, .wechat {
        color: $grey;
        margin-top: 8px;
      }
    }

    @media (max-width: $screen-small) {
      display: block;

      button {
        margin-top: 24px;
      }
    }
  }
}
