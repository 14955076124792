.news-celebrity-view {
  margin-top: 0;
  max-width: 100%;
  padding: 0;

  .news-celebrity-view-content {
    @media (max-width: $legacy-screen-l) {
      width: 100%;
    }
  }

  .news-celebrity-view-content-main {
    position: relative;
    max-width: 1366px;
    margin: 0 auto;
    @media (max-width: $legacy-screen-l) {
      .breadcrumbs {
        padding: 0 20px;
      }
    }
    .breadcrumbs {
      padding: 20px 20px 45px 20px;
      max-width: 1280px;
      margin: 0 auto;
    }
  }

  .news-celebrity-view-breadcrumbs {
    position: relative;
    max-width: 1366px;
    margin: 20px auto;
  }

  .post-hero {
    text-align: center;
    padding-bottom: 35px;

    .post-title {
      font-size: 68px;
      @include secondaryFont;
      text-transform: uppercase;
      text-align: center;
      display: block;
      margin-bottom: 0;

      @media screen and (width <= 1080px) {
        font-size: 40px;
        margin-left: 0;
        text-align: center;
        margin-top: 15px;
      }
    }

    .post-date {
      text-transform: uppercase;
      @include primaryFont;
      font-weight: 500;
      font-weight: bold;
      font-size: 17px;
      margin-bottom: 20px;
      letter-spacing: 1px;

      @media (max-width: $legacy-screen-l) {
        margin-top: 0;
      }
    }
  }

  .breadcrumbs {
    margin-top: 30px;
  }

  .mpcss.post-products {
    .products-grid {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 70px 0;
      flex-direction: column;

      .products-references-title {
        margin: 0 0 40px;
        max-width: 200px;
        line-height: 1;
      }

      ol {
        width: 60%;
        max-width: 900px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.activated {
          position: relative;
          perspective-origin: 50%;
          margin-left: 20%;
          margin-right: 20%;

          @media (max-width: $legacy-screen-l) {
            margin-left: 20px;
            margin-right: 20px;
            width: 50%;
            margin: 10px 0 40px;
          }

          @media (max-width: $legacy-screen-s) {
            width: 80%;
          }

          .cardSlide {
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            margin-left: -50%;
            box-sizing: border-box;
          }
        }

        li {
          margin: 0 6px !important;
          align-items: center;
          justify-content: center;
          display: flex;
          width: 33.33333% !important;

          .product-item-info {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;

            > a {
              display: block;
              width: 100%;
              transform: translate(0%, -50%);
              position: absolute;
              top: 50%;

              img {
                width: 100%;
              }
            }

            &:hover {
              .product-item-details {
                opacity: 1;
              }
            }

            .product-item-details {
              position: absolute;
              inset: 0;
              opacity: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: opacity 0.3s ease;
              background-color: rgb(0 0 0 / 30%);
              color: $white;
              flex-direction: column;
              pointer-events: none;

              a {
                color: $white;
                font-size: 26px;
                @include secondaryFont;
                font-style: italic;
              }

              .actions-secondary {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .banner-top-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-height: 650px;
    width: 100%;
    align-items: center;
    overflow: hidden;
    background: $black;
    @media (max-width: $legacy-screen-l) {
      max-height: calc(100vh - 210px);
      height: 100vh;
    }

    .banner-top-container {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      img.img-responsive {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .ultranoir-container-video {
    max-height: 650px;
  }

  .post-type {
    font-size: 136px;
    @include secondaryFont;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 133.33%;

    span {
      display: block;
      text-align: center;
      transform-origin: bottom left;
      color: rgb(0 0 0 / 10%);
      transform: translate3d(0, 0, 0) rotate(-90deg);
    }

    @media screen and (width <= 1160px) {
      font-size: 110px;
    }

    @media (max-width: $legacy-screen-l) {
      display: none;
    }
  }

  .img-responsive {
    @media (min-width: $legacy-screen-l) {
      display: block;
      width: 100%;
      height: auto;
      margin: auto;
    }

    @media (max-width: $legacy-screen-l) {
      display: block;
      width: 100%;
      height: auto;
      margin: auto;
    }
  }

  .post-grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;

    @media (min-width: $legacy-screen-l) {
      padding: 0 175px;
    }

    @media (max-width: $legacy-screen-l) {
      margin-bottom: 10px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .post-grid-column {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 50%;
      padding-right: 10px;
      box-sizing: border-box;
      position: relative;

      &:nth-child(even) {
        padding-right: 0;
        padding-left: 10px;

        .post-grid-content {
          padding-left: 40px;
          padding-right: 0;
        }
      }

      img {
        @media (max-width: $legacy-screen-l) {
          width: 100%;
          margin: auto;
        }
      }

      @media (min-width: $legacy-screen-l) {
        &:nth-child(4n-1) {
          .content {
            text-align: right;

            > * {
              text-align: right !important;
            }
          }
        }

        &:nth-child(4n-2) {
          .content {
            text-align: left;

            > * {
              text-align: left !important;
            }
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        flex-basis: initial;
        width: 60%;
        margin: 0 auto;
        max-width: initial;
        padding: 0;

        &:nth-child(even) {
          padding: 0;
        }

        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(2) {
          order: 2;
        }

        &:nth-child(3) {
          order: 4;
        }

        &:nth-child(4) {
          order: 3;
        }

        &:nth-child(5) {
          order: 5;
        }

        &:nth-child(6) {
          order: 6;
        }

        &:nth-child(7) {
          order: 8;
        }

        &:nth-child(8) {
          order: 7;
        }

        &:nth-child(9) {
          order: 9;
        }

        &:nth-child(10) {
          order: 10;
        }

        &:nth-child(11) {
          order: 12;
        }

        &:nth-child(12) {
          order: 11;
        }
      }

      @media (max-width: $legacy-screen-l) {
        width: 100%;
        margin-bottom: 0;
      }

      .post-date {
        text-transform: uppercase;
        @include primaryFont;
        font-weight: 500;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 20px;
        letter-spacing: 1px;

        @media (max-width: $legacy-screen-l) {
          margin-top: 15px;
          margin-left: 0;
          margin-bottom: 0;
          text-align: center;
          font-size: 10px;
        }
      }

      .post-subtitle {
        margin-top: 0;
        @include primaryFont;
        margin-left: 60px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 17px;
        margin-bottom: 20px;
        letter-spacing: 1px;

        @media (max-width: $legacy-screen-l) {
          margin-left: 0;
          margin-bottom: 0;
          margin-top: 20px;
          text-align: center;
          font-size: 24px;
        }
      }

      .post-content {
        font-size: 20px;
        padding: 30px 60px;
        margin: 50px 0;

        @media (max-width: $legacy-screen-l) {
          font-size: 20px;
          padding: 0 60px;
          margin: 40px 0;
        }

        @media (max-width: $legacy-screen-s) {
          font-size: 16px;
          padding: 0 20px;
        }
      }

      &.content-wrapper {
        min-height: 450px;
        padding: 40px;
        box-sizing: border-box;

        @media (max-width: $legacy-screen-l) {
          padding: 30px 10px;
        }
      }

      .content {
        font-size: 17px;
        padding: 40px;
        @include primaryFont;
        font-weight: 300;

        @media (max-width: $legacy-screen-l) {
          padding: 10px 0 30px;

          p {
            text-align: left;
          }
        }

        @media (max-width: $legacy-screen-s) {
          font-size: 16px;
        }
      }
    }

    &-content {
      padding-left: 0;
      padding-right: 40px;
    }
  }

  .see-more-wrapper {
    padding: 0 100px;

    @media screen and (width <= 960px) {
      padding: 0;
    }
  }

  .swiper-moreposts {
    max-width: 100%;
    overflow: hidden;
    padding: 0;

    @media (max-width: $legacy-screen-l) {
      margin: 0 15%;
    }

    @media (max-width: $legacy-screen-s) {
      margin: 0 5%;
    }

    .swiper-slide:nth-child(4n + 2) .post-item,
    .swiper-slide:nth-child(4n + 2) .celebrities-item,
    .swiper-slide:nth-child(4n + 2) .thisism-item,
    .swiper-slide:nth-child(4n + 4) .post-item,
    .swiper-slide:nth-child(4n + 4) .celebrities-item,
    .swiper-slide:nth-child(4n + 4) .thisism-item {
      margin-top: 45px;

      @media (max-width: $legacy-screen-l) {
        margin-top: 0;
      }
    }

    .celebrities-item,
    .post-item,
    .thisism-item {
      position: relative;
      background-color: $black;

      .celebrities-item-image,
      .post-item-image,
      .thisism-item-image {
        transition: opacity 0.3s ease-in-out;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .celebrities-item-content,
      .post-item-content,
      .thisism-item-content {
        position: absolute;
        inset: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid $white;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        padding: 30px;

        .celebrities-item-date,
        .post-item-date,
        .thisism-item-date {
          text-transform: uppercase;
          @include primaryFont;
          color: $white;
          font-size: 14px;
          margin-top: -15px;
          text-align: center;
          font-weight: normal;
          letter-spacing: 1px;
        }

        .celebrities-item-name,
        .post-item-name,
        thisism-item-name {
          @include secondaryFont;
          color: $white;
          font-size: 36px;
          font-style: italic;
          text-align: center;
        }
      }

      &:hover {
        .celebrities-item-image,
        .post-item-image,
        .thisism-item-image {
          opacity: 0.7;
        }

        .celebrities-item-content,
        .post-item-content,
        .thisism-item-content {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .video-item-player {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 120px;
    display: block;
  }
}

.cta-button {
  display: inline-block;
  @include primaryFont;
  font-weight: 500;
  border: 2px solid $black;
  color: $black;
  text-transform: uppercase;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px $transparent;
  position: relative;
  transition-property: color;
  transition-duration: 0.2s;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  background: $white;
  margin: 0;
  letter-spacing: 1px;
  margin-top: 16px;
  font-size: 17px;
  text-align: center;
  transition: all 0.3s ease;
  padding: 5px 20px;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    background-color: $black;
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition: transform 0.2s ease-out;
  }

  &:hover,
  &:active {
    color: $white;
    text-decoration: none;
    background: $black;
    border: 2px solid $white;

    &::before {
      transform: scaleY(1);
    }

    span {
      color: #fff !important;
    }
  }

  &:focus {
    background: $black;
  }

  &.white,
  &--white {
    color: $white;
    border: 2px solid $white;
    background-color: $transparent;

    a {
      color: $white;
      text-decoration: none;
    }

    &::before {
      background-color: $white;
    }

    &:hover {
      color: $black;

      a {
        color: $black;
      }
    }

    &:focus {
      background-color: $transparent;
    }
  }
}
