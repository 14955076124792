.page-error {
  padding: 0 0 30px;

  h1,
  p {
    @include primaryFont;

    font-weight: 400;
  }

  h1 {
    font-size: 40px;
  }

  p {
    font-size: 18px;
  }

  p strong {
    font-weight: 500;
  }

  p,
  li {
    padding: 0 0 10px;
  }

  .error-body {
    padding: 20px 0;

    p + ul {
      margin-top: 10px;
    }

    ul {
      list-style: disc;
      padding: 0 0 0 15px;
    }
  }
}
