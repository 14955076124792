.main-contrast picture::after {
  opacity: 0;
}

.main-contrast
  picture:not(
    .selection-item picture,
    .Image-container picture,
    .Item-image-wrapper picture,
    .Blog-item picture,
    .Item picture,
    .gift picture,
    .product-page picture,
    figure.contrast picture,
    .main-contrast.page-homepage .header-homepage .header-media-source picture
  )::after {
  content: '';
  background-color: $transparent-black-62 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 1;
  opacity: 1;
}

.main-contrast.product-page .header-navigation-desktop-pushimage picture,
.main-contrast.page-homepage .header-homepage .header-media-source {
  &::after {
    content: '';
    background-color: $transparent-black-62 !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 1;
    opacity: 1;
  }
}

.main-contrast {
  video,
  .hero-image,
  .content-wrapper:not(.Item .content-wrapper):has(> img),
  .content-wrapper:not(.Item .content-wrapper):has(> video),
  .main-section__img:has(> img),
  .o-media__img:has(> img),
  figure.contrast {
    &::after {
      content: '';
      background-color: $transparent-black-62 !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 1;
      opacity: 1;
    }
  }

  .mosaic {
    .video-container {
      &::after {
        content: '';
        background-color: $transparent-black-62 !important;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }
  }

  .no-contrast * {
    &::after {
      display: none !important;
    }
  }

  // figure:has(picture)::after,
  .hero-image:has(picture)::after,
  .Item-video-wrapper:has(video)::after,
  .Item-video-wrapper:has(iframe)::after {
    display: none;
  }

  .cta-button,
  .light-clear {
    background-color: $white !important;
    color: $black !important;
    transition: 1s ease !important;

    &:hover {
      transform: scale(1.1) !important;
      transition: 1s ease !important;
    }
  }

  .main-contrast .cms-block-noheadless .Block-02 picture::after {
    z-index: 0 !important;
  }

  .cta-button:hover::before,
  .cms-block-noheadless .Item:hover .cta-button::before,
  .cms-block-noheadless .Item .cta-button,
  .cms-block-noheadless .Item .cta-button::before {
    background-color: $white !important;
    color: $black !important;
  }
}
