.custom-arrow-navigation {
  cursor: pointer;

  path {
    stroke: $black;
  }

  &.custom-arrow-navigation-disabled {
    cursor: default;

    path {
      stroke: $grey;
    }
  }

  &.custom-arrow-navigation-left {
    transform: rotate(180deg);
  }
}
