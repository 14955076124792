.default-addresses {
  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    & > :first-child {
      padding-bottom: 24px;
      border-bottom: 1px solid rgb(0 0 0 / 10%);
    }
  }

  .add-new-address {
    margin-top: 20px;
  }
}

.default-address {
  .heading {
    display: flex;

    @media (max-width: $screen-small) {
      display: block;

      button {
        padding: 0;
      }
    }

    h3 {
      flex: 1;
      font-size: 18px;
      font-weight: 450;
      line-height: 29px;
    }

    button {
      text-decoration: underline;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .resume-address {
    margin-bottom: 0;
    line-height: 23px;
  }

  .resume-address-fullname {
    color: $grey;
    text-shadow: none;
  }
}
