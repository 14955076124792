.custom-slider-pagination {
  display: flex;
  justify-content: center;
  gap: 3px;

  .custom-slider-pagination-bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $light-grey-400;

    &.custom-slider-pagination-bullet-active {
      background-color: $black;
      margin-right: 7px;
    }
  }
}
