body {
  @include primaryFont;

  overflow-x: hidden;

  @include transition;

  &.fullscreen {
    position: relative;
    overflow: hidden;
    height: 100%;

    @include transition;

    .header {
      z-index: 1;
    }
  }

  &.fullscreen-header {
    overflow: hidden;
    position: fixed;
    height: 100%;
  }

  &.no-overflow {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    max-width: 100vw !important;
  }
}

.flex {
  display: flex;
}

img {
  max-width: 100%;
}

button {
  color: $black;
}
