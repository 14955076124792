.page-suivi {
  padding: 50px 0 100px;

  h1 {
    @include primaryFont;

    font-weight: 400;
    font-size: 40px;
    text-align: center;
    width: 100%;
  }
}
