.modal .modal-carving {
  display: flex;
  gap: 78px;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;

  @media (max-width: $screen-small) {
    gap: calc(16px * 2);
    flex-direction: column;
  }

  .title-h4 {
    margin: 0;
  }

  .infos {
    font-size: 16px;
    color: $grey;
    margin-top: 10px;
  }

  picture {
    flex: 1;
    max-height: 600px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
      display: block;

      @media (max-width: $screen-small) {
        height: 300px;
      }
    }
  }

  section {
    width: 41%;
    text-align: left;
    padding-right: 40px;

    @media (max-width: $screen-small) {
      width: 100%;
      padding: 0;
    }

    .preview {
      width: 100%;
      height: 40px;
      background: $light-grey-100;
      border: none;
      text-align: center;
      font-size: 14px;
      font-weight: 500;

      &.stick {
        font-family: $futura !important;
      }

      &.english {
        font-family: $kunstler !important;
        font-size: 22px;
      }
    }

    .input-text {
      width: stretch;
      height: 40px;
      border-radius: 0;
      border: 2px solid $light-grey-100;
      padding: 0 15px;
    }

    input {
      outline: none;
      border-radius: 0;
    }

    .container-input {
      margin-top: 20px;

      label {
        display: block;
        font-size: 12px;
        color: $grey;
        margin-bottom: 5px;
      }
    }

    .craving-button-group {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      gap: 10px;

      button {
        font-size: 12px;
        width: 50%;
        display: block;
      }
    }

    p.small {
      font-size: 12px;
      color: $grey;
      margin-top: 20px;
    }

    .save-button-group {
      display: flex;
      margin-left: auto;
      justify-content: flex-end;
      margin-top: 30px;
      gap: 10px;
    }
  }
}
