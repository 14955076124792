.block-select {
  margin-top: -1px;
  width: 100%;
  border-bottom: 1px solid $light-grey-400;
  border-top: 1px solid $light-grey-400;

  &.disabled {
    pointer-events: none;
  }

  .select-container {
    width: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    gap: 5px;
    align-items: center;
    outline: none;
    height: 40px;

    label {
      white-space: nowrap;
      font-size: 16px;
      color: $grey;
      margin: 0;
      pointer-events: none;
    }

    .placeholder {
      @media (max-width: $screen-medium) {
        font-size: 14px;
      }

      .grey {
        color: $light-grey-300;
      }

      .black {
        color: $black;
      }
    }

    .right {
      margin-left: auto;
      height: 15px;
      display: flex;

      .size-guide {
        font-size: 14px;
        color: $transparent-black-50;
        white-space: nowrap;
        display: block;
        margin-right: 33px;
        text-decoration: underline;
      }

      .arrow {
        margin-left: auto;
        width: 16px !important;
        height: 16px !important;

        @include transition;

        &.rotate {
          transform: rotate(-180deg);

          @include transition;
        }
      }
    }
  }

  ul {
    max-height: 200px;
    overflow-y: auto;
    background-color: $white;
    width: 100%;
    padding: 15px 0;
    position: absolute;
    z-index: 1;
    opacity: 0;
    text-align-last: left;
    display: none;

    li {
      width: 100%;
      cursor: pointer;
      margin: 0;
      color: $grey;

      & + li {
        margin-top: 15px;
      }

      &:hover span::after,
      &:focus span::after,
      &:active span::after {
        left: 0;
        right: auto;
        width: 100%;
      }

      &.disabled-select {
        color: $grey;
        pointer-events: none;
        cursor: default;

        &::after {
          display: none;
        }
      }

      &.bold {
        color: $black;
      }
    }

    &.open {
      border-bottom: 1px solid $light-grey-200;
      opacity: 1;
      z-index: 5;
      display: block;
    }
  }
}
