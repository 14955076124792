.wrapper-logo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.wrapper-logo div {
  padding: 10px;
}

.icon-messika-logo {
  width: 30em;
  height: auto;
}

.icon-messika-monograme-large {
  width: 8em;
  height: auto;
}

.icon-messika-monograme-small {
  width: 8em;
  height: auto;
}
