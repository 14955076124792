$screen-vertical-small: 580px;

$screen-xsmall: 400px;
$screen-small: 600px;
$screen-medium: 960px;
$screen-large: 1280px;
$screen-extra-large: 1720px;

$black-transparent: rgb(0 0 0 / 3%);
$black-transparent-10: rgb(0 0 0 / 10%);
$color-white-transparent: rgb(255 255 255 / 10%);

// COLORS

$white: #fff;
$transparent: transparent;
$transparent-white-10: rgb(255 255 255 / 10%);
$transparent-white-20: rgb(255 255 255 / 10%);
$black: #000;

$transparent-black-15: rgb(0 0 0 / 15%);
$transparent-black-50: rgb(0 0 0 / 50%);
$transparent-black-62: rgb(0 0 0 / 62%);

$grey: #707070;
$light-grey-100: #f8f8f8;
$light-grey-200: #f0f0f0;
$light-grey-300: #838383;
$light-grey-400: #cecece;
$transparent-grey-10: rgb(255 255 255 / 30%);

$gradient: linear-gradient(180deg, transparent 0%, $transparent-black-62 100%);

$red: #b52a2a;

$transparent: transparent;

$light-grey-active: #f7f7f7;
