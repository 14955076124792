.celebrities-page {
  &:has(.banner-top-wrapper) {
    margin-top: 149px;
    @media (max-width: $legacy-screen-l) {
      margin-top: 71px;
    }
  }

  .celebrities-page-container {
    position: relative;
    max-width: 1366px;
    margin: 0 auto;

    .event-page-container-seemore {
      margin: 0 auto;
      margin-top: 60px;
      margin-bottom: 60px;

      button,
      .button {
        @include primaryFont;
        font-weight: 500;
        padding: 5px 45px;
        border: 2px solid $black;
      }
    }
  }

  .breadcrumbs {
    margin: 30px;
    @media (max-width: $screen-medium) {
      margin: 30px 0;
    }
  }

  .title-h1 {
    text-align: center;
    margin-bottom: 24px;
    font-size: 68px;

    @media (max-width: $legacy-screen-l) {
      font-size: 38px;
    }
  }

  .Blog-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 143px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 60px;

    @media (max-width: $legacy-screen-l) {
      padding: 0 40px;
    }
  }

  .celebrities-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: $legacy-screen-l) {
      display: block;
    }

    li {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .celebrities-item {
    flex-basis: calc(25% - ((20px * 3) / 4));
    margin-bottom: -20px;
    color: $black;
    text-decoration: none;

    a {
      color: $black;
      width: 100%;
      display: inline-block;
      text-decoration: none;
    }

    &:not(:nth-child(4n)) {
      margin-right: 20px;
    }

    &:nth-child(4n + 2),
    &:nth-child(4n + 4) {
      padding-top: 45px;
      margin-top: 0;
    }

    @media (max-width: $legacy-screen-l) {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      flex-basis: 100%;

      &:not(:nth-child(4n)) {
        margin-right: 0;
      }

      &:nth-child(4n + 2),
      &:nth-child(4n + 4) {
        margin-top: 0;
        padding-top: 0;
      }
    }

    &:hover {
      .celebrities-item-image img {
        transform: scale(1.05);
      }
    }

    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          width: 100% !important;
          max-height: 100%;
        }
      }

      .swiper-pagination {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        width: initial;
        bottom: 18px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        .swiper-pagination-bullet {
          display: inline-block;
          border-radius: 100%;
          width: 8px;
          height: 8px;
          border: 1px solid white;
          background-color: $transparent;

          &-active {
            background-color: $white;
          }
        }
      }
    }

    .Item--3_4 {
      background-color: #303446;
      position: relative;

      &::before {
        content: '';
        position: relative;
        width: 100%;
        padding-top: 133%;
        display: block;
      }

      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        > img,
        video {
          object-fit: cover;
          position: relative;
          width: 100%;
          height: 100%;
        }

        > iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .celebrities-item-image {
    width: 100%;
    overflow: hidden;

    &.content-wrapper {
      padding: 0 !important;
    }

    picture {
      display: block;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease;
      will-change: transform;
    }
  }

  .celebrities-item-content {
    text-align: center;

    @media (max-width: $legacy-screen-l) {
      display: flex;
      position: relative;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      text-align: left;
      padding: 23px 0;
      margin: 0;
    }
  }

  .celebrities-item-date {
    text-transform: uppercase;

    font-size: 16px;
    font-weight: normal;
    margin-top: 5px;
    display: inline-block;
    letter-spacing: 1px;

    @media (max-width: $legacy-screen-l) {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.12px;
      color: #00000080;
      position: absolute;
      right: 0;
      top: 4px;
    }
  }

  .celebrities-item-name {
    margin-top: 2px;
    margin-bottom: 0;
    @include primaryFont;
    font-weight: 500;
    font-size: 17px;
    text-transform: uppercase;

    @media (max-width: $legacy-screen-l) {
      font-size: 17px;
      color: $black;
      margin: 0;
      text-transform: uppercase;
      letter-spacing: 0.85px;
    }
  }

  .celebrities-item-desc {
    font-size: 17px;
    color: $black;
    line-height: 1.2;
    @include primaryFont;
    font-weight: 300;

    @media (min-width: $legacy-screen-l) {
      display: none;
    }
  }
}
