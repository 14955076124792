.account-nav {
  ul {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    @media (max-width: $screen-medium) {
      margin-top: 0;

      &.open {
        li {
          display: block;

          &.active::after {
            transform: rotate(180deg);

            @include transition;
          }
        }
      }
    }

    li {
      position: relative;

      @media (max-width: $screen-medium) {
        display: none;
      }

      .link :is(button, span) {
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        display: block;
        padding: 0;
        width: 100%;
        text-align: left;

        @media (max-width: $screen-medium) {
          margin: 16px 0 16px 16px;
        }

        &:hover {
          text-decoration: underline;

          @media (max-width: $screen-medium) {
            text-decoration: none;
          }
        }
      }

      .container {
        margin: 16px 0;
        padding-left: 30px;
        display: block;
        width: 100%;
        z-index: 9;
        position: relative;

        @media (max-width: $screen-medium) {
          margin: 16px 0;
          padding: 0;
        }
      }

      &.active {
        border-right: 2px solid $black;
        background: $light-grey-active;

        @media (max-width: $screen-medium) {
          background: $light-grey-active;
          display: block;
          order: -1;

          &::after {
            pointer-events: none;
            content: '';
            display: block;
            position: absolute;
            background-image: url('../../icons/icon-nav-arrow-down.svg');
            right: 10px;
            top: 13px;
            transform: rotate(0);
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            @include transition;
          }
        }

        .link :is(button, span) {
          font-weight: 450;
        }

        &::before {
          content: '';
          display: block;
          background: $light-grey-active;
          width: stretch;
          height: 100%;
          position: absolute;
          left: -30%;

          @media (max-width: $screen-medium) {
            display: none;
          }
        }
      }
    }
  }
}
