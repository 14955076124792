.mosaic {
  position: relative;
  min-height: 780px;

  picture {
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .video-container {
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


  .content {
    position: absolute;
    left: 50%;
    bottom: 39px;
    color: $white;
    height: auto;
    text-align: center;
    width: 90%;
    transform: translate3d(-50%, 0, 0);
    z-index: 2;

    @media (max-width: $screen-medium) {
      bottom: 24px;
    }

    .content-text {
      width: 92%;
      margin: 12px auto 0;

      &.text-black {
        color: $black;

        span,
        a {
          color: $black !important;
        }
      }

      @media (max-width: $screen-medium) {
        margin: 4px auto 0;
      }

      p,
      span,
      a {
        font-size: 18px;

        @media (max-width: $screen-medium) {
          font-size: 14px;
          line-height: 18px;
          width: 100%;
        }
      }

      span,
      a {
        text-decoration: underline;
        color: $white !important;
      }
    }

    .button {
      display: inline-block;
      margin: 30px auto 0;
      font-size: 18px;

      &.text-black {
        color: $black !important;
        border-color: $black !important;
      }

      @media (max-width: $screen-medium) {
        letter-spacing: 1px;
        padding: 8px 10px;
        margin-top: 15px;
        font-size: 13px;
      }
    }
  }
}
