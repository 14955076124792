label.switch {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0 15px;
  font-size: 14px;
  cursor: pointer;

  &.switch--white {
    color: $white;
  }

  &.switch--black {
    color: $black;
  }

  .checkbox {
    position: relative;
  }

  input {
    appearance: none;
    position: absolute;
    width: 26px !important;
    height: 10px;
    top: 0;
    left: 0;
  }

  span.label {
    font-size: 14px;
    margin-top: -7px;
  }

  span.decorator {
    position: relative;
    display: inline-block;
    margin-right: -2px;
    width: 34px;
    height: 26px;
    border-radius: 100%;
    vertical-align: text-bottom;
  }

  span.decorator::before {
    content: '';
    position: absolute;
    left: 0;
    width: 31px;
    height: 15px;
    background-color: $transparent-black-15;
    border-radius: 15px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
  }

  span.decorator::after {
    content: '';
    position: absolute;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: $white;
    border-radius: 100%;
    transform: translate3d(2.5px, 2.5px, 0);
    transition: all 0.2s ease-in-out;
  }

  &:active span.decorator::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }

  &:active input:checked + span.decorator::after {
    transform: translate3d(16px, 2px, 0);
  }

  input:checked + span.decorator::before {
    background-color: $black;
  }

  input:checked + span.decorator::after {
    transform: translate3d(18.5px, 2.5px, 0);
    background-color: $white;
  }
}
