.to-top {
  position: sticky;
  bottom: 43px;
  right: 0;
  margin-left: auto;
  margin-right: 140px;
  z-index: 99;
  margin-bottom: -48px;
  transform-origin: 50% calc(50% + 30px);
  transform: translateY(30px);
  transition: 1.5s ease;
  width: 48px;
  height: 48px;
  background-color: $black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $white;

  @include transition;

  cursor: pointer;

  &.onleft {
    right: auto;
    left: 0;
    margin-left: 20px;
    margin-right: auto;
  }

  &.alone:not(.onleft) {
    margin-right: 20px;
    right: 0;
  }

  &.alone.onleft {
    margin-left: 20px;
    left: 0;
  }

  @media (max-width: $screen-medium) {
    bottom: 40px;

    &.alone {
      bottom: 45px;
    }

    &.alone:not(.onleft) {
      right: 20px;
    }

    &.alone.onleft {
      left: 20px;
    }

    &.onleft {
      margin-left: 20px;
    }

    &:not(.onleft) {
      margin-right: 20px;
    }

    &:not(.alone, .onleft) {
      bottom: 100px;
    }
  }

  &:hover {
    transform: scale(1.2) translateY(30px);

    @include transition;
  }

  img,
  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
}

@media (width <= 1024px) {
  body:has(span.container-3PFIa) .to-top,
  .to-top {
    margin-right: 80px;
  }
}
