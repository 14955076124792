.input-radio {
  position: relative;
  margin: 0;
  display: flex;
  cursor: pointer;
  gap: 24px;

  @media (max-width: $screen-medium) {
    gap: 16px;
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:checked + span + span {
      color: $black;
    }

    &:checked + span.radio-case {
      border-color: $black;

      &::before {
        opacity: 1;
      }
    }
  }

  span.radio-case {
    position: relative;
    display: block;
    max-width: 16px;
    min-width: 16px;
    width: 16px;
    height: 16px;
    border: 1px solid $light-grey-300;
    border-radius: 50%;

    &::before {
      opacity: 0;
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $black;
      transition: opacity 0.25s ease-in-out;
    }
  }
}
