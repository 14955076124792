.my-orders {
  h4 {
    margin-bottom: calc(16px * 2);
  }

  article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $black-transparent-10;
    padding: 16px;
    margin-bottom: 16px;

    div {
      display: flex;
      flex-direction: row;
      gap: 8px;


      .date {
        font-weight: 14px;
      }
    }

    .price {
      font-weight: 450;
      text-align: right;
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 24px;

    thead {
      th {
        color: $grey;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 450;
      }
    }

    tbody {
      tr {
        margin-bottom: 16px;

        td {
          padding: calc(16px * 2) 10px;
          text-align: center;
          vertical-align: middle;
          font-size: 16px;
          border-bottom: 1px solid $black-transparent-10;
          border-top: 1px solid $black-transparent-10;

          &:first-child {
            text-transform: uppercase;
            font-weight: 450;
            border-left: 1px solid $black-transparent-10;
          }

          &:last-child {
            border-right: 1px solid $black-transparent-10;
          }

          .tracking {
            display: inline-block;
          }

          .invoice {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            color: $black;
            justify-content: center;
        
            &:hover {
              text-decoration: underline;
            }
        
            span {
              font-size: 16px;
            }
        
            svg {
              height: 17px;
            }
            
          }
        }
      }
    }
  }

  .invoice {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    margin-top: 25px;
    color: $black;

    &:hover {
      text-decoration: underline;
    }

    span {
      font-size: 16px;
    }

    svg {
      height: 17px;
    }

    @media (min-width: $screen-small) {
      margin-top: 0;
    }
  }

  .status,
  .date {
    color: $grey;
  }

  .div1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .div2 {
      display: flex;
      flex-direction: column;
    }
  }

  .div3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}
