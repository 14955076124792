.search-page {
  .header-media-category .header-media-content .subtitle {
    @include titleH3;

    letter-spacing: inherit;
  }

  .search-products-page {
    .search-products-page-blocs {
      display: flex;
      gap: 16px;
      margin-bottom: 50px;

      .search-products-page-bloc-filters {
        width: calc(25% - 8px);
        margin-top: 5px;
      }

      .search-products-page-bloc-results {
        width: calc(75% - 8px);

        @media (max-width: $screen-medium) {
          width: 100%;
        }

        .search-products-page-bloc-results-resume {
          margin-bottom: 20px;

          .search-products-page-bloc-results-resume-nbhits {
            font-size: 16px;
            margin-top: 1px;
          }

          .search-products-page-bloc-results-resume-desktop {
            padding: 9px 24px;
            display: flex;
            justify-content: space-between;
            position: relative;
          }

          .search-products-page-bloc-results-resume-mobile {
            .search-products-page-bloc-results-resume-nbhits {
              margin-top: 32px;

              @media (max-width: $screen-medium) {
                margin-top: 20px;
              }
            }
          }
        }

        .search-products-page-bloc-results-list {
          display: flex;
          gap: 16px;
          row-gap: 32px;
          flex-wrap: wrap;
          margin-bottom: 48px;

          .search-products-page-bloc-results-item {
            width: calc(100% / 3 - 32px / 3);

            a {
              display: block;
              text-decoration: none;
              color: $black;
            }

            @media (max-width: $screen-medium) {
              width: calc(50% - 8px);
            }
          }

          .search-products-item {
            .search-products-item-picture {
              display: block;
              overflow: hidden;
              margin-bottom: 12px;
              position: relative;

              img {
                width: 100%;
                object-fit: contain;
                height: auto;
              }

              // TAG
              .push-tag {
                font-size: 17px;
                text-transform: uppercase;
                margin: 13px 19px 0;
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                z-index: 2;
              }
            }

            .search-products-item-content {
              text-align: center;
              display: flex;
              flex-direction: column;

              .search-products-item-content-name {
                @include clamp;

                margin: 0 20px;
              }

              .search-products-item-content-description {
                @include clamp;

                font-size: 16px;
                line-height: 18px;
                color: $grey;
                margin-top: 7px;
              }

              .search-products-item-content-colorvariations {
                margin-top: 9px;
                color: $grey;
              }

              .search-products-item-content-price {
                margin-top: 9px;
                color: $black;

                span {
                  margin-right: 5px;
                }
              }
            }
          }
        }

        .search-products-page-bloc-results-seemore button {
          margin: 0 auto;
        }
      }
    }
  }

  .filter-product-form .filter-research-product-form {
    @media (max-width: $screen-medium) {
      margin: 20px 20px 45px;
    }

    .custom-accordion {
      margin-bottom: 15px;

      button {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;

        .accrodion-title {
          text-align: left;
        }
      }

      article.open .content {
        margin: 20px 0;
      }
    }
  }
}
