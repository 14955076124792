.selection-item {
  position: relative;

  &:hover {
    article.card .img-container .custom-slider-pagination,
    .button {
      opacity: 1;

      @media (max-width: $screen-medium) {
        opacity: 0;
      }
    }
  }

  .swiper-slide {
    overflow: hidden;
    opacity: 0;

    @media (min-width: $screen-medium) {
      transition: opacity 1s ease;
    }

    &.swiper-slide-active {
      opacity: 1;

    }
  }

  article.card {
    position: relative;

    a {
      text-decoration: none;
      color: $black;
      position: relative;
      display: block;
    }

    // ICON
    .selection-item-cart {
      padding: 4px;
      position: absolute;
      top: 7px;
      right: 5px;
      z-index: 2;
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
      pointer-events: auto;
      display: none;

      img,
      svg {
        width: 16px;
        height: 16px;
      }

      @media (max-width: $screen-medium) {
        display: flex;
      }
    }

    // TAG
    .push-tag {
      font-size: 17px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 13px 19px 0;
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      z-index: 2;
    }

    .img-container {
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        display: block;
        aspect-ratio: 1/1;
        object-fit: cover;
        transition: transform 0.3s ease-out;

        &:hover {
          @media (min-width: $screen-medium) {
            transform: scale(1.1);

          }
        }
      }

      .custom-slider-pagination {
        position: absolute;
        bottom: 20px;
        width: 100%;
        z-index: 1;
        opacity: 1;

        @media (max-width: $screen-medium) {
          opacity: 0;
        }
      }
    }

    figcaption {
      margin-top: 16px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 7px;

      @media (max-width: $screen-medium) {
        padding: 0 10px;
      }

      .title-h5 {
        @include clamp;

        margin: 0 20px;
        font-weight: 600;

        @media (max-width: $screen-medium) {
          font-size: 14.5px;
          line-height: 18px;
        }
      }

      .push-description {
        @include clamp;

        font-size: 16px;
        line-height: 18px;
        color: $grey;
        font-weight: 400;
        margin: 0;
      }

      .push-color {
        color: $grey;
        text-transform: lowercase;
        font-size: 16px;
        line-height: 16px;
      }

      .push-price {
        color: $black;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .button {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    display: block;
    opacity: 0;
    text-decoration: none;

    &--loading { opacity: 1; }
  }
}
