.register-page {
  margin: 0 0 200px;

  @media (max-width: $screen-medium) {
    margin: 0;
  }

  .layout:empty {
    display: none;
  }

  section.register {
    margin: 85px auto;
    max-width: 847px;
    display: flex;
    flex-direction: column;
    gap: 17px;

    @media (max-width: $screen-medium) {
      margin: 50px 20px;
    }

    .title-h3 {
      font-size: 50px;

      @media (max-width: $screen-medium) {
        font-size: 30.5px;
      }
    }

    .input-checkbox {
      gap: 16px;

      span.checkbox-case {
        border: 1px solid $black;
      }
    }

    .bloc {
      &.perso {
        margin-top: 23px;

        .title-h5 {
          margin-bottom: 10px;
        }
      }

      &.id {
        margin-top: 60px;

        .title-h5 {
          margin-bottom: 33px;
        }
      }

      .title {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
        margin: 24px 0;

        @media (max-width: $screen-medium) {
          justify-content: space-between;
          gap: 20px 20px;
        }
      }

      .input-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 24px;

        @media (max-width: $screen-medium) {
          grid-template-columns: repeat(1, 1fr);
          gap: 25px;
        }

        .text-wrapper {
          @media (max-width: $screen-medium) {
            display: flex;
            flex-direction: column-reverse;
          }

          .text-wrapper-label {
            @media (max-width: $screen-medium) {
              position: relative;
              bottom: inherit;
            }
          }

          input {
            width: 100%;

            @media (max-width: $screen-medium) {
              padding: 5px 0;
            }
          }
        }

        label {
          color: $light-grey-300;
          font-size: 14px;
        }

        & > * {
          grid-column: span 1;
        }

        &--wechat {
          margin-top: calc(16px * 2);
        }
      }

      &.bloc-terms {
        margin-top: 20px;

        @media (max-width: $screen-medium) {
          margin-top: 30px;

          span {
            font-size: 14px;
          }
        }
      }

      &.bloc-newsletter {
        margin-top: 30px;

        @media (max-width: $screen-medium) {
          margin-top: 25px;

          span {
            font-size: 14px;
          }
        }
      }

      &.bloc-terms,
      &.bloc-newsletter {
        label {
          color: $black;
        }
      }
    }

    .errors {
      padding: 16px 0;
      color: $red;
      text-align: left;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 50px;

      @media (max-width: $screen-medium) {
        margin-top: 30px;
      }
    }

    .container-switch {
      width: 100%;
      margin-top: 20px;

      .switch {
        justify-content: flex-end;
        margin-right: 0;
      }
    }

    label.switch span.label {
      color: $black;
      font-size: 16px;
      margin-left: 11px;
    }
  }

  .header-alert-notification {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 99;
    width: 100%;
  }

  .terms-of-use {
    gap: 16px;

    button {
      @media (max-width: $screen-medium) {
        font-size: 14px;
      }
    }

    .error {
      color: $red;
      position: absolute;
      left: 0;
      bottom: -15px;
      font-size: 12px;
      height: 10px;
    }
  }
}
