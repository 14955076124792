.layout {
  &.full {
    width: 100%;
  }

  &.padding {
    padding: 0 80px;

    @media (max-width: $screen-large) {
      padding: 0 30px;
    }

    @media (max-width: $screen-small) {
      padding: 0 17px;
    }
  }

  &.center {
    max-width: 850px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: $screen-medium) {
      max-width: inherit;
      padding: 0 30px;
    }

    @media (max-width: $screen-small) {
      padding: 0 17px;
    }
  }

  &.legacy {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: $screen-medium) {
      max-width: inherit;
      padding: 0 30px;
    }

    @media (max-width: $screen-small) {
      padding: 0 17px;
    }
  }

  &.legacy-large {
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: $screen-medium) {
      max-width: inherit;
      padding: 0 30px;
    }

    @media (max-width: $screen-small) {
      padding: 0 17px;
    }
  }
}
