.alma-page {
  header {
    position: fixed;
    width: 100%;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 35px;
    }
  }

  .alma-message {
    padding: 60px;
    height: 100vh;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;

    .content {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
    }

    h5 {
      color: $red;
    }

    .error {
      svg {
        fill: $red;
      }
    }
  }
}
