.modal-newsletter {
  .modal {
    @include primaryFont;

    padding: 0;
    width: 720px;
    max-width: 90vw;
    max-height: 80vh;
    height: auto;

    .modal-content {
      display: flex;
    }

    .newsletter-image {
      flex-basis: 50%;
      width: 50%;
      align-self: center;
    }

    .newsletter-content {
      display: flex;
      text-align: center;
      padding: 50px;
      box-sizing: border-box;
      background-color: $white;
      color: $black;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;

      @media (max-width: $screen-medium) {
        width: 100%;
      }
    }

    .title {
      text-transform: uppercase;
      font-size: 24px;
      margin: 0;
      font-weight: 100;
    }

    .content {
      margin-top: 30px;
      font-size: 18px;
      line-height: 1.42;
    }

    .button {
      margin-top: 30px;
    }
  }
}
