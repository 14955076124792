.sellers-page {
  .sellers-page-structure {
    margin-bottom: 40px;

    @media (max-width: $screen-medium) {
      padding: 0 16px;
    }

    .sellers-page-structure-title {
      @include primaryFont;

      font-size: 40px;
      font-weight: 200;
      text-transform: none;
      padding-left: 24px;
      margin-bottom: 40px;

      @media (max-width: $screen-medium) {
        font-size: 28px;
      }
    }
  }

  .sellers-page-blocs {
    display: flex;
    gap: 16px;
    padding-left: 24px;

    .sellers-page-bloc-filters {
      width: calc(100% * 0.33 - 8px);
      height: 100vh;
      overflow: auto;

      @media (max-width: $screen-medium) {
        height: auto;
        overflow: hidden;
      }
    }

    .sellers-filters-map {
      background-color: $light-grey-200;
      padding: 16px;
      margin-bottom: 24px;

      @media (max-width: $screen-medium) {
        margin-bottom: 16px;
      }

      .input-checkbox {
        gap: 12px;
      }

      .block-select {
        border-top: none;

        ul {
          border-bottom: 1px solid $light-grey-400;
        }
      }

      .sellers-page-filter-search {
        margin-bottom: 16px;

        .sellers-page-filter-search-cities {
          position: relative;
          margin-bottom: 12px;

          .map-input-cities {
            width: 100%;
            height: 40px;
            box-sizing: border-box;
            padding: 0 10px;
            border: none;
            border: 1px solid $light-grey-400;
            padding-right: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:focus {
              border: 1px solid $black;

              & + svg path {
                stroke: $black;
              }
            }
          }

          .sellers-page-filter-search-cities-icon {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            pointer-events: none;
            stroke: $light-grey-400;

            &.naver{
              top: 20px;
            }
          }
        }

        button {
          width: 100%;
        }
      }

      .sellers-page-filter-radius {
        margin-bottom: 16px;

        .sellers-page-filter-radius-unity {
          margin-bottom: 30px;

          > label {
            display: block;
            margin-right: 0;
            font-weight: 700;
            color: $black;
          }

          .block-select {
            display: inline-block;
            position: relative;

            label {
              min-width: 50px;
            }

            ul {
              background-color: $light-grey-200;
              padding: 8px 15px 8px 55px;
            }
          }
        }

        .sellers-page-filter-radius-range {
          position: relative;

          input {
            appearance: none;
            width: 100%;
            background-color: $light-grey-400;
            height: 5px;
            padding: 0;

            &::-webkit-slider-thumb {
              appearance: none;
              cursor: pointer;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: $black;

              @media (max-width: $screen-medium) {
                width: 20px;
                height: 20px;
              }
            }
          }

          .sellers-page-filter-radius-range-tooltip {
            display: block;
            width: 40px;
            position: absolute;
            top: -20px;
            left: 0;
            transform: translate(50%, 0);
            font-size: 14px;
            text-transform: lowercase;
            white-space: nowrap;
            text-align: center;
          }
        }
      }

      .sellers-page-filter-radius-unity,
      .sellers-filters-map-type,
      .sellers-filters-map-category {
        display: flex;
        gap: 16px;
        margin-bottom: 16px;
        align-items: center;

        & > * {
          width: 50%;
        }

        .sellers-filters-map-type-title,
        .sellers-filters-map-category-title {
          font-weight: 700;
          line-height: 1.2;

          @media (max-width: $screen-medium) {
            font-size: 14px;
          }
        }
      }

      .sellers-filters-map-type {
        .block-select {
          position: relative;

          ul {
            background-color: $light-grey-200;
          }
        }
      }

      .sellers-filters-map-category {
        .sellers-filters-map-category-list {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }

      .sellers-filters-map-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 8px;

        // avoid style on focus for button
        .button {
          &:focus {
            color: $black;

            &::before {
              transform: scaleY(0);
            }
          }

          &.black {
            &:focus {
              color: $white;

              &::before {
                transform: scaleY(0);
              }
            }
          }
        }

        @media (max-width: $screen-medium) {
          justify-content: space-between;
          gap: 16px;

          > * {
            flex: 1 1 0;
          }
        }
      }
    }

    .sellers-page-bloc-map {
      width: calc(100% * 0.67 - 8px);
      height: 85vh;

      .leaflet-container {
        width: 100%;
        height: 100%;
      }

      .map-sellers-baidu .BMap_shadow img {
        width: 0;
        height: 0;
      }
    }

    @media (max-width: $screen-medium) {
      flex-direction: column;
      padding: 0;
      gap: 0;

      .sellers-page-bloc-filters {
        width: 100%;
        height: auto;
      }

      .sellers-page-bloc-map {
        width: 100%;
        height: 300px;

        .naver-map-container{
          max-height: 300px;
        }
      }
    }
  }
}

.map-sellers-googlemap,
.map-sellers-baidu {
  height: 100%;
}

.map-sellers-leaflet {
  .map-sellers-leaflet-cluster {
    position: relative;

    .map-sellers-leaflet-cluster-image {
      display: block;
    }

    .map-sellers-leaflet-cluster-count {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-size: 14px;
    }
  }
}

.info-window-store-content {
  > img {
    padding-bottom: 8px;
    margin: auto;
  }

  @media (max-width: $screen-medium) {
    padding-bottom: 15px;
  }

  .info-window-store-content-title {
    margin-bottom: 12px;

    a {
      color: $black;
      text-decoration: none;
      font-weight: 600;
      outline: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .info-window-store-content-address {
    @include primaryFont;

    font-weight: 100;
    font-size: 16px;
    margin-bottom: 8px;

    @media (max-width: $screen-medium) {
      margin-bottom: 12px;
    }
  }

  .info-window-store-content-phone {
    font-weight: 100;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    span {
      @include primaryFont;

      font-weight: 100;
      font-size: 16px;
    }

    svg,
    span {
      vertical-align: middle;
    }
  }

  .info-window-store-content-description {
    @include primaryFont;

    font-weight: 100;
    font-size: 16px;
    margin-top: 10px;

    @media (max-width: $screen-medium) {
      display: none;
    }
  }
}

.pac-container {
  .pac-item {
    font-size: 11px;

    &:hover {
      cursor: pointer;
    }
  }

  .pac-item-query {
    font-size: 13px;
  }
}

.map-list-sellers-ctn {
  height: 100vh;
  max-height: 500px;
  overflow: hidden auto;
  padding-top: 30px;
  padding-left: 8px;
  padding-right: 8px;

  @media (max-height: $screen-vertical-small) {
    max-height: 350px;
  }
}

.map-list-sellers {
  .map-list-sellers-bytype {
    margin-bottom: 40px;

    .map-list-sellers-bytype-title {
      @include secondaryFont;

      text-align: center;
      font-size: 32px;
      text-transform: uppercase;
      margin: 0;
      line-height: inherit;
    }

    .map-list-sellers-bytype-list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 20px;

      @media (max-width: $screen-medium) {
        gap: 12px;
      }
    }

    .map-list-sellers-bytype-item-container {
      border-bottom: 1px solid $light-grey-200;
      cursor: pointer;
      border: 1px solid $transparent;

      &.active {
        border: 1px solid $black;
      }
    }

    .map-list-sellers-bytype-item {
      p,
      span {
        font-size: 16px;
        font-weight: 300;
        line-height: 23px;

        @media (max-width: $screen-large) {
          &.seller-item-openinghours-title {
            line-height: 16px;
          }
        }

        &.seller-item-openinghours-title-opening-today {
          white-space: nowrap;
        }
      }

      .map-list-sellers-bytype-item-informations {
        padding: 16px;
      }

      .map-list-sellers-bytype-item-title {
        @include primaryFont;

        font-size: 18px;
        letter-spacing: inherit;
        line-height: inherit;
        margin-bottom: 4px;

        a {
          font-size: 18px;
          letter-spacing: inherit;
          line-height: inherit;
          text-decoration: none;
          color: $black;
          font-weight: 700;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .map-list-sellers-bytype-item-categories {
        @include primaryFont;

        font-weight: 500;
        font-size: 16px;
        margin-bottom: 8px;
      }

      .map-list-sellers-bytype-item-address {
        margin-bottom: 8px;

        .map-list-sellers-bytype-item-address-phone {
          .map-list-sellers-bytype-item-address-phone-icon {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-right: 12px;
          }

          span {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

body {
  .gm-style .gm-style-iw-tc {
    @media (max-width: $screen-medium) {
      top: 46px;
    }
  }

  .gm-style .gm-style-iw-c {
    box-shadow: 2px 2px 10px rgba($black, 0.1);
    border-radius: 2px;

    @media (max-width: $screen-medium) {
      top: 45px;
      left: -50px;
      width: 100%;
      max-height: 200px !important;
      height: auto;
    }

    button {
      right: -3px !important;
      top: -3px !important;
    }
  }
}
