.cart-container {
  .modal {
    padding: 40px 0;

    @media (max-width: $screen-medium) {
      padding: 0 !important;
      height: 100vh;

      .modal-content {
        height: 100%;
      }

      .modal-cart {
        // Chrome
        height: 92.7vh;
        height: calc(var(--vh, 1vh) * 92.7);

        // Safari
        @supports (hanging-punctuation: first) and (font: -apple-system-body) and
          (-webkit-appearance: none) {
          height: 81vh;
          height: calc(var(--vh, 1vh) * 81);
        }

        .summary-cart {
          height: auto;
        }

        .summary {
          z-index: 999;
          background-color: $white;
          height: auto;
        }
      }
    }
  }
}
