// TODO rework style, because this css is coming from legacy project
.cms-block-noheadless {
  .Item {
    position: relative;
    box-sizing: border-box;

    .Title--rotated {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 64px;
      color: $black;
      text-transform: uppercase;
      @include secondaryFont;
      width: 133.33%;
      text-align: center;
      margin: 0;

      span {
        transform-origin: bottom left;
        display: block;
        transform: rotate(-90deg) translate3d(0, 0, 0);
        padding: 10px 0;
      }
    }

    .Item-desc {
      font-size: 17px;
      line-height: 24px;

      p {
        font-size: 17px;
      }
    }

    p {
      color: $black;
      text-decoration: none;
      width: 100%;
      padding: 0 20px;
    }

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        .image-container {
          img {
            transform: scale(1.05) translateZ(0);
          }
        }
      }
    }

    .image-container {
      img {
        &.only-desktop {
          display: block;
          @media (max-width: $screen-medium) {
            display: none;
          }
        }

        &.only-mobile {
          display: none;
          @media (max-width: $screen-medium) {
            display: block;
          }
        }

        @media (max-width: $legacy-screen-l) {
          min-width: initial;

          &.only-desktop {
            display: none;
          }

          &.only-mobile {
            display: block;
          }
        }
      }
    }

    * {
      box-sizing: border-box;
    }

    &-title {
      font-size: 18px;
      text-transform: uppercase;
      line-height: 1.4;
      color: $black;
      @include primaryFont;
      font-weight: 500;
      text-decoration: none;
      text-align: center;
      width: 100%;
      padding: 0 20px;

      &-01 {
        font-size: 68px;
        @include secondaryFont;
        position: absolute;
        bottom: 0;
        left: 0;
        color: $black;
        text-transform: uppercase;
        width: 133.33%;
        text-align: center;
        margin: 0;

        span {
          transform-origin: bottom left;
          display: block;
          transform: rotate(-90deg) translate3d(0, 0, 0);
          padding: 10px 0;
          text-align: center;
        }
      }

      &-02 {
        font-size: 17px;
        @include primaryFont;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    &-subtitle {
      color: $black;
      @include primaryFont;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration: none;
    }

    &-desc {
      color: $black;
      font-size: 17px;
      text-decoration: none;
      @include primaryFont;
      font-weight: 300;
    }

    &--video {
      position: relative;

      &::before {
        content: '';
        position: relative;
        width: 100%;
        padding-top: 133%;
        display: block;
      }

      .blog-video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        &:fullscreen {
          object-fit: contain;
        }

        &:full-screen {
          object-fit: contain;
        }

        &:-ms-full-screen {
          object-fit: contain;
        }

        &:full-screen {
          object-fit: contain;
        }
      }

      &.paused {
        &::after {
          content: '\e938';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          font-family: icomoon !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          font-size: 190%;
          background-color: $white;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          text-align: center;
          line-height: 50px;
          text-indent: 5px;
          pointer-events: none;
        }
      }
    }

    &--1_1,
    &--square {
      background-color: #913a3a;
      position: relative;

      &::before {
        content: '';
        position: relative;
        width: 100%;
        padding-top: 100%;
        display: block;
      }

      .title {
        z-index: 2 !important;
      }

      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        > img,
        video {
          object-fit: cover;
          position: relative;
          width: 100%;
          height: 100%;
        }

        > iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }

    &--2_1 {
      position: relative;

      &::before {
        content: '';
        position: relative;
        width: 100%;
        padding-top: 45.45%;
        display: block;
      }

      .content-wrapper {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        > img,
        video {
          object-fit: cover;
          position: relative;
          width: 100%;
          height: 100%;
        }

        > iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }

    &--3_4 {
      background-color: #303446;
      position: relative;

      &::before {
        content: '';
        position: relative;
        width: 100%;
        padding-top: 133%;
        display: block;
      }

      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        picture {
          display: block;
          height: 100%;
          overflow: hidden;
          img,
          video {
            object-fit: cover;
            position: relative;
            width: 100%;
            height: 100%;
          }
        }

        > img,
        video {
          object-fit: cover;
          position: relative;
          width: 100%;
          height: 100%;
        }

        > iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }

    &--4_3 {
      background-color: #303446;
      position: relative;

      &::before {
        content: '';
        position: relative;
        width: 100%;
        padding-top: 75%;
        display: block;
      }

      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        > img,
        video {
          object-fit: cover;
          position: relative;
          width: 100%;
          height: 100%;
        }

        > iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }

    &--10_6 {
      background-color: #303446;
      position: relative;

      &::before {
        content: '';
        position: relative;
        width: 100%;
        padding-top: 60%;
        display: block;
      }

      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        > img,
        video {
          object-fit: cover;
          position: relative;
          width: 100%;
          height: 100%;
        }

        > iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }

    &--16_9 {
      position: relative;
      background-color: #39b9a1;

      &::before {
        content: '';
        position: relative;
        width: 100%;
        padding-top: 56.5%;
        display: block;
      }

      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        > img,
        video {
          object-fit: cover;
          position: relative;
          width: 100%;
          height: 100%;
        }

        > iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }

    &--305_221 {
      background-color: #522323;
      position: relative;

      &::before {
        content: '';
        position: relative;
        width: 100%;
        padding-top: 72.459%;
        display: block;
      }

      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        > img,
        video {
          object-fit: cover;
          position: relative;
          width: 100%;
          height: 100%;
        }

        > iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }

    &-01 {
      // 3:4 ratio image BG, content rel
      > a {
        display: block;
        width: 100%;
        text-decoration: none;
      }

      .image-container {
        position: relative;
        width: 100%;
        padding-top: 133.33%;

        .image-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          overflow: hidden;
          height: 100%;

          img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            transform-origin: center;
            transition: all 0.3s ease;
            backface-visibility: hidden;

            &.only-desktop {
              @media (max-width: $legacy-screen-l) {
                display: none;
              }
            }

            &.only-mobile {
              @media screen and (width >= 1023px) {
                display: none;
              }
            }
          }

          .cta-button {
            position: absolute;
            left: 50%;
            bottom: 15%;
            transform: translateX(-50%);
          }
        }
      }

      .content-wrapper {
        position: relative;
      }
    }

    &-02 {
      // VISION MESSIKA HOME
      padding: 4%;
      background-color: $white;

      p {
        font-size: 18px;
        color: $black;
        @include primaryFont;
        font-weight: 300;
        padding: 0;
        line-height: 1.3;
        text-align: left;
      }

      .cta-button {
        margin: 30px auto 0;
        padding: 5px 20px;
        font-size: 17px;
        @include primaryFont;
        font-weight: 500;
        text-transform: uppercase;

        @media (max-width: $legacy-screen-l) {
          &.only-mobile {
            margin-top: 20px !important;
          }
        }
      }

      .Item {
        color: $black;

        &-title {
          clear: both;
          font-size: 68px;
          text-transform: uppercase;
          @include secondaryFont;
          color: $black;
          text-align: left;
          margin: 0;
          padding: 10px 0;
          line-height: 0.7;
          overflow: hidden;
          position: relative;
          z-index: 2;

          span {
            display: block;
          }
        }

        &-subtitle {
          clear: both;
          font-size: 17px;
          color: $black;
          text-transform: uppercase;
          @include primaryFont;
          font-weight: 500;
          padding: 0;
        }
      }
    }

    &-05 {
      padding: 0 0 0 91px;

      .Item {
        &-image {
          &-container {
            position: relative;
            width: 100%;
            padding-top: 133.33%;
          }

          &-wrapper {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;

            img {
              position: relative;
              object-fit: cover;
              display: block;
              width: 100%;
            }
          }
        }

        &-title {
          padding: 0;
          text-align: left;

          &-01 {
            position: absolute;
            bottom: 50%;
            left: -41px;
            width: 200vh;

            span {
              transform-origin: center;
              transform: translate3d(-50%, 50%, 0) rotate(-90deg);
            }
          }

          @media (max-width: $legacy-screen-l) {
            left: -25px;
          }
        }

        &-desc {
          padding: 0;
        }
      }
    }

    &-06 {
      display: flex;
      flex-direction: column;

      .Item {
        &-image {
          &-container {
            position: relative;
            width: 100%;
            padding-top: 133.33%;
          }

          &-wrapper {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;

            img {
              position: relative;
              object-fit: cover;
              display: block;
              width: 100%;
            }
          }
        }

        &-title {
          padding: 0;
          text-align: left;
        }

        &-desc {
          padding: 0;
        }
      }
    }

    &-07 {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .Item {
        display: flex;
        justify-content: space-between;

        &-infos {
          align-self: flex-end;
          width: 22.5%;
        }

        &-title {
          padding: 0;
          text-align: left;
        }

        &-desc {
          padding: 0;
        }

        &-image {
          &-container {
            position: relative;
            width: 73.5%;
          }

          &-wrapper {
            position: relative;
            width: 100%;

            img {
              position: relative;
              display: block;
              width: 100%;
            }
          }
        }

        &-video {
          &-container {
            width: 73.5%;
            padding-top: calc(0.735% * 56.21);
            position: relative;

            video {
              position: relative;
              width: 100%;
            }
          }

          &-wrapper {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            iframe {
              position: relative;
              width: 100%;
              height: 100%;
            }

            video {
              position: relative;
              object-fit: contain;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        flex-direction: column;

        .Item {
          &-video {
            &-container {
              order: 1 !important;
              width: 100% !important;
              padding-top: 0;
            }

            &-wrapper {
              position: relative;
            }
          }
        }

        .Item-infos {
          order: 2 !important;
          width: 100% !important;
        }
      }

      &--alt01 {
        .Item {
          &-infos {
            width: 48%;
          }

          &-image {
            &-container {
              position: relative;
              width: 48%;

              @media (max-width: $legacy-screen-l) {
                width: 100%;
              }
            }
          }

          &-video {
            &-container {
              width: 48%;
              padding-top: calc(0.48% * 56.21);
              position: relative;
            }
          }
        }
      }
    }

    &-08 {
      .Item-title {
        margin-bottom: 30px;
        font-size: 17px;
        color: $black;
        @include primaryFont;
        font-weight: 500;

        @media (max-width: $legacy-screen-l) {
          margin-bottom: 6px;
        }
      }

      .Item-desc {
        padding: 14px 0 20px;
        width: 100%;
        box-sizing: border-box;

        p {
          padding: 0;
          font-size: 17px;
        }
      }
    }

    &-09 {
      display: flex;
      flex-direction: column;

      .fat-title {
        color: #0003;
        position: absolute;
        bottom: 50%;
        left: -35px;
        font-size: 136px;
        text-transform: uppercase;
        width: 100vh;
        text-align: center;
        margin: 0;
        z-index: -1;
        transform-origin: center;
        transform: none;

        span {
          transform-origin: center;
          transform: translate3d(-50%, 50%, 0) rotate(-90deg);
        }

        @media (max-width: $legacy-screen-l) {
          font-size: 40px;
          color: $black;
          z-index: 2;
          left: 0;
          transform-origin: center;
          transform: none;

          span {
            transform-origin: center;
            transform: translate3d(-47%, 50%, 0) rotate(-90deg);
          }
        }
      }

      .Grid {
        box-sizing: border-box;
      }

      .Item {
        &-image {
          &-container {
            position: relative;
            width: 100%;
          }

          &-wrapper {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;

            img {
              position: relative;
              object-fit: cover;
              display: block;
              width: 100%;
            }
          }
        }

        &-infos {
          &-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 13%;
            box-sizing: border-box;
            z-index: 2;

            @media (min-width: $legacy-screen-l) {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              padding: 30px 13%;
              z-index: 2;
            }

            @media (max-width: $legacy-screen-l) {
              text-align: center;
              position: relative;
              padding: 0 0 50px;
              width: calc(80% - 80px);
              margin: 0 auto;
            }
          }
        }

        &-title {
          font-size: 68px;
          @include secondaryFont;
          text-transform: uppercase;
          text-align: left;
          padding: 0;

          @media (max-width: $legacy-screen-l) {
            font-size: 40px;
            display: none;
          }
        }

        &-date {
          @include primaryFont;
          font-weight: 400;
          text-transform: uppercase;
          text-align: right;
          padding: 10px 0;
        }

        &-subtitle {
          font-size: 17px;
          font-weight: 300;
        }

        &-desc {
          line-height: 1.2;
        }

        &:hover {
          .cta-button {
            color: $white;
            text-decoration: none;
            background: $white;

            &::before {
              transform: scaleY(1);
            }
          }
        }
      }

      .cta-button {
        align-self: center;

        @media (max-width: $legacy-screen-l) {
          margin: 0;
        }
      }

      &--white {
        .Item {
          &-infos {
            &-wrapper {
              color: $white;
            }
          }

          &-title,
          &-subtitle,
          &-desc {
            color: $white;
          }
        }

        .fat-title {
          .Block & {
            color: $white;

            @media (max-width: $legacy-screen-l) {
            }
          }
        }

        .cta-button {
          display: inline-block;
          @include primaryFont;
          border: 2px solid $white;
          color: $black;
          text-transform: uppercase;
          transform: perspective(1px) translateZ(0);
          box-shadow: 0 0 1px $transparent;
          position: relative;
          transition-property: color;
          transition-duration: 0.2s;
          text-decoration: none;
          cursor: pointer;
          border-radius: 0;
          background: $white;
          font-weight: 300;
          margin: 0;
          letter-spacing: 1px;
          margin-top: 16px;
          font-size: 17px;
          text-align: center;
          transition: all 0.3s ease;
          padding: 5px 20px;

          &::before {
            content: '';
            position: absolute;
            z-index: -1;
            inset: 0;
            background-color: $black;
            transform: scaleY(0);
            transform-origin: 50% 100%;
            transition: transform 0.2s ease-out;
          }

          &:hover,
          &:active {
            color: $white;
            text-decoration: none;
            background: $black;
            border: 2px solid $white;

            &::before {
              transform: scaleY(1);
            }

            span {
              color: $white !important;
            }
          }

          &:focus {
            background: $black;
          }
        }

        &:hover {
          .cta-button {
            color: $black;
            text-decoration: none;
            background: $black;

            &::before {
              transform: scaleY(1);
            }
          }
        }
      }

      &:hover {
        .cta-button {
          color: $white;
          text-decoration: none;
          background: $white;

          &::before {
            transform: scaleY(1);
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        a {
          background-color: $transparent;
        }
      }
    }

    &-10 {
      > a {
        position: relative;
        width: 100%;
        display: block;
      }

      .fat-title {
        .Block & {
          color: #0003;
          position: absolute;
          bottom: 50%;
          left: -26px;
          font-size: 40px;
          text-transform: uppercase;
          width: 100vh;
          text-align: center;
          margin: 0;
          z-index: -1;
          transform-origin: center;
          transform: translate3d(-50%, 50%, 0) rotate(-90deg);
        }
      }

      .Item {
        &-desc {
          @media (max-width: $legacy-screen-l) {
            text-align: center;
            max-width: 80%;
            margin: 0 auto;
          }
        }

        &-date {
          @include primaryFont;
          font-weight: 300;
          text-transform: uppercase;
          text-align: right;
          padding: 10px 0;
          font-size: 12px;
          letter-spacing: 0.12px;
          color: #00000080;
        }

        &-image-container {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;

          @media (max-width: $legacy-screen-l) {
            position: relative;

            img {
              transition: none !important;
              transform: none !important;
            }
          }

          @media (min-width: $legacy-screen-l) {
            overflow: hidden;
          }
        }

        &-infos {
          &-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 2;

            .cta-button {
              line-height: 1;
              margin: 60px 20px 0;
              border: none;
              padding: 5px 10px;
            }

            @media (max-width: $legacy-screen-l) {
              position: relative;
            }
          }
        }

        &--3_4,
        &--4_3 {
          @media (max-width: $legacy-screen-l) {
            &::before {
              display: none;
            }

            .content-wrapper {
              position: relative;
            }
          }
        }
      }

      .swiper {
        &-slide {
          @media (max-width: $legacy-screen-l) {
            .Item {
              &-infos-wrapper {
                position: relative;
              }
            }
          }
        }
      }

      &--white {
        .Item {
          &-infos {
            &-wrapper {
              color: $white;
            }
          }

          &-title,
          &-subtitle,
          &-desc {
            color: $white;
          }
        }

        .cta-button {
          display: inline-block;
          @include primaryFont;
          border: 2px solid $white;
          color: $black;
          text-transform: uppercase;
          transform: perspective(1px) translateZ(0);
          box-shadow: 0 0 1px $transparent;
          position: relative;
          transition-property: color;
          transition-duration: 0.2s;
          text-decoration: none;
          cursor: pointer;
          border-radius: 0;
          background: $white;
          font-weight: 300;
          margin: 0;
          letter-spacing: 1px;
          margin-top: 16px;
          font-size: 17px;
          text-align: center;
          transition: all 0.3s ease;
          padding: 5px 20px;

          &::before {
            content: '';
            position: absolute;
            z-index: -1;
            inset: 0;
            background-color: $black;
            transform: scaleY(0);
            transform-origin: 50% 100%;
            transition: transform 0.2s ease-out;
          }

          &:hover,
          &:active {
            color: $white;
            text-decoration: none;
            background: $black;
            border: 2px solid $white;

            &::before {
              transform: scaleY(1);
            }

            span {
              color: #fff !important;
            }
          }

          &:focus {
            background: $black;
          }
        }

        &:hover {
          .cta-button {
            color: $black;
            text-decoration: none;
            background: $black;

            &::before {
              transform: scaleY(1);
            }
          }
        }
      }

      &:hover {
        .cta-button {
          color: $white;
          text-decoration: none;
          background: $white;

          &::before {
            transform: scaleY(1);
          }
        }
      }

      @media (min-width: $legacy-screen-l) {
        .Item {
          &-title,
          &-subtitle,
          &-date,
          &-desc {
            display: none;
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        width: 100%;

        .Item {
          &-infos {
            &-wrapper {
              .cta-button {
                display: none;
              }
            }
          }
        }

        a {
          background-color: $transparent;
        }
      }
    }

    &-11 {
      .Item {
        &-title {
          @include secondaryFont;
          font-weight: 700;
          font-size: 36px;
          text-align: left;
          line-height: 1;
          padding: 0;
          margin: 0;
          margin-bottom: 25px;
        }

        &-desc {
          width: 100%;
          box-sizing: border-box;
          padding: 0;
          margin: 0;
        }
      }

      .List {
        margin-top: 20px;
      }
    }
  }

  .Item-title {
    font-weight: 500;
  }
}

.cms-block-noheadless .Item-desc {
  color: $black;
  font-size: 17px;
  text-decoration: none;
  font-weight: 300;
  a {
    color: $black;
    display: inline !important;
  }
}
