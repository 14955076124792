.customer-addresses-page {
  .customer-addresses {
    .customer-addresses-section {
      margin-bottom: 64px;

      @media (max-width: $screen-medium) {
        margin-bottom: 40px;
      }

      .customer-addresses-section-title {
        margin-bottom: 56px;

        @media (max-width: $screen-medium) {
          margin-bottom: 32px;
        }
      }
    }

    .customer-addresses-section-list {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .customer-address {
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 32px;

    @media (max-width: $screen-medium) {
      padding: 20px;
    }

    .customer-address-title {
      @include primaryFont;

      font-size: 18px;
      text-transform: uppercase;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    .customer-address-resume {
      margin-bottom: 20px;
    }

    .customer-address-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}
