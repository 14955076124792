.modal-return {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .item {
    margin: 48px 0;

    &:first-of-type h6 {
      &::before {
        content: '1';
      }
    }

    &:nth-of-type(2) h6 {
      &::before {
        content: '2';
      }
    }

    &:nth-of-type(3) h6 {
      &::before {
        content: '3';
      }
    }

    h6 {
      display: flex;
      align-items: center;
      gap: 32px;
      margin: 24px 0;
      font-size: 18px;

      @media (max-width: $screen-medium) {
        font-size: 16px;
      }

      &::before {
        content: '';
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        background: $black;
        color: $white;
      }
    }
  }

  section:nth-of-type(3) {
    div:first-of-type {
      margin-bottom: 32px;
    }
  }

  h2,
  h4,
  h6 {
    @include secondaryFont;

    color: $black;
    margin: 12px 0;
  }

  h2 {
    margin: 24px 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 40px;

    @media (max-width: $screen-medium) {
      font-size: 30px;
    }
  }

  h4 {
    font-size: 20px;

    @media (max-width: $screen-medium) {
      font-size: 18px;
    }
  }

  p {
    @include primaryFont;

    color: $black;
    font-size: 16px;
    text-align: left;

    strong {
      font-weight: 700;
    }

    a {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 40px;
    margin-bottom: 40px;

    @media (max-width: $screen-medium) {
      font-size: 24px;
    }
  }
}
