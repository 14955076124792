.modal-delete-customer-address {
  .modal-delete-customer-address-text {
    font-size: 18px;
    font-weight: 400;
    color: $grey;
    margin-bottom: 16px;

    @media (max-width: $screen-medium) {
      font-size: 18px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .button-group {
    display: flex;
    gap: 24px;
    padding: 0 40px;

    button {
      margin: 0;
    }

    @media (max-width: $screen-medium) {
      gap: 8px;
    }
  }
}
