.explorer-animation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  height: 27px;

  &.bullet {
    width: 10px;
    height: 22px;
    transform: rotate(90deg);
  }

  .explorer-animation-bullet {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $white;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 5s;
    display: block;

    @keyframes thirdBulletAnimation {
      5% {
        transform: translateY(0);
      }

      20% {
        transform: translateY(10px);
      }

      85% {
        transform: translateY(10px);
      }

      100% {
        transform: translateY(0);
      }
    }

    @keyframes secondBulletAnimation {
      20% {
        transform: translateY(0);
      }

      35% {
        transform: translateY(10px);
      }

      70% {
        transform: translateY(10px);
      }

      85% {
        transform: translateY(0);
      }
    }

    @keyframes firstBulletAnimation {
      35% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(10px);
      }

      55% {
        transform: translateY(10px);
      }

      70% {
        transform: translateY(0);
      }
    }

    &:nth-child(1) {
      animation-name: firstBulletAnimation;
    }

    &:nth-child(2) {
      animation-name: secondBulletAnimation;
    }

    &:nth-child(3) {
      animation-name: thirdBulletAnimation;
    }
  }
}
