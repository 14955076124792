.breadcrumbs {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;

    li {
      display: flex;
      margin-bottom: 0;
      line-height: 1.4;

      a,
      span {
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;

        @media (max-width: $screen-medium) {
          font-size: 13px;
        }
      }

      span {
        color: $black;
      }

      a {
        color: $grey;
        margin: auto;
      }

      &::after {
        content: '|';
        margin-left: 5px;
        color: $grey;
      }
    }

    li:last-child,
    .arrow-child {
      &::after {
        content: '';
      }
    }

    .arrow-child {
      height: 21px;

      img,
      svg {
        display: block;
        height: 21px;
        width: 21px;
        stroke-width: 21px;
        transform: rotate(180deg);
      }
    }
  }
}

.cart-breadcrumbs {
  height: 20px;
}

.go-back-to-cart {
  display: flex;
  align-items: center;
  text-decoration: none;

  svg {
    transform: rotate(180deg);
    width: 20px;
    height: 20px;
    fill: $grey;
    margin-right: 5px;
    margin-left: -4px;
  }

  span {
    color: $grey;
    font-size: 16px;
    text-decoration: none;

    &::after {
      background-color: $grey;
    }
  }
}
