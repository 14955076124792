.cms-block-noheadless {
  .hero-container {
    position: relative;
    overflow: hidden;

    &.text-black {
     .hero-content .hero-flex-container .cover-titles div {
        color: $black;
      }
    }

    &.black-subtitle {
      .hero-content .hero-flex-container .page-title-wrapper .page-title span {
        color: $black;
      }
    }

    &.black-slide-in {
      .hero-content .hero-flex-container .hero-description p {
        color: $black;
      }
    }

    .test {
      display: block;
    }

    .hero-image,
    .hero-video {
      height: 100%;
      position: relative;

      picture {
        height: 100%;
        width: 100%;
      }

      img {
        background-color: $black;
      }

      .ultranoir-container-video {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: relative;

        @media (max-width: $legacy-screen-l) {
          .ultranoir-container-video {
            flex: 1;
          }
        }
      }

      picture,
      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: relative;

        @media (max-width: $legacy-screen-l) {
          display: none;
        }
      }

      video {
        max-width: none;
        width: 100%;
        display: block;

        @media (max-width: $legacy-screen-l) {
          max-width: 100%;
          height: auto;
          width: auto;
          position: relative;
          display: inline-block;
          user-select: none;
        }
      }

      div.cover {
        min-width: 100%;
        height: 650px;
        background-size: cover;
        background-position: center;
      }
    }

    .hero-video {
      @media (max-width: $legacy-screen-l) {
        min-height: auto;
      }
    }

    .hero-content-wrapper {
      position: absolute;
      inset: 0;

      @media (max-width: $legacy-screen-l) {
        pointer-events: none;
      }

      .breadcrumbs {
        @media (max-width: $legacy-screen-l) {
          pointer-events: all;
        }
      }

      .global-wrapper {
        @media (min-width: $legacy-screen-l) {
          box-sizing: border-box;
          margin-left: auto;
          margin-right: auto;
          max-width: 1280px;
          padding-left: 20px;
          padding-right: 20px;
          width: 100%;
        }
      }
    }

    .hero-flex-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding: 0 11.6vw;
      top: calc(50% + 65px);
      transform: translateY(-50%);
      position: relative;
      z-index: 1;

      .cover-titles {
        margin-top: 20px;
        margin-bottom: 40px;
      }

      & > div {
        opacity: 1;
      }

      @media (max-width: $legacy-screen-l) {
        width: 90%;
        padding: 0;
        margin: auto;
        justify-content: flex-end;
        text-align: center;
        margin-bottom: 25px;
        position: relative;
        bottom: 25px;
        top: auto;
        transform: none;
      }
    }

    .hero-content {
      position: relative;
      height: 100%;

      .breadcrumbs {
        margin-left: 0;
        color: $white;
        position: absolute;
        top: 20px;
        left: 0;

        ul {
          li {
            color: $white;

            a {
              color: $white;
              font-weight: 600;
            }

            &:last-child {
              color: $white;
            }
          }
        }
      }

      h2,
      p {
        color: $white;
      }

      h2 {
        @include primaryFont;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 1px;

        span {
          font-weight: 500;
        }

        margin-top: 20px;

        @media (max-width: $legacy-screen-l) {
          font-size: 18px;
        }

        @media (max-width: $legacy-screen-s) {
          font-size: 14px;
        }

        &.page-title {
          span {
            color: $white;
          }
        }
      }

      .page-title-wrapper {
        h2 {
          margin: 0;

          @media (max-width: $legacy-screen-l) {
            margin-top: 15px;
            font-size: 17px;
          }
        }
      }

      p {
        @include primaryFont;
        font-size: 16px;
        max-width: 420px;
        padding-top: 2px;
        margin-bottom: 25px;
        font-weight: 400;

        @media (max-width: $legacy-screen-l) {
          font-size: 18px;
          margin-top: 0;
          line-height: 1;
          margin: 0 auto;
          margin-bottom: 20px;
          @include primaryFont;
          text-align: center;
          font-weight: 100;
        }
      }

      .cover-titles {
        @media (max-width: $legacy-screen-l) {
          margin: 0;
        }

        &.no-margin {
          margin-left: 0 !important;
        }

        .prefix {
          font-size: 30px;
          margin-right: -10px;

          @media (max-width: $legacy-screen-l) {
            font-size: 22px;
            margin-right: -5px;
          }

          &:empty {
            display: none;
          }
        }

        div {
          color: $white;
          font-size: 80px;
          @include secondaryFont;
          text-transform: uppercase;
          line-height: 1;

          @media (max-width: $legacy-screen-l) {
            font-size: 54px;
            text-align: center;
          }

          &:last-child {
            padding-left: 32px;

            @media (max-width: $legacy-screen-l) {
              padding: 0;
            }
          }
        }
      }

      .primary-cat {
        h1 {
          color: $white;
          font-size: 12px;
          @include primaryFont;
          font-weight: 500;
          text-transform: uppercase;
          line-height: 16px;
          margin-bottom: 20px;
          max-width: 700px;

          @media (max-width: $legacy-screen-l) {
            font-size: 12px;
            margin-left: 0;
            line-height: 1;
          }

          span:nth-child(2) {
            display: none;
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        display: flex;
      }
    }

    @media (max-width: $legacy-screen-l) {
      max-height: calc(100vh - 150px);
      background-color: $black;
    }

    /* iphone X : Portrait and Landscape */
    @media only screen and (minwidth: 375px) and (device-width <= 812px) and (min-device-pixel-ratio: 3) {
      height: calc(100vh - 190px);
      background-color: $black;
    }

    @media (min-width: $legacy-screen-l) {
      height: calc(100vh - 185px);
      min-height: 590px;
      background-color: $black;
    }
  }

  .move-container {
    .hero-container {
      @media (max-width: $legacy-screen-l) {
        height: calc(100vh - 150px);
      }
    }
  }

  .hero-infos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 15px;
    overflow: hidden;
    margin-top: 50px;

    h1,
    p {
      color: $black;
    }

    h1 {
      @include primaryFont;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 1px;
      margin-top: 20px;

      @media (max-width: $legacy-screen-l) {
        font-size: 18px;
      }
    }

    .page-title-wrapper {
      margin-bottom: 5px;

      h1 {
        margin: 0;

        @media (max-width: $legacy-screen-l) {
          margin-top: 15px;
          font-size: 17px;
        }
      }
    }

    p {
      font-size: 16px;
      max-width: 60%;
      padding-top: 2px;
      margin: 0 auto;
      @include primaryFont;
      font-weight: 300;

      @media (max-width: $legacy-screen-l) {
        font-size: 16px;
        margin-top: 0;
        line-height: 1;
        margin: 0 auto;
        margin-bottom: 20px;
        @include primaryFont;
        text-align: center;
        font-weight: 100;
      }
    }

    @media (max-width: $legacy-screen-l) {
      margin-top: 0;
      margin-bottom: 10px;

      .page-title-wrapper .page-title span {
        font-weight: 500;
      }

      .hero-description {
        @media (max-width: $legacy-screen-l) {
          margin: auto;

          p {
            font-size: 17px;
            line-height: 24px;
          }
        }

        p {
          width: 100%;
          max-width: 100%;
          margin: 0;
          @media (max-width: $screen-medium) {
            line-height: 16px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
