// TODO rework style, because this css is coming from legacy project

.cms-block-noheadless {
  h1 {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 40px;
    line-height: 1.1;
    @media (max-width: $screen-medium) {
      font-size: 26px;
      margin-bottom: 20px;
    }
  }

  h2 {
    font-weight: 300;
    line-height: 1.1;
    font-size: 26px;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  h3 {
    font-weight: 300;
    line-height: 1.1;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .title-h3 {
    @include titleH3;
  }

  p,
  li {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4;
  }

  p a {
    text-decoration: underline;
  }

  h4 {
    margin-top: 25px;
  }

  b,
  strong {
    font-weight: 400;
  }

  ul {
    list-style: disc;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
    border: 1px solid $black;

    td {
      padding: 8px 10px;
      border: 1px solid $black;
    }
  }

  .print-button-wrapper {
    text-align: right;
    margin-top: 50px;
  }

  .breadcrumbs {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    width: 100%;
  }

  &.cms-return-policy,
  &.cms-retours,
  &.cms-votre-commande-messika,
  &.cms-how-to-place-an-order,
  &.cms-como-comprar-online,
  &.cms-wie-kann-ich-online-einkaufen {
    .breadcrumbs-wrapper .breadcrumbs strong,
    .breadcrumbs-wrapper .breadcrumbs a {
      color: #606060 !important;
    }

    section:not(.benefit) {
      border-bottom: 1px solid $black;
      padding: 80px;

      @media (max-width: $legacy-screen-xs) {
        padding: 40px 0;
      }

      &:last-child {
        border: none;
      }

      > p {
        max-width: 50%;
        margin: auto;

        @media (max-width: $legacy-screen-xs) {
          max-width: 80%;
        }
      }

      h6 {
        margin: 20px 40px 20px 0;
      }

      .item {
        max-width: 50%;
        margin: auto;
        padding: 30px 0;
        position: relative;

        @media (max-width: $legacy-screen-xs) {
          max-width: 80%;
          padding-top: 100px;
        }

        &:first-of-type {
          padding-top: 0;

          @media (max-width: $legacy-screen-l) {
            padding-top: 100px;
          }

          &::before {
            content: '1';
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: $black;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            @include primaryFont;
            font-weight: 500;
            top: 0;
            left: 0;
            transform: translate(-200%, -30%);

            @media (max-width: $legacy-screen-l) {
              left: 50%;
              top: 50px;
              transform: translate(-50%, 0%);
            }
          }
        }

        &:nth-of-type(2) {
          @media (max-width: $legacy-screen-l) {
            padding-top: 120px;
          }

          &::before {
            content: '2';
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: $black;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            @include primaryFont;
            font-weight: 500;
            top: 50px;
            left: 0;
            transform: translate(-200%, -30%);

            @media (max-width: $legacy-screen-l) {
              left: 50%;
              top: 70px;
              transform: translate(-50%, 0%);
            }
          }
        }

        &:nth-of-type(3) {
          &::before {
            content: '3';
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: $black;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            @include primaryFont;
            font-weight: 500;
            top: 50px;
            left: 0;
            transform: translate(-200%, -30%);

            @media (max-width: $legacy-screen-l) {
              left: 50%;
              top: 50px;
              transform: translate(-50%, 0%);
            }
          }
        }

        &:nth-of-type(4) {
          &::before {
            content: '4';
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: $black;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            @include primaryFont;
            font-weight: 500;
            top: 50px;
            left: 0;
            transform: translate(-200%, -30%);

            @media (max-width: $legacy-screen-l) {
              left: 50%;
              top: 50px;
              transform: translate(-50%, 0%);
            }
          }
        }

        &:nth-of-type(5) {
          &::before {
            content: '5';
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: $black;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            @include primaryFont;
            font-weight: 500;
            top: 50px;
            left: 0;
            transform: translate(-200%, -30%);

            @media (max-width: $legacy-screen-l) {
              left: 50%;
              top: 50px;
              transform: translate(-50%, 0%);
            }
          }
        }
      }

      h2 {
        font-size: 14px;
        @include primaryFont;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
      }

      h4 {
        font-size: 36px;
        @include secondaryFont;
        font-style: italic;
        text-align: center;
        font-weight: 400;
      }

      > h4 {
        margin-bottom: 40px;
      }

      h6 {
        font-size: 14px;
        @include primaryFont;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      p {
        font-size: 16px;
      }

      .cta-container {
        text-align: center;
        margin-top: 60px;

        a {
          color: $black;
          font-size: 12px;
          @include primaryFont;
          font-weight: 500;
          padding: 12px 30px;
          border: 1px solid $black;
          text-transform: uppercase;
          text-decoration: none;
          transition: all 0.3s ease;

          &:hover {
            background-color: $black;
            color: $white;
          }
        }
      }

      .col-2 {
        display: flex;
        max-width: 75%;
        margin: auto;

        @media (max-width: $legacy-screen-l) {
          display: block;
        }

        > div {
          padding: 0 40px;

          @media (max-width: $legacy-screen-xs) {
            padding: 0;
          }

          &:last-child {
            @media (max-width: $legacy-screen-l) {
              margin-top: 25px;
            }
          }

          h6 {
            margin: 0;
          }
        }
      }
    }
  }

  @media (max-width: $screen-medium) {
    .news-celebrity-view-content {
      .banner-top-container {
        max-height: calc(100vh - 210px);
        height: 100vh;
        position: relative;
      }

      .fat-title {
        display: none;
      }
    }

    .event-page-container-seemore button,
    .event-page-container-seemore .button {
      margin-top: 20px;
      padding: 15px 38px !important;
      font-size: 17px;
    }

    .Block-09 .Grid-wrapper,
    .Block-09 .Video-container,
    .Block-09 .Image-container {
      margin-bottom: 0 !important;
    }
  }
}

.cms-le-diamant-en-heritage {
  @media (max-width: $screen-medium) {
    .page-main {
      margin-top: 50px;
      .column.main {
        padding: 0 !important;
      }
    }
  }
}

.page-layout-1column .Block-09 {
  width: 100%;
  max-width: 1166px;
  margin: 0 auto;
  padding: 0 105px;
  box-sizing: border-box;
  &.Block--full {
    max-width: inherit;
    margin: 0;
    padding: 0;
  }
}
.navigation,
.breadcrumbs,
.page-header .header.panel,
.header.content,
.page-main,
.page-wrapper > .widget,
.page-wrapper > .page-bottom,
.block.category.event,
.top-container,
.description-wrapper,
.global-wrapper {
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: $screen-medium) {
    max-width: 100%;
    margin: 0;
    padding: 0;
    min-width: 100%;
  }
}
