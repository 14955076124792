.page-newsletter {
  .layout-cms {
    padding: 0 200px;

    @media (max-width: $screen-large) {
      padding: 0 50px;
    }

    @media (max-width: $screen-medium) {
      padding: 0 30px;
    }

    @media (max-width: $screen-small) {
      padding: 0 17px;
    }
  }

  .container {
    margin-bottom: 50px;
  }

  .flex {
    display: flex;

    @media (max-width: $screen-medium) {
      display: block;
    }
  }

  .content {
    margin-top: 50px;

    @media (max-width: $screen-medium) {
      margin-top: 30px;
    }
  }

  .title {
    @include secondaryFont;

    font-size: 36px;
    text-transform: uppercase;
    line-height: inherit;

    @media (max-width: $screen-medium) {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .modal-contact {
    width: 100%;
    margin-top: 40px;

    @media (max-width: $screen-medium) {
      margin-top: 0;
    }
  }

  .left-side {
    width: 100%;
    margin: 0;

    @media (max-width: $screen-medium) {
      width: 100%;
      margin: 0;
    }

    .rgpd-intro {
      margin-top: 10px;
    }

    .form-control.checkbox {
      display: flex;
      gap: initial;
      flex-direction: initial;

      input {
        width: 16px;
        height: 16px;
        border: 1px solid $light-grey-300;
        appearance: none;
        display: block;
        padding: 0;
        margin: 0;
        cursor: pointer;

        &:checked {
          background: url('../../icons/icon-checked.svg') transparent no-repeat center center / 75%;
        }
      }

      label {
        @include primaryFont;

        margin-left: 10px;
        width: 90%;
        font-weight: 400;
        text-transform: none;
        font-size: 16px;
      }
    }

    .rgpd {
      font-size: 14px;
      margin-top: 30px;
      color: $grey;
      line-height: 1.2;
      padding-right: 40px;

      @media (max-width: $screen-medium) {
        margin-top: 0;
        padding-right: 0;
      }
    }

    .newsletter-unsubscription {
      margin-top: 60px;

      .desc {
        margin-top: 10px;
        font-size: 16px;
      }

      .modal-contact {
        margin-top: 20px;
      }
    }

    form {
      @media (max-width: $screen-medium) {
        padding-right: 0;
      }

      .button {
        display: block;
        width: fit-content;
        margin-top: 20px;

        @media (max-width: $screen-medium) {
          margin: 20px auto 0;
        }
      }
    }
  }

  .right-side {
    min-width: 360px;
    margin: 40px 0 0;

    @media (max-width: $screen-medium) {
      margin-top: 10px;
      min-width: inherit;
      width: 100%;
    }

    .button {
      width: fit-content;
      margin: 0 auto;
      display: block;

      @media (max-width: $screen-medium) {
        padding: 20px 30px;
      }
    }

    picture {
      margin-left: auto;
      margin-top: 49px;
      display: block;
      width: 100%;

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .newsletter-success {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;

    svg {
      fill: green;
      height: calc(16px * 2);
      width: calc(16px * 2);
    }
  }
}
