.selectslot {
  margin: 24px 0 0 40px;
  border: 1px solid $light-grey-200;
  padding: 24px 0 0;

  @media (max-width: $screen-small) {
    margin: 0;
    border: none;
  }

  p,
  label,
  button,
  a {
    font-size: 16px;

    @media (max-width: $screen-medium) {
      font-size: 14px;
    }
  }

  span,
  input {
    font-size: 12px;
  }

  .selectslot-dates-container {
    overflow: auto hidden;
  }

  .selectslot-text {
    text-align: center;
    margin-bottom: 24px;

    @media (max-width: $screen-medium) {
      margin-bottom: 18px;
    }

    .selectslot-text-title {
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 4px;

      @media (max-width: $screen-medium) {
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 1.4;
      }
    }

    .selectslot-text-desc {
      font-size: 16px;
      font-weight: 400;
      color: $grey;

      @media (max-width: $screen-medium) {
        font-size: 14px;
      }
    }
  }

  .selectslot-dates {
    display: flex;
    flex-direction: row;
    padding-bottom: 24px;

    .selectslot-date {
      width: calc(100% / 7);
      padding: 20px 10px;

      @media (max-width: $screen-medium) {
        width: calc(100vw / 3.4);
        min-width: calc(100vw / 3.4);
      }

      &:not(:last-child) {
        border-right: 1px solid $light-grey-200;
      }
    }

    .selectslot-date-title {
      text-align: center;
      margin-bottom: 12px;

      .selectslot-date-title-dayofweek {
        margin-bottom: 5px;
      }
    }

    .selectslot-date-slotsofday {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .selectslot-date-slot {
      border: 1px solid $light-grey-200;
      width: 100%;

      label {
        width: 100%;
        display: inline-block;
        padding: 8px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;

        @media (max-width: $screen-medium) {
          padding: 14px 8px;
        }
      }

      input[type='radio'] {
        opacity: 0;
        position: absolute;
        left: 0;

        &:disabled + label {
          cursor: default;
          color: $light-grey-300;
        }

        &:checked + label {
          background-color: $black;
          color: $white;
        }
      }

      &:hover,
      &:focus {
        input {
          &:not(:disabled) {
            & + label {
              background-color: $black;
              color: $white;
            }
          }
        }
      }
    }
  }
}
