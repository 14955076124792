section.alerts {
  table {
    margin-top: 50px;
    width: 100%;

    @media (max-width: $screen-medium) {
      margin-top: 30px;
    }

    th,
    td {
      text-align: left;
      padding: 8px 10px;
      font-size: 16px;
      vertical-align: middle;
    }

    thead {
      tr {
        border-bottom: 1px solid $light-grey-300;
      }
    }

    tbody {
      button {
        padding: 0;
      }

      td {
        @media (max-width: $screen-medium) {
          display: block;
          width: 100%;
          padding: 10px 0 0;

          &:not(:first-child),
          a,
          button {
            display: flex;
            width: 100%;
          }
        }

        .alert-label {
          display: block;
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 500;
          white-space: nowrap;
        }

        .info {
          margin-left: 5px;
          color: $grey;
        }
      }

      tr {
        @media (max-width: $screen-medium) {
          margin-bottom: 30px;
          display: block;
        }
      }

      figure {
        width: 45px;
        height: 45px;
        display: block;
        background-color: $grey;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .link:hover,
      .delete {
        text-decoration: underline;
      }

      .delete {
        font-size: 16px;
      }
    }
  }

  .goback {
    font-size: 16px;
    margin-top: 50px;
    display: flex;
    align-items: center;

    svg {
      transform: rotate(180deg);
      width: 20px;
    }

    span {
      text-decoration: underline;
    }
  }
}
