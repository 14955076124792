form {
  label {
    font-size: 16px;
    color: $grey;
    margin-right: 16px;

    @media (max-width: $screen-medium) {
      font-size: 14px;
    }
  }

  .date {
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $black;

    &::after {
      position: absolute;
      right: 0;
      bottom: 5px;
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      padding-right: 5px;
      background-color: $white;
      background-image: url('../../icons/icon-calendar.svg');
      background-repeat: no-repeat;
      background-position: center right;
      background-size: contain;
      pointer-events: none;

      @media (max-width: $screen-small) {
        width: 19px;
        height: 19px;
      }
    }

    label {
      font-size: 12px;
      color: $light-grey-300;
    }

    input {
      border: none;
      color: $black;
      padding: 2px 0;
      font-size: 16px;
      position: relative;

      @media (max-width: $screen-small) {
        font-size: 14px;
        appearance: none;
        background-color: $transparent;
        padding: 5px 0;
      }
    }
  }

  input {
    border-radius: 0;
  }
}
