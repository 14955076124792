.form-select {
  margin: 16px 0;

  @media (max-width: $screen-medium) {
    margin: 8px 0;
  }

  label {
    white-space: nowrap;
  }

  select {
    min-width: 66px;
    border: none;
    border-bottom: 1px solid $black;
    font-size: 16px;

    @media (max-width: $screen-medium) {
      position: relative;
      top: -2px;
      padding-bottom: 4px;
      font-size: 14px;
    }
  }
}
