.my-move-collection {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 100px 0;

  @media (max-width: $screen-medium) {
    display: flex;
    flex-direction: column-reverse;
    padding: 60px 0;
  }

  div {
    display: block;
    width: 48.61%;
    max-width: 392px;
    margin-left: 8.22%;
    margin-right: 8.22%;

    @media (max-width: $screen-medium) {
      width: 100%;
      max-width: inherit;
      min-width: inherit;
      margin: 0;
      text-align: center;
      padding: 0 16px;
    }

    h2 {
      @media (max-width: $screen-medium) {
        text-align: center;
      }
    }

    p {
      @include primaryFont;

      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      margin-top: 27px;
      margin-bottom: 24px;

      @media (max-width: $screen-medium) {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        margin: 7px 8px 0;
        margin-bottom: 16px;
      }
    }

    .button {
      @media (max-width: $screen-medium) {
        margin: auto;
      }
    }
  }

  figure,
  picture {
    position: relative;
    width: 47.12%;
    max-width: 630px;
    max-height: 698px;
    overflow: hidden;
    height: auto;
    display: block;

    @media (max-width: $screen-medium) {
      width: 78.5%;
      margin-bottom: 35px;
      height: auto;
      max-height: inherit;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 110.79%;
    }

    picture,
    img,
    source {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
