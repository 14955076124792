.modal-contact {
  width: 900px;

  @media (min-width: $screen-medium) {
    max-height: 80vh;
    overflow-y: auto;
  }

  @media (max-width: $screen-medium) {
    width: 600px;
    padding-top: 30px;
    padding-bottom: 40px;
  }

  @media (max-width: $screen-small) {
    width: 100%;
  }

  .success {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  h2 {
    font-size: 36px;
    margin-bottom: calc(16px * 2);
    margin-bottom: 30px;
    letter-spacing: 0;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 40px;

    @media (max-width: $screen-medium) {
      padding-right: 10px;
    }

    .form-control {
      display: flex;
      flex-direction: column;
      gap: calc(16px / 2);

      label {
        color: $black;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }

      &.date {
        border: none;

        &::after {
          right: 11px;
          bottom: 7px;
        }
      }

      .date input,
      input,
      select,
      textarea {
        padding: 0 10px;
        height: 35px;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        font-size: 14px;

        &:focus {
          border-color: $black;
        }
      }

      select {
        appearance: none;
        color: $black;
        background: $transparent url('../../icons/icon-nav-arrow-down.svg') center right 8px
          no-repeat;
        background-size: 16px;
      }

      textarea {
        @include primaryFont;

        height: 65px;
        padding: 10px;

        @media (max-width: $screen-medium) {
          height: 100px;
        }
      }
    }
  }
}
