// TODO rework style, because this css is coming from legacy project

#Contact-container {
  .Contact-title {
    padding: 0 0 25px;
    font-size: 40px;
    @include primaryFont;
    line-height: 1.1;
    font-weight: 300;
    text-transform: uppercase;
  }

  .Grid--contact {
    padding: 25px 0 110px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
    grid-auto-flow: row dense;
    position: relative;
    width: 100%;
  }

  .Grid--contact::before {
    background-color: #f6f6f6;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200vw;
    height: 100%;
  }

  .Grid--contact * {
    box-sizing: border-box;
  }

  .Grid--contact .item {
    position: relative;
    overflow: hidden;
    padding: 10%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    border: 1px solid #f6f6f6;
  }

  .Grid--contact .item .Title {
    margin-bottom: 15px;
    color: $black;
    justify-content: flex-start;
    text-align: left;
    text-transform: uppercase;
    @include secondaryFont;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 32px;
    margin-top: 0;
    min-height: 64px;
    @media (max-width: $screen-medium) {
      min-height: inherit;
    }
  }

  .Grid--contact .item .Desc {
    margin-bottom: 0;
    @include primaryFont;
    font-weight: 300;
    font-size: 17px;
    color: $black;
  }

  .Grid--contact .item .cta-button {
    width: 100%;
    color: $black;
    padding: 8px 20px;
    font-size: 12px;
    line-height: 1.6;
    cursor: pointer;
    transition: all 0.6s ease;

    &::before {
      transition: all 0.6s ease;
    }
  }

  .Grid--contact .item .cta-button:hover {
    color: $white;
  }

  .Grid--contact .item .Link-wrapper {
    margin-top: auto;
  }

  .Grid--contact .item .Link-wrapper .cta-button {
    margin-top: 7px;
  }

  .Grid--contact .item .Link-wrapper .cta-button:first-child {
    margin-top: 25px;
  }

  .Grid--contact .item--2x2 {
    padding: 5% 10% !important;
    grid-column: auto / span 2;
  }

  .Grid--contact .item--2x2 .Desc {
    align-items: center;
  }

  .Grid--contact .item--02 {
    justify-content: center;
    align-items: center;
    padding: 10%;
  }

  .Grid--contact .item--02 .Desc {
    text-align: center;
    align-items: center;
  }

  .Grid--contact .item--02 .cta-button {
    width: 245px;
    max-width: 100%;
    margin-top: 7px;
    color: $black;
    line-height: 1;
  }

  .Grid--contact .item--2x2 .Link-wrapper .cta-button:first-child {
    margin-top: 12px;
  }

  @media (max-width: $legacy-screen-l) {
    .Grid--contact {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: $legacy-screen-s) {
    .Grid--contact {
      grid-template-columns: 1fr;
    }

    .Grid--contact .item--2x2 {
      grid-column: auto;
    }
  }

  &.cms-page-view .page-main .column.main {
    margin: 0 auto !important;
  }

  .modalDialog {
    @include primaryFont;

    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 80%);
    z-index: 99999;
    opacity: 0;
    transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    pointer-events: none;
  }

  .modalDialog:target {
    opacity: 1;
    pointer-events: auto;
  }

  .label:not(.labelContrast) {
    @include primaryFont;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 12px;
    margin-bottom: 3px;
    display: inline-block;
  }

  .control,
  .select,
  option {
    @include primaryFont;
    font-weight: 300;
    font-size: 14px;
  }

  select {
    padding-left: 9px;
    padding-right: 9px;
  }

  .modalDialog > div {
    width: 400px;
    position: relative;
    margin: 10% auto;
    padding: 5px 20px 13px;
    border-radius: 10px;
    background: $white;
    background: linear-gradient(#fff, #999);
    background: linear-gradient(#fff, #999);
    background: linear-gradient(#fff, #999);
  }

  .modal-inner-wrap {
    overflow: hidden auto;
  }

  .modal-popup.modal-slide .modal-inner-wrap {
    @media (max-width: $legacy-screen-l) {
      top: 50%;
      left: 50%;
      margin: 0 auto;
      max-width: 560px;
      transform: translate(-50%, -50%);
      z-index: 100;
      position: fixed;
      width: calc(100vw - 30px);
      max-height: 80vh;
    }
  }

  .modal-popup._show .modal-inner-wrap {
    @media (min-width: $legacy-screen-l) {
      max-height: 80vh;
      max-width: 560px;
      top: 50%;
      margin: 0 auto;
      transform: translateY(-50%);
    }
  }

  .close:not(#close-newsletter-popin) {
    background: #606061;
    color: $white;
    line-height: 25px;
    position: absolute;
    right: -12px;
    text-align: center;
    top: -10px;
    width: 24px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 12px;
    border-radius: 12px;
    border-radius: 12px;
    box-shadow: 1px 1px 3px $black;
    box-shadow: 1px 1px 3px $black;
    box-shadow: 1px 1px 3px $black;
  }

  button:not(.aa-SubmitButton, .to-top) {
    background-color: #0ea3d6;
    border: none;
    color: $white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .close:not(#close-newsletter-popin):hover {
    background: #00d9ff;
  }

  input[type='text'],
  input[type='password'],
  input[type='url'],
  input[type='tel'],
  input[type='search'],
  input[type='number'],
  input[type='datetime'],
  input[type='email'],
  select {
    height: 36px;
    border-color: #e7e7e7;
  }

  textarea {
    border-color: #e7e7e7;
  }

  .modal-popup.modal-slide._show .modal-inner-wrap {
    background-color: $white;
  }
}
