.animate {
  .swiper-slide,
  .wrapper-push-item,
  .card,
  .services-content article,
  .benefit ul li {
    transform: translateY(50px);
    opacity: 0;

    &.slide-in {
      animation: slide-up 0.5s ease forwards;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }

      &:nth-child(4) {
        animation-delay: 0.6s;
      }

      &:nth-child(5) {
        animation-delay: 0.8s;
      }
    }
  }

  .title-h2,
  .title-h5,
  .gift figure,
  .media,
  .list-selection-wrapper {
    transform: translateY(50px);
    opacity: 0;

    &.slide-in {
      animation: slide-up 0.5s ease forwards;
    }
  }

  p,
  .push-price {
    transform: translateY(50px);
    opacity: 0;

    &.slide-in {
      animation: slide-up 0.5s ease forwards;
      animation-delay: 0.5s;
    }
  }

  .buttons {
    transform: translateY(50px);
    opacity: 0;

    &.slide-in {
      animation: slide-up 0.5s ease forwards;
      animation-delay: 0.5s;
    }
  }

  @keyframes slide-up {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.Item-09 .animate .fat-title {
  left: 65px !important;
  opacity: 0;
  transition: all 1s ease;

  @media (max-width: $screen-medium) {
    left: 0 !important;
    top: 30px !important;
  }

  &.slide-in {
    left: -35px !important;
    opacity: 1;
    transition: all 1s ease;

    @media (max-width: $screen-medium) {
      left: 0 !important;
      top: -77px !important;
    }
  }
}

.Block-10 {
  .animate .fat-title {
    left: 100px !important;
    opacity: 0;

    @include transition;

    &.slide-in {
      left: -100px !important;
      opacity: 1;

      @include transition;
    }
  }

  .Cols-2 .Item.Item-08:first-child:has(.Item--video) {
    .animate .fat-title {
      left: 280px !important;
      transform: translate3d(-50%, 0%, 0) rotate(-90deg);

      &.slide-in {
        left: 100px !important;
      }
    }
  }
}
