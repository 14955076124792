.hidden {
  display: none !important;
}

.lg-block {
  @media (width >= 960px) {
    display: block !important;
  }
}

.lg-flex {
  @media (width >= 960px) {
    display: flex !important;
  }
}

.lg-hidden {
  @media (width >= 960px) {
    display: none !important;
  }
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}
