.reassure {
  text-align: center;
  padding: 70px 0;
  background: $light-grey-100;

  @media (max-width: $screen-small) {
    padding: 60px 0;
  }

  ul {
    margin: 0 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      width: 33.3%;
      padding: 16px * 2;
      display: flex;
      flex-direction: column;
      gap: calc(16px / 2);

      @media (max-width: $screen-medium) {
        width: 50%;
      }

      @media (max-width: $screen-small) {
        width: 100%;
      }

      h4,
      h3 {
        @include primaryFont;

        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 2px;

        @media (max-width: $screen-small) {
          font-size: 14px;
        }
      }

      .phone,
      .email {
        text-decoration: underline;
        white-space: nowrap;
        cursor: pointer;
        color: $black;
      }

      .marge {
        margin-bottom: 14px;

        @media (max-width: $screen-medium) {
          margin-bottom: 0;
        }
      }

      .cb-icons {
        padding-top: 10px;
        padding-bottom: 9px;
        display: flex;
        flex-direction: row;
        gap: calc(16px / 2);
        justify-content: center;
      }

      .light {
        color: $grey;

        a {
          color: $grey;
        }
      }

      p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;

        @media (max-width: $screen-small) {
          font-size: 14px;
        }
      }
    }

    @media (max-width: $screen-small) {
      margin: 17px 0;
    }
  }
}
