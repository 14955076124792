.modal-overlay {
  position: fixed;
  inset: 0;
  z-index: 999999;
  display: flex;

  .overlay {
    background: $transparent-black-62;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
  }

  &.modal-center {
    justify-content: center;
    align-items: center;
    padding: 16px;

    @media (min-width: $screen-large) {
      padding: 0 180px;
    }

    @media (max-width: $screen-small) {
      padding: 0;
    }
  }

  &.modal-left {
    justify-content: flex-start;

    .modal {
      width: 34.4%;
      max-width: 496px;

      @media (max-width: $screen-medium) {
        width: 100%;
        max-width: inherit;
      }
    }
  }

  &.modal-right {
    justify-content: flex-end;

    &.cart-container {
      .modal {
        padding: 40px 0;

        @media (max-width: $screen-small) {
          padding-top: 27px;
          padding-bottom: 12px;
        }
      }
    }

    .modal {
      width: 34.4%;
      min-width: 496px;

      @media (max-width: $screen-medium) {
        width: 100%;
        max-width: inherit;
        min-width: 1px;
      }

      .close {
        right: auto;
        left: 16px;
      }
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  z-index: 9;
  max-height: 100%;
  opacity: 0;
  transition: opacity .2s;

  &.visible {
    opacity: 1;
  }


  @media (max-width: $screen-medium) {
    height: 100vh;
    overflow: hidden auto;
  }

  @media (max-width: $screen-small) {
    padding: 20px;
  }

  .button-group {
    button,
    a {
      margin-top: 16px;
      width: 100%;
    }
  }

  .modal-content {
    position: relative;
    height: 100%;
    overflow-y: auto;

    @media (max-width: $screen-medium) {
      height: auto;
      overflow: visible;
    }
  }

  .title-h4 {
    margin: 16px;
    text-align: center;
  }

  @media (max-width: $screen-small) {
    &.modal-right,
    &.modal-left,
    &.modal-center,
    & {
      max-width: initial;
      width: 100%;
      height: 100%;
    }
  }

  &.dark {
    background: $black;
    color: $white;

    .messika-logo img,
    .messika-logo svg path {
      fill: $white;
    }
  }

  &.light {
    background: $white;
    color: $black;

    .messika-logo img,
    .messika-logo svg path {
      fill: $black;
    }
  }

  .messika-logo {
    margin: 16px auto;

    svg {
      width: 112px;
      margin: 0 auto;
      display: block;
    }
  }

  .close {
    position: absolute;
    top: 12px;
    right: 6px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      transform: scale(0.8);
    }

    &.contrast {
      svg {
        fill: $white;
      }
    }

    &:hover {
      svg {
        transform: scale(0.8);
        animation: rotation 0.5s;

        @keyframes rotation {
          from {
            transform: scale(0.8) rotate(0deg);
          }

          to {
            transform: scale(0.8) rotate(90deg);
          }
        }
      }
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;

    @media (max-width: $screen-small) {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;

      > * {
        width: 100%;
        min-width: 100%;
      }
    }

    .title-h4 {
      margin-bottom: 16px;
      text-align: center;
    }
  }
}
