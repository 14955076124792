// DIN REGULAR
// @font-face {
//   font-family: DINCondensed;
//   src: url('../../fonts/Din/DINCondensed-Regular.woff2') format('woff2'),
//     url('../../fonts/Din/DINCondensed-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: BebasNeue;
  src: url('../../fonts/BebasNeue/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// FUTURA PT - Latin primary font
@font-face {
  font-family: 'Futura PT';
  src: url('../../fonts/Futura/futuraptlight-webfont.eot') format('eot'),
    url('../../fonts/Futura/futuraptlight-webfont.woff2') format('woff2'),
    url('../../fonts/Futura/futuraptlight-webfont.woff') format('woff'),
    url('../../fonts/Futura/futuraptlight-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../fonts/Futura/futuraptbook-webfont.eot') format('eot'),
    url('../../fonts/Futura/futuraptbook-webfont.woff2') format('woff2'),
    url('../../fonts/Futura/futuraptbook-webfont.woff') format('woff'),
    url('../../fonts/Futura/futuraptbook-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../fonts/Futura/futuraptdemi-webfont.eot') format('eot'),
    url('../../fonts/Futura/futuraptdemi-webfont.woff2') format('woff2'),
    url('../../fonts/Futura/futuraptdemi-webfont.woff') format('woff'),
    url('../../fonts/Futura/futuraptdemi-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../fonts/Futura/futuraptheavy-webfont.eot') format('eot'),
    url('../../fonts/Futura/futuraptheavy-webfont.woff2') format('woff2'),
    url('../../fonts/Futura/futuraptheavy-webfont.woff') format('woff'),
    url('../../fonts/Futura/futuraptheavy-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

// KUNSTLER
@font-face {
  font-family: Kunstler;
  src: url('../../fonts/Kunstler/KunstlerScript.ttf') format('truetype'),
    url('../../fonts/Kunstler/KunstlerScript.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}

// NotoSansJP - Japanese primary font
@font-face {
  font-family: NotoSansJP;
  src: url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-100.woff2') format('woff2'),
    url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-100.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: NotoSansJP;
  src: url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-300.woff2') format('woff2'),
    url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: NotoSansJP;
  src: url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-regular.woff2') format('woff2'),
    url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: NotoSansJP;
  src: url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-500.woff2') format('woff2'),
    url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: NotoSansJP;
  src: url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-700.woff2') format('woff2'),
    url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: NotoSansJP;
  src: url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-900.woff2') format('woff2'),
    url('../../fonts/NotoSansJP/noto-sans-jp-v23-latin_japanese-900.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// NotoSerifJP - Japanese secondary font
@font-face {
  font-family: NotoSerifJP;
  src: url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-200.woff2') format('woff2'),
    url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-200.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifJP;
  src: url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-300.woff2') format('woff2'),
    url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifJP;
  src: url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-regular.woff2') format('woff2'),
    url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifJP;
  src: url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-500.woff2') format('woff2'),
    url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifJP;
  src: url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-600.woff2') format('woff2'),
    url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-600.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifJP;
  src: url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-700.woff2') format('woff2'),
    url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifJP;
  src: url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-900.woff2') format('woff2'),
    url('../../fonts/NotoSerifJP/noto-serif-jp-v6-latin_japanese-900.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// NotoSansSC - Chinese primary font
@font-face {
  font-family: NotoSansSC;
  src: url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-100.woff2')
      format('woff2'),
    url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-100.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: NotoSansSC;
  src: url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-300.woff2')
      format('woff2'),
    url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: NotoSansSC;
  src: url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-500.woff2')
      format('woff2'),
    url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: NotoSansSC;
  src: url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-700.woff2')
      format('woff2'),
    url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: NotoSansSC;
  src: url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-900.woff2')
      format('woff2'),
    url('../../fonts/NotoSansSC/noto-sans-sc-v8-latin_chinese-simplified-900.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// NotoSerifSC - Chinese secondary font
@font-face {
  font-family: NotoSerifSC;
  src: url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-200.woff2')
      format('woff2'),
    url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-200.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifSC;
  src: url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-300.woff2')
      format('woff2'),
    url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifSC;
  src: url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-500.woff2')
      format('woff2'),
    url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifSC;
  src: url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-600.woff2')
      format('woff2'),
    url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-600.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifSC;
  src: url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-700.woff2')
      format('woff2'),
    url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: NotoSerifSC;
  src: url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-900.woff2')
      format('woff2'),
    url('../../fonts/NotoSerifSC/noto-serif-sc-v5-latin_chinese-simplified-900.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// AirArabia - Arab primary font
@font-face {
  font-family: AirArabia;
  src: url('../../fonts/AirArabiaLight/air-arabia-light-webfont.eot') format('eot'),
    url('../../fonts/AirArabiaLight/air-arabia-light-webfont.woff2') format('woff2'),
    url('../../fonts/AirArabiaLight/air-arabia-light-webfont.woff') format('woff'),
    url('../../fonts/AirArabiaLight/air-arabia-light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: AirArabia;
  src: url('../../fonts/AirArabiaNormal/air-arabia-normal-webfont.eot') format('eot'),
    url('../../fonts/AirArabiaNormal/air-arabia-normal-webfont.woff2') format('woff2'),
    url('../../fonts/AirArabiaNormal/air-arabia-normal-webfont.woff') format('woff'),
    url('../../fonts/AirArabiaNormal/air-arabia-normal-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: AirArabia;
  src: url('../../fonts/AirArabiaBold/air-arabia-bold-webfont.eot') format('eot'),
    url('../../fonts/AirArabiaBold/air-arabia-bold-webfont.woff2') format('woff2'),
    url('../../fonts/AirArabiaBold/air-arabia-bold-webfont.woff') format('woff'),
    url('../../fonts/AirArabiaBold/air-arabia-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

// ArabicCond - Arab secondary font
@font-face {
  font-family: ArabicCond;
  src: url('../../fonts/ArabicCondDemi/URWDINArabicCond-Demi.eot') format('eot'),
    url('../../fonts/ArabicCondDemi/URWDINArabicCond-Demi.woff2') format('woff2'),
    url('../../fonts/ArabicCondDemi/URWDINArabicCond-Demi.woff') format('woff'),
    url('../../fonts/ArabicCondDemi/URWDINArabicCond-Demi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Corean font
@font-face {
  font-family: MinSans;
  src: url('../../fonts/MinSans/MinSans-Light.woff2') format('woff2'),
    url('../../fonts/MinSans/MinSans-Light.woff') format('woff'),
    url('../../fonts/MinSans/MinSans-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: MinSans;
  src: url('../../fonts/MinSans/MinSans-Regular.woff2') format('woff2'),
    url('../../fonts/MinSans/MinSans-Regular.woff') format('woff'),
    url('../../fonts/MinSans/MinSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: MinSans;
  src: url('../../fonts/MinSans/MinSans-Bold.woff2') format('woff2'),
    url('../../fonts/MinSans/MinSans-Bold.woff') format('woff'),
    url('../../fonts/MinSans/MinSans-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: SLEIGothic;
  src: url('../../fonts/SLEIGothic/SLEIGothic.woff2') format('woff2'),
    url('../../fonts/SLEIGothic/SLEIGothic.woff') format('woff'),
    url('../../fonts/SLEIGothic/SLEIGothic.otf') format('opentype'),
    url('../../fonts/SLEIGothic/SLEIGothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// VARIABLES
$futura: 'Futura PT', arial, helvetica, sans-serif;
$bebas: 'bebasNeue', arial, helvetica, sans-serif;
$kunstler: 'Kunstler', arial, helvetica, sans-serif;

@mixin primaryFont {
  &:lang(fr),
  &:lang(de),
  &:lang(es),
  &:lang(ja),
  &:lang(it),
  &:lang(en-US),
  &:lang(fr-CH),
  &:lang(ar),
  &:lang(en-GB),
  &:lang(ci_fr),
  &:lang(su_fr),
  &:lang(ru_ru),
  &:lang(ch_cn),
  &:lang(en),
  &:lang(uk_en),
  &:lang(us_en),
  &:lang(tu_en),
  &:lang(su_en),
  &:lang(ru_en),
  &:lang(ci_en),
  &:lang(ar_en),
  &:lang(hk_en),
  &:lang(ko) {
    font-family: 'Futura PT', arial, helvetica, sans-serif;
  }

  &:lang(ru),
  &:lang(ru_ru) {
    font-family: arial, sans-serif;
  }

  &:lang(ja),
  &:lang(jp_ja) {
    font-family: NotoSansJP, arial, sans-serif;
  }

  &:lang(ar),
  &:lang(ar_ar),
  &:lang(sa_ar) {
    font-family: AirArabia, arial, sans-serif;
  }

  &:lang(zh),
  &:lang(zh_zh) {
    font-family: NotoSansSC, arial, sans-serif;
  }
}

@mixin secondaryFont {
  &:lang(fr),
  &:lang(de),
  &:lang(es),
  &:lang(ja),
  &:lang(it),
  &:lang(en-US),
  &:lang(fr-CH),
  &:lang(ar),
  &:lang(en-GB),
  &:lang(ci_fr),
  &:lang(su_fr),
  &:lang(ru_ru),
  &:lang(ch_cn),
  &:lang(en),
  &:lang(uk_en),
  &:lang(us_en),
  &:lang(tu_en),
  &:lang(su_en),
  &:lang(ru_en),
  &:lang(ci_en),
  &:lang(ar_en),
  &:lang(hk_en),
  &:lang(ko) {
    font-family: BebasNeue, arial, helvetica, sans-serif;
    letter-spacing: 0.01em !important;
  }

  &:lang(ru),
  &:lang(ru_ru) {
    font-family: arial, sans-serif;
  }

  &:lang(ja),
  &:lang(jp_ja) {
    font-family: NotoSerifJP, arial, sans-serif;
  }

  &:lang(ar),
  &:lang(ar_ar),
  &:lang(sa_ar) {
    font-family: ArabicCond, arial, sans-serif;
  }

  &:lang(zh),
  &:lang(zh_zh) {
    font-family: NotoSerifSC, arial, sans-serif;
  }
}
