.wishlist {
  display: flex;
  flex-direction: column;

  @media (max-width: $screen-medium) {
    padding: 0 10%;
  }

  .wish:not(:last-child) {
    border-bottom: 1px solid rgba(0 0 0 / 10%);
  }

  .wish {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;

    @media (max-width: $screen-medium) {
      display: block;
    }

    a.article-wish {
      display: flex;
      flex-direction: row;
      gap: 32px;

      @media (max-width: $screen-medium) {
        display: block;
        padding: 0;
      }
    }

    img {
      @media (max-width: $screen-medium) {
        width: 100%;
        height: 100%;
        display: block;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    .wish-title {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: $screen-medium) {
        text-align: center;
        gap: 10px;
        margin: 15px 0;
      }

      .title-h4 {
        font-size: 26px;

        @media (max-width: $screen-medium) {
          margin: 0;
          font-size: 21px;
        }
      }

      h2 {
        margin-bottom: 16px;
        font-size: 26px;

        @media (max-width: $screen-medium) {
          margin: 0;
        }

        /* stylelint-disable */
        @media (max-width: 1363px) {
          line-height: 25px;
        }
        /* stylelint-enable */
      }

      a {
        display: block;
      }

      p {
        color: $grey;

        /* stylelint-disable */
        @media (max-width: 1363px) {
          padding-bottom: 8px;
        }
        /* stylelint-enable */

      }

      .price {
        color: $black;
        font-size: 16px;
        font-weight: 600;

        /* stylelint-disable */
        @media (max-width: 1363px) {
          font-size: 15px;
        }
        /* stylelint-enable */
      }
    }

    .wish-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;

      button {
        min-width: 212px;
        max-width: 212px;

        &.remove {
          max-width: 125px;
          min-width: 125px;

          @media (max-width: $screen-small) {
            max-width: 125px;
            min-width: 1px;
          }
        }

        @media (max-width: $screen-small) {
          max-width: 125px;
          min-width: 162px;
        }
      }

      a {
        min-width: 212px;
        max-width: 212px;

        @media (max-width: $screen-small) {
          max-width: 125px;
          min-width: 162px;
        }
      }

      .unavailable-product {
        color: $grey;
      }

      .button-remove-to-wishlist {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          background-image: url('../../icons/icon-close.svg');
          background-size: 20px 20px;
          height: 20px;
          width: 20px;
          display: block;
          margin-right: 16px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      /* stylelint-disable */
      @media (min-width: 960px) and (max-width: 1363px) {
        flex-direction: column;
      }
      /* stylelint-enable */

    }
  }
}

.customer-wishlist-page {
  .share-wishlist {
    @media (max-width: $screen-medium) {
      border-top: 1px solid $transparent-black-15;
      padding: 30px 0;
      margin-top: 30px;
    }

    button {
      display: block;

      @media (max-width: $screen-medium) {
        margin: 0 auto;
      }
    }
  }
}

.stay-left {
  margin-left: auto;
  margin-top: 25px;
}
