.seller-item-openinghours {
  background-color: $light-grey-100;
  padding: 16px;

  .accordion {
    .accordion-item {
      border-bottom: none;

      &.open {
        .content {
          padding-top: 16px;
        }
      }
    }

    .accordion-item-button {
      gap: 12px;
      padding: 0;

      @media (max-width: $screen-extra-large) {
        align-items: start;
      }
    }

    .accrodion-title {
      text-transform: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      gap: 12px;

      @media (max-width: $screen-extra-large) {
        flex-direction: column;
        width: 100%;
      }

      .icon-clock {
        transform: none;
        animation: none;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        flex: none;

        @media (max-width: $screen-extra-large) {
          display: none;
        }
      }
    }
  }

  .seller-item-openinghours-days {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .seller-item-openinghours-days-item {
      display: flex;
      justify-content: space-between;
    }
  }
}
