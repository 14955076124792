.header-navigation-desktop {
  display: flex;
  justify-content: space-between;
  width: 907px;
  margin: 0 auto;

  .header-navigation-desktop-item {
    .header-navigation-desktop-item-title {
      @include primaryFont;

      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      text-decoration: none;
      color: $white;
      display: block;
      padding-bottom: 24px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        right: 0;
        width: 0;
        bottom: 0;
        background-color: $black;
        height: 3px;
        transition-property: width;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
      }

      &:hover {
        text-shadow: 0 0 0.5px $black, 0 0 0.5px $black, 0 0 0.5px $black;
      }

      &:hover::after,
      &:focus::after,
      &:active::after {
        left: 0;
        right: auto;
        width: 100%;
      }
    }
  }

  .header-navigation-desktop-submenu {
    display: none;
    background-color: $white;
    position: absolute;
    padding: 20px 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #cecece;

    &.header-navigation-desktop-submenu--push {
      .header-navigation-desktop-submenu-wrapper {
        display: flex;
        gap: 70px;
        width: auto;
        max-width: 1150px;
        margin: 0 auto;
      }

      .header-navigation-desktop-submenu-pushimage-wrapper {
        flex: 2;
        position: relative;
        margin-right: 16px;

        .header-navigation-desktop-pushimage {
          picture {
            position: relative !important;
            z-index: 1;
          }

          &.header-navigation-desktop-pushimage-category {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.active {
              display: block;
              z-index: 2;
            }
          }
        }
      }

      .header-navigation-desktop-submenu--push-blockcategories {
        flex: auto;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 20px 0;

        &.col1 {
          flex: 1.9;
        }

        &.col2 {
          flex: 1.8;
        }

        &.col3 {
          flex: 3;
        }
      }

      a {
        color: $black;
      }

      .header-navigation-desktop-blockcategory {
        &.twocolumns {
          .header-navigation-desktop-blockcategory-items {
            column-count: 2;
          }
        }

        .header-navigation-desktop-blockcategory-title {
          @include primaryFont;

          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
          line-height: 1.2;
          margin-bottom: 3px;
          color: $black;
          font-weight: 500;
        }

        .header-navigation-desktop-category {
          column-count: 1;
          padding: 2px 0 6px;

          a {
            @include primaryFont;

            font-size: 17px;
            color: $black;
            font-weight: 100;
            text-decoration: none;
            position: relative;
            line-height: 20px;

            @include underline;
          }
        }

        .header-navigation-desktop-blockcategory-seemore {
          max-width: 165px;
          text-decoration: underline;
          text-underline-position: under;
          text-decoration-color: $transparent;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.25;
          display: initial;

          &:hover {
            transition: 1s ease;
            text-decoration-color: $black;
          }
        }
      }
    }

    &.header-navigation-desktop-submenu--nopush {
      padding: 30px 0;

      .header-navigation-desktop-submenu--nopush-list {
        display: flex;
        justify-content: center;
        width: auto;
        max-width: 1236px;
        margin: 0 auto;
      }

      .header-navigation-desktop-submenu--nopush-item {
        width: 20%;
        margin: 0 4px;

        figure {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: 0.2;
            transition: all 0.3s ease-in-out;
            display: block;
          }
        }

        a {
          display: block;
          overflow: hidden;
          position: relative;
          transition: all 0.3s ease-in-out;
          width: 100%;
          height: 100%;

          span {
            @include secondaryFont;

            position: absolute;
            top: 50%;
            left: 50%;
            width: 70%;
            text-align: center;
            transform: translate(-50%, -50%);
            color: $white;
            font-size: 26px;
            font-weight: 100;
            z-index: 1;
            transition: all 0.3s ease-in-out;
          }

          figure {
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
              transition: all 0.3s ease-in-out;
            }

            &::before {
              z-index: 1;
            }
          }

          &:hover {
            figure {
              &::before {
                opacity: 0;
              }

              img {
                transform: scale(1.1);
                transition: all 0.3s ease-in-out;
                display: block;
              }
            }

            span {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .header-navigation-desktop-item:hover {
    .header-navigation-desktop-submenu {
      display: block;
      z-index: 2;
    }
  }

  li.header-navigation-desktop-item:nth-child(3) {
    .header-navigation-desktop-submenu.header-navigation-desktop-submenu--push
      .header-navigation-desktop-submenu--push-blockcategories.col1 {
      flex: 3 1;
    }
  }
}

.header-navigation-desktop-pushimage {
  display: block;
  cursor: pointer;

  picture {
    display: block;
    overflow: hidden;
    width: 275px;
    height: 367px;
    margin: 0 auto;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.2;
      transition: all 0.1s ease;
      z-index: 2;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    transition: all 0.1s ease;
  }

  .header-navigation-desktop-pushimage-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    text-transform: uppercase;
    transition: all 0.1s ease;
    width: calc(100% - 20px);
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    z-index: 2;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    .header-navigation-desktop-pushimage-title {
      opacity: 0;
    }

    picture {
      &::before {
        opacity: 0;
      }
    }
  }
}

html.uk_en,
html.su_en,
html.su_fr,
html.tu_en,
html.ci_en,
html.ci_fr,
html.ru_en {
  li.header-navigation-desktop-item.category-85
    .header-navigation-desktop-pushimage.header-navigation-desktop-pushimage-category {
    display: none !important;
  }
}
