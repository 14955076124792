.bloc-form-login {
  align-items: center;

  .errors {
    color: $red;
    text-align: left;
    margin-top: 0.5rem;
    width: 100%;
  }

  .title-login {
    text-align: center;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    @media (max-width: $screen-small) {
      margin-top: 30px;
    }

    .text-wrapper {
      @media (max-width: $screen-small) {
        margin-bottom: 48px;
      }

      &.email {
        margin-bottom: 35px;

        @media (max-width: $screen-small) {
          margin-bottom: 45px;
        }
      }

      &.password {
        margin-bottom: 8px;

        @media (max-width: $screen-small) {
          margin-bottom: 3px;
        }
      }

      span.error {
        position: absolute;
        bottom: inherit;
        margin-top: 5px;
        display: block;
        text-align: left;
      }
    }

    .text-wrapper,
    input {
      width: 100%;
    }

    button {
      width: fit-content;
      margin-top: 34px;
    }
  }

  label.switch span.label {
    color: $black;
  }

  @media (max-width: $screen-medium) {
    padding: 0;
    border: none;
  }
}
