.move-container {
  ul.wrapper-push {
    display: flex;
    flex-flow: row wrap;
    place-items: flex-start flex-start;
    gap: 16px;

    @media (max-width: $screen-medium) {
      gap: 8px;
    }

    li {
      width: calc(50% - 8px);
      margin: 0;

      @media (max-width: $screen-medium) {
        width: 100%;
      }
    }
  }

  article {
    &:has(figcaption .push-subtitle) {
      &:hover figcaption {
        @include transition;

        height: auto;
        min-height: 206px;

        @media (max-width: $screen-medium) {
          height: auto;
        }

        .push-subtitle {
          height: auto;
          opacity: 1;
          transform: translate(0);

          @include transition;
        }
      }
    }
  }

  a:not(.cta-button, .inline) {
    display: inline;
    width: 100%;
    height: 100%;
    color: $black;
  }

  figure {
    position: relative;
    height: 100%;
    overflow: hidden;

    img {
      display: block;

      @include transition;

      width: 100%;
      height: 100%;
      min-height: 601px;
      object-fit: cover;

      @include transition;

      @media (max-width: $screen-medium) {
        min-height: inherit;
        max-height: 442px;
      }
    }
  }

  figcaption {
    bottom: 0;
    position: absolute;
    padding: 50px 0;
    width: 100%;
    z-index: 2;
    text-align: center;
    background: linear-gradient(to bottom, 
      rgb(0 0 0 / 0%) 0%, 
      rgb(0 0 0 / 5%) 20%, 
      rgb(0 0 0 / 10%) 40%, 
      rgb(0 0 0 / 20%) 60%, 
      rgb(0 0 0 / 35%) 80%, 
      rgb(0 0 0 / 50%) 100%
    );
    height: auto;
    min-height: 160px;

    @include transition;

    @media (max-width: $screen-medium) {
      height: auto;
      padding: 25px 0;
    }

    .push-title,
    .title-h3 {
      @include titleH3;

      color: $white;
      margin: 0;
    }

    .push-subtitle {
      color: $white;
      font-size: 18px;
      line-height: 23px;
      width: 80%;
      margin: 0 auto;
      height: 0;
      opacity: 0;
      transform: translate(0, -45px);

      @include clamp;

      -webkit-line-clamp: 2;

      @include transition;

      @media (max-width: $screen-medium) {
        opacity: 1;
        height: auto;
        transform: translate(0);
        font-size: 14px;
        line-height: 18px;
      }
    }

    h2 {
      margin: 0;
    }
  }

  ul.wrapper-push > li {
    list-style-type: none;
  }
}
