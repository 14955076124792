.header-media-category {
  height: 82.8vh;
  min-height: 600px;
  margin-bottom: 16px;
  position: relative;

  @media (max-width: $screen-medium) {
    margin-bottom: 8px;
  }

  .header-media-source {
    height: 100%;
    width: 100%;
    display: block;

    picture {
      width: 100%;
      height: 100%;
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: $black;
      }
    }
  }

  .header-media-content {
    color: $white;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -30%, 0);
    z-index: 2;

    @media (max-width: $screen-medium) {
      width: 90%;
      margin: 0 auto;
      transform: translate3d(-50%, -43%, 0);
    }

    .title-h1 {
      display: flex;
      flex-wrap: wrap;
      gap: 0 20px;
      justify-content: center;

      &.text-black {
        color: $black;
      }

      @media (max-width: $screen-medium) {
        gap: 0 10px;
        line-height: 1;
      }
    }

    .header-media-descr-wrapper {
      margin: 33px auto 0;
      max-width: 650px;
    }

    .subtitle {
      display: flex;
      flex-wrap: wrap;
      gap: 0 10px;
      justify-content: center;
      font-size: 22px;
      line-height: 1.2;

      &.text-black {
        color: $black;
      }

      @media (max-width: $screen-medium) {
        margin-top: 10px;
      }
    }

    .descr {
      &.text-black {
        color: $black;
      }

      @media (max-width: $screen-medium) {
        width: 90%;
        margin: 10px auto 0;
      }

      p {
        margin-top: 14px;
        font-size: 22px;
        line-height: 27px;

        @media (max-width: $screen-medium) {
          font-size: 16px;
          margin: 0 auto;
          line-height: 20px;
        }
      }

      a {
        display: block;
        width: fit-content;
      }
    }

    .btn-see-more {
      text-decoration: underline;
      color: $white;
      font-size: 16px;
      margin-top: 5px;
      cursor: pointer;
      padding: 0;
    }
  }
}
