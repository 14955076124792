.checkout-page {
  .checkoutworkflow-stepper {
    .checkoutworkflow-step {
      padding: 34px 0;

      @media (max-width: $screen-medium) {
        padding: 30px 0;
      }

      &.disabled {
        pointer-events: none;
      }

      &:first-child {
        padding-top: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $light-grey-200;
      }

      &#checkoutworkflow-step-2 {
        padding-bottom: 20px;
      }

      .checkoutworkflow-step-header {
        .checkoutworkflow-step-header-title {
          margin-top: 0;
          text-transform: uppercase;

          span {
            @include secondaryFont;

            font-size: 32px;
            margin-right: 17px;

            @media (max-width: $screen-medium) {
              font-size: 23px;
              margin-right: 7px;
            }
          }
        }
      }

      .checkoutworkflow-step-content {
        display: none;
      }

      &.checkoutworkflow-step-status-validated {
        .checkoutworkflow-step-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 32px;

          @media (max-width: $screen-medium) {
            margin-bottom: 16px;
          }

          button {
            padding: 1px;
            display: inline-flex;
            align-items: center;
            gap: 6px;
            font-size: 16px;
            font-weight: 400;
            height: 1.3em;
            
            span {
              text-decoration: underline;
            }

            @media (max-width: $screen-medium) {
              font-size: 14px;
            }
          }
        }

        .checkoutworkflow-step-content {
          display: block;
        }
      }

      &.checkoutworkflow-step-status-opened {
        .checkoutworkflow-step-header {
          margin-bottom: 32px;

          @media (max-width: $screen-medium) {
            margin-bottom: 16px;
          }
        }

        .checkoutworkflow-step-content {
          display: block;
        }
      }

      &.checkoutworkflow-step-status-disabled {
        .checkoutworkflow-step-header-title {
          color: $grey;
        }
      }
    }
  }

  .cart-page-resume .cart-resume-detail-section {
    display: block !important;
  }

  .checkoutworkflow-deliveryaddress {
    .checkoutworkflow-deliveryaddress-addresses {
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .checkoutworkflow-deliveryaddress-radio {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .input-radio {
        display: flex;
      }

      button {
        text-decoration: underline;
        font-size: 16px;

        @media (max-width: $screen-medium) {
          font-size: 14px;
        }
      }
    }

    .checkoutworkflow-deliveryaddress-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      margin-right: 3px;

      .nextbutton {
        .button {
          &:hover {
            border-color: $black;
            color: $black;
            text-decoration: none;
          }
        }
      }
    }
  }

  .checkoutworkflow-deliverytype-validatedstep {
    color: $grey;

    .checkoutworkflow-deliverytype-validatedstep-type {
      color: $black;
      text-shadow: 0 0 0.05rem $black;
    }
  }

  .checkoutworkflow-gift {
    display: flex;
    gap: 40px;

    .checkoutworkflow-gift-img {
      max-width: 42.5%;
      width: 42.5%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .checkoutworkflow-gift-desc {
      margin-bottom: 19px;
      color: $light-grey-300;
      font-size: 16px;
      line-height: 1.3;

      @media (max-width: $screen-medium) {
        margin-bottom: 24px;
        font-size: 14px;
      }
    }

    .checkoutworkflow-gift-form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 14px;
      flex: 1;

      @media (max-width: $screen-medium) {
        margin-top: 0;
      }

      .checkoutworkflow-gift-form-radios {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 18px;

        @media (max-width: $screen-medium) {
          margin-bottom: 33px;
        }

        .input-radio span {
          font-size: 16px;

          @media (max-width: $screen-medium) {
            font-size: 14px;
          }
        }
      }

      .checkoutworkflow-gift-form-message {
        margin-bottom: 24px;

        &.hide {
          display: none;
        }

        textarea {
          @include primaryFont;

          box-sizing: border-box;
          resize: none;
          padding: 8px;
          border: 1px solid $light-grey-200;
          color: $light-grey-300;
          width: 100%;
          height: 70px;
          font-size: 14px;

          &::placeholder {
            color: $light-grey-300;
          }
        }

        .checkoutworkflow-gift-form-message-left {
          color: $grey;
          font-size: 12px;
          text-align: right;
        }
      }

      .checkoutworkflow-gift-buttons {
        text-align: right;
        justify-content: flex-end;

        .button:first-child {
          margin-right: 4px;
          border: none;

          @media (max-width: $screen-medium) {
            margin-right: 0;
          }

          &:hover {
            &::before {
              display: none;
            }

            color: $black !important;
          }
        }
      }
    }

    @media (max-width: $screen-medium) {
      & > * {
        width: 100%;
      }
    }
  }

  .checkoutworkflow-gift-validated {
    .checkoutworkflow-gift-validated-title {
      font-size: 18px;
      text-shadow: 0 0 0.05rem $black;

      @media (max-width: $screen-medium) {
        font-size: 17px;
      }
    }

    .checkoutworkflow-gift-validated-message {
      font-size: 18px;
      color: $grey;
    }
  }

  .checkoutworkflow-login-resume {
    color: $grey;
    line-height: 1.2;

    @media (max-width: $screen-medium) {
      font-size: 16px;
      line-height: 1.3;
    }

    .checkoutworkflow-login-resume-email {
      color: $black;
      text-shadow: 0 0 0.05rem $black;
      margin-left: 5px;
      overflow-wrap: break-word;
    }
  }

  .checkoutworkflow-login {
    display: flex;
    flex-direction: row;
    padding: 0 35px;

    & > .checkoutworkflow-login-form {
      width: 50%;

      .title-login {
        height: 63px;

        @media (max-width: $screen-medium) {
          height: auto;
        }
      }
    }

    h5 {
      text-align: center;
      text-transform: uppercase;
    }

    .checkoutworkflow-login-form {
      .checkoutworkflow-login-form-button {
        button {
          margin: 0 auto;
        }
      }

      .text-field-email,
      .text-field-password {
        input {
          border-bottom: 1px solid $light-grey-300;
          width: 100%;
        }
      }

      &.checkoutworkflow-login-form-guest {
        padding-right: 10%;
        position: relative;

        @media (max-width: $screen-medium) {
          padding-right: 0;
        }

        .content-title {
          height: 90px;
          margin-bottom: 35px;

          @media (max-width: $screen-medium) {
            margin: 0;
          }
        }

        .checkoutworkflow-login-form-button {
          margin-top: 54px;

          @media (max-width: $screen-medium) {
            margin: 0;
          }
        }

        .checkoutworkflow-login-form-content {
          h5 {
            margin-bottom: 12px;

            @media (max-width: $screen-medium) {
              font-size: 16px;
            }
          }

          p.message {
            text-align: center;
            font-size: 16px;
            color: $light-grey-300;

            @media (max-width: $screen-medium) {
              font-size: 14px;
            }
          }
        }

        &::after {
          content: '';
          display: block;
          border-right: 1px solid $light-grey-200;
          height: 100%;
          width: 1px;
          position: absolute;
          right: 0;
          top: 0;

          @media (max-width: $screen-medium) {
            display: none;
          }
        }
      }

      &.checkoutworkflow-login-form-account {
        padding-left: 10%;

        @media (max-width: $screen-medium) {
          padding-right: 0;
        }

        input {
          border-bottom: 1px solid $light-grey-300;
        }

        .container {
          width: 100%;

          .container-switch {
            display: none;
          }

          .forgoten-password {
            text-decoration: underline;
            margin-left: auto;
            font-size: 12px;
          }
        }

        .checkoutworkflow-login-form-content {
          padding-bottom: 30px;

          h5 {
            margin-bottom: 19px;

            @media (max-width: $screen-medium) {
              margin-bottom: 28px;
            }
          }

          .checkoutworkflow-login-form-forgot-password {
            text-align: right;
            margin: 0;
            padding: 0;
            width: 100%;
            margin-left: auto;
            display: block;

            a {
              color: $black;
              font-size: 12px;
              text-decoration: underline;
            }
          }

          .text-field-email {
            margin-bottom: 20px;

            @media (max-width: $screen-medium) {
              margin-bottom: 33px;
            }
          }

          .text-field-password {
            margin-bottom: 4px;
          }
        }
      }
    }

    @media (max-width: $screen-medium) {
      flex-direction: column;
      padding: 0 24px;

      & > .checkoutworkflow-login-form {
        width: 100%;
      }

      .checkoutworkflow-login-form {
        display: block;

        &.checkoutworkflow-login-form-guest {
          border-bottom: 1px solid $light-grey-200;
          padding: 0 0 36px;

          .checkoutworkflow-login-form-content {
            border-right: none;
            padding: 0;
            margin-bottom: 30px;
          }
        }

        &.checkoutworkflow-login-form-account {
          padding: 43px 0 0;

          .checkoutworkflow-login-form-content {
            padding: 0;
            margin-bottom: 31px;
          }
        }
      }
    }
  }

  .checkoutworkflow-payment {
    .checkoutworkflow-payment-resume {
      margin-bottom: 16px;
    }

    .checkoutworkflow-payment-notvalid {
      color: $red;
      font-weight: 700;
    }

    .payment-success {
      margin-top: 8px;
      display: flex;
      padding: 12px 16px;
      border-radius: 12px;
      border: 1px solid #e6e9eb;
      gap: 16px;
      align-items: center;
      justify-content: center;

      svg {
        height: 40px;
        width: 40px;
        fill: green;
      }
    }

    .alma {
      padding: 12px 16px;
      border-radius: 12px;
      margin-bottom: 0;
      background: $white;
      border: 1px solid #e6e9eb;
      cursor: pointer;
      margin-top: 8px;
      position: relative;
      transition: opacity 0.3s ease-out;
      width: 100%;

      button {
        display: flex;
        gap: 16px;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;

          @include transition;
        }

        &.open {
          svg {
            transform: rotate(180deg);

            @include transition;
          }
        }
      }

      .alma-plans {
        margin-top: 16px;

        .radios {
          display: flex;
          align-items: center;
          gap: 16px;

          @media (max-width: $screen-medium) {
            flex-direction: column;
            align-items: flex-start;
          }

          label {
            flex: 1;
            display: flex;
            gap: 8px;

            span {
              display: flex;
              align-items: center;
              gap: 8px;
            }

            img {
              display: block;
            }
          }
        }
      }

      .plan-detail {
        margin-top: 16px;

        .error-message {
          margin-bottom: 8px;
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-bottom: 16px;

          li {
            display: flex;
            flex-direction: row;
            gap: 16px;
            margin: calc(16px / 2) 0;
            border-bottom: 1px solid #e6e9eb;

            span {
              flex: 1;

              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }
    }

    .lunu {
      padding: 12px 16px;
      border-radius: 12px;
      margin-bottom: 0;
      background: $white;
      border: 1px solid #e6e9eb;
      cursor: pointer;
      margin-top: 8px;
      position: relative;
      transition: opacity 0.3s ease-out;
      width: 100%;

      button {
        display: flex;
        gap: 16px;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;

          @include transition;
        }

        &.open {
          svg {
            transform: rotate(180deg);

            @include transition;
          }
        }
      }

      .lunu-detail {
        padding: 10px;

        p {
          margin-bottom: 8px;
        }
      }
    }
  }

  .billedaddress-form {
    margin-bottom: 24px;

    .billedaddress-form-different {
      margin-bottom: 42px;
    }

    .billedaddress-form-address {
      margin-bottom: 40px;

      &.hide {
        display: none;
      }
    }

    .billedaddress-form-buttons {
      margin-top: 16px;
      text-align: right;

      /* TODO remove style and make this input reusable */
      input[type='submit'] {
        background-color: $black;
        color: $white;
        padding: 12px;
        border: 2px solid $black;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
          background-color: $white;
          color: $black;
        }

        &:disabled {
          background-color: $grey;
          border-color: $grey;
          pointer-events: none;
        }
      }
    }
  }

  .adyen-checkout__button__text {
    @media (max-width: $screen-medium) {
      white-space: initial;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .cart-resume .advantage-cart-list-open .cart-list {
    @media (max-width: $screen-medium) {
      max-height: 425px;
      overflow: auto;
    }
  }
}

.cart-page.checkout-page {
  @media (max-width: $screen-medium) {
    .grids {
      margin: 0;
    }

    .link-with-icon {
      margin: 0;
    }

    .checkoutworkflow-stepper {
      margin-top: 11px;
    }

    .checkoutworkflow-step-content {
      margin-top: 30px;
    }
  }
}
