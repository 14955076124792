$background-header: linear-gradient(
  180deg,
  rgb(0 0 0 / 40%) 0%,
  rgb(255 255 255 / 0%) 100%
) !default;
$color-header: $white !default;

.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: $background-header;

  @media (max-width: $screen-medium) {
    overflow: hidden;
  }

  .wrapper-header,
  .header-helpers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;

    @media (max-width: $screen-medium) {
      gap: 16px;
    }
  }

  .wrapper-header {
    justify-content: space-between;
    padding: 40px 80px 0;
    position: relative;
    max-height: 200px;
    transition: all 0.1s ease;

    @media (max-width: $screen-medium) {
      padding: 17px 10px;
    }

    &.simple-header {
      padding: 40px 80px;

      @media (max-width: $screen-medium) {
        padding: 17px 10px;
      }
    }

    li {
      &.menu svg {
        width: 23px;
        margin-left: 1px;
        margin-top: 8px;
      }

      @media (max-width: $screen-medium) {
        &.contrast-icon svg {
          width: 24px;
          margin-top: 7px;
        }
      }
    }
  }

  .header-helpers,
  .header-tools {
    flex: 1;
    margin-top: -18px;

    svg {
      fill: $white;
    }
  }

  .header-tools {
    display: flex;

    ul {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: auto;
      gap: 8px;
      margin-top: -4px;
      font-family: Arial, Helvetica, sans-serif;

      @media (max-width: $screen-medium) {
        margin-top: 8px;
      }

      img {
        width: 24px;
        height: 24px;
      }

      li {
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:has(.button--cart--counter.visible) {
          margin-right: 6px;
        }

        svg {
          width: 25px;
          fill: $white;

          &:hover {
            transform: scale(1.1);
            transition: 0.5s ease;
          }
        }

        button {
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }

    .button--cart {
      position: relative;

      &--counter {
        display: none;
        position: absolute;
        top: 16px;
        right: -1px;
        width: 16px;
        height: 16px;
        align-items: center;
        justify-content: center;
        background-color: $white;
        color: $black;
        border-radius: 50%;
        font-size: 10px;
        transition: 1s ease;

        &.visible {
          display: flex;
        }
      }
    }
  }

  .header-logo {
    text-align: center;
    cursor: pointer;

    .icon-messika-logo {
      color: $color-header;
      width: 150px;
      height: auto;

      @media (max-width: $screen-medium) {
        width: 119px;
      }
    }

    svg {
      fill: $white;
    }
  }

  .header-helpers {
    .helpers-border {
      padding-right: 24px;
      position: relative;

      &:not(:last-child) {
        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 10.5px;
          background-color: $color-header;
          position: absolute;
          right: -1px;
          top: 4px;
        }
      }
    }

    .numbers {
      margin-left: 14px;
      margin-top: -8px;

      a {
        text-decoration: none;
        font-size: 14px;
        color: $white;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    li:last-child {
      margin-left: 20px;
    }

    p {
      color: $color-header;
      font-size: 14px;
      padding: 0;
    }
  }

  .header-nav {
    padding: 24px 0 0;
  }

  @mixin contrast-mode() {
    border-bottom: 1px solid #cecece;

    $background-header: $white !important;
    $color-header: $black;

    background: $background-header;
    color: $color-header;

    .wrapper-header {
      svg {
        fill: $black;
      }

      .button--wishlist svg path {
        fill: black;
      }

      p,
      a,
      li,
      span:not(.button--cart--counter),
      label,
      .icon-messika-logo {
        color: $color-header;
      }
    }

    .header-navigation-desktop-item .header-navigation-desktop-item-title {
      color: $color-header;
    }
  }

  &.contrast,
  &.header-search-opened,
  &.active {
    @include contrast-mode;

    border: none;
  }

  &:hover {
    @media (min-width: $screen-medium) {
      @include contrast-mode;
    }
  }

  &:hover,
  &.contrast,
  &.active,
  &.header-search-opened {
    .header-helpers .helpers-border::after {
      background-color: $black;
    }

    label.switch span::before {
      background-color: $transparent-black-15;
    }

    label.switch input:checked + span::before {
      background-color: $black;
    }

    span.button--cart--counter {
      background-color: $black;
      color: $white;
    }
  }

  &:has(.simple-header) {
    &.header-scroll {
      top: -126px;
    }
  }

  &.header-scroll {
    position: fixed;

    @include contrast-mode;

    top: -82px;
    z-index: 999;

    @media (max-width: $screen-medium) {
      top: 0;
    }

    .header-tools .button--cart--counter {
      @media (max-width: $screen-medium) {
        background-color: $black;
        color: $white;
      }
    }

    .delivery {
      display: none;
    }
  }

  @media (max-width: $screen-medium) {
    .header-nav {
      li {
        a {
          font-size: 14px;
        }
      }
    }

    .header-helpers {
      li.contrast-icon {
        margin-left: -13px;
      }
    }
  }

  .header-search {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 30px 0;
    background-color: $white;

    input {
      font-size: 16px;

      &::placeholder {
        font-size: 16px;
        color: $black;
      }
    }

    @media (max-width: $screen-medium) {
      padding: 16px 0;
    }

    .header-search-input {
      height: 100%;
    }
  }

  &.hide-items {
    border-bottom: none;
  }

  &.has-background {
    @include contrast-mode;

    .header-helpers .helpers-border::after {
      background-color: $black;
    }

    label.switch span::before {
      background-color: $transparent-black-15;
    }

    label.switch input:checked + span::before {
      background-color: $black;
    }

    span.button--cart--counter {
      background-color: $black;
      color: $white;
    }
  }

  &.no-border {
    &:not(.header-scroll) {
      border-bottom: none;
    }
  }
}

.delivery-infoPush {
  &:empty {
    display: none;
  }

  .message-push {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px 42px;
    background-color: $black;

    @media (max-width: $screen-medium) {
      padding: 12px 38px 12px 15px;
    }

    &:empty {
      display: none;
    }
  }

  @media (max-width: $screen-small) {
    .infopush-title a,
    .infopush-content--wrapper {
      @include clamp;

      -webkit-line-clamp: 2;
    }

    .infopush-content--wrapper a {
      vertical-align: bottom;
    }
  }
}
