.landing-celine {
  .header-media-category {
    min-height: 550px;
    height: 550px;
  }

  .filter-product-form {
    .filter-product-form-desktop {
      .filter-product-form-menu {
        padding-bottom: 15px;

        .filter-product-form-menu-right {
          margin-left: auto;
        }
      }
    }

    @media (max-width: $screen-small) {
      .filter-product-form-desktop {
        .filter-product-form-menu {
          padding: 0;

          .filter-product-form-menu-right {
            margin: 0;
            width: 100%;

            .filter-product-form-menu-orderby {
              width: 100%;
              padding: 0;
              padding-bottom: 4px;
              text-align: center;

              .filter-product-form-menu-orderby-button {
                padding: 8px 0;
                width: 100%;

                span {
                  font-size: 14px;
                }
              }

              .filter-product-form-orderby {
                left: 0;
                top: 100%;
                transform: none;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .breadcrumbs {
    padding: 28px 0;

    @media (max-width: $screen-small) {
      padding: 19px 0;
    }
  }

  .list-selection-wrapper {
    margin-top: 10px;

    @media (max-width: $screen-medium) {
      .list-selection-swiper {
        padding: 0 1rem;
      }

      .list-selection-swiper-slide:not(.swiper-slide-visible) {
        .selection-item .img-container::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $transparent-black-15;
          z-index: 1;
        }
      }
    }

    // on celine page, avoid swipping on images
    .selection-item {
      .img-container {
        .custom-slider-pagination {
          opacity: 0 !important;
        }
      }
    }
  }

  .celine-list-products-slider {
    margin-bottom: 20px;
  }

  .gift {
    margin-top: 48px;
  }

  .story {
    margin: 103px 0 0;

    @media (max-width: $screen-medium) {
      margin: 54px 0 0;
    }
  }
}
