.modal-contrast {
  text-align: center;
  justify-content: center;

  .title-h4 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 22px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }

  button {
    margin: 20px auto 0;
    letter-spacing: 0.9px;
  }
}

.modal-contrast-logo {
  position: absolute;
  top: 0;
  left: 0;

  svg {
    width: 120px;
    fill: $white;
  }
}

.contrast-modal-open {
  main {
    height: 100vw;
    overflow: hidden;
  }
}
