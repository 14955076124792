// MEDIA {

body.contrast {
  .media {
    picture::after {
      content: '';
      background: $transparent-black-62;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.media {
  position: relative;
  margin-top: 57px;
  color: $white;

  .contrast::after {
    content: '';
    background: $transparent-black-62;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video,
  picture img {
    min-height: 550px;
    max-height: 800px;
    object-fit: cover;

    @media (max-width: $screen-medium) {
      min-height: 550px;
      max-height: 550px;
    }
  }

  img,
  video {
    width: 100%;
    position: relative;
    display: block;
  }

  .video-control {
    position: absolute;
    bottom: 21px;
    right: 24px;
    display: flex;
    gap: 8px;

    @media (max-width: $screen-medium) {
      right: 16px;
    }

    button {
      background-color: $transparent-white-10;
      padding: 0;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @include transition;

      @media (max-width: $screen-medium) {
        width: 24px;
        height: 25px;
      }

      svg {
        color: $white;
        fill: $white;
        width: 16px;
        height: 16px;
      }

      &:hover {
        background: $black;
        color: $white;

        @include transition;
      }
    }
  }

  .media-over {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    z-index: 2;

    @media (max-width: $screen-medium) {
      width: 80%;
    }
  }
}
