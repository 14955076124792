// BENEFIT

.cms-block-noheadless .page-main:has(.benefit) {
  margin-bottom: 0;
  padding-bottom: 0;
}

.page-main .columns:has(.benefit) .benefit {
  margin-left: -80px;
  width: calc(100% + 160px);
  margin-top: 80px;
}

.cms-block-noheadless .benefit {
  @media (max-width: $screen-medium) {
    margin: 0 -10px;
  }
}

.margin-benefit {
  margin: 50px 0 0;
  position: relative;
}

.benefit {
  text-align: center;
  background: $light-grey-100;
  padding: 94px 0 57px;

  @media (max-width: $screen-small) {
    padding: 54px 15px;
  }

  .title-h3 {
    margin: 0 !important;

    @media (max-width: $screen-small) {
      line-height: 42px;
      font-size: 42px;
      width: 90%;
      margin: 0 auto !important;
    }
  }

  figure::after {
    display: none !important;
  }

  ul {
    margin: 42px 72px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    @media (max-width: $screen-small) {
      margin: 34px 0 0;
    }

    li {
      width: 25%;
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      line-height: inherit;
      font-size: 16px;
      font-weight: 400;
      margin: 0;

      @media (max-width: $screen-medium) {
        width: 50%;
        margin-bottom: 27px;
      }

      .title-h5 {
        margin-top: 11px;
      }

      figure {
        height: auto;
      }

      img,
      svg {
        width: 41px;
        height: 41px;

        @media (max-width: $screen-small) {
          width: 26px;
          height: 26px;
        }
      }

      p {
        font-size: 16px;
        color: $grey;
        margin: 0;
        margin-top: 10px;
        line-height: 22px;
        font-weight: 400;

        @media (max-width: $screen-small) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}
