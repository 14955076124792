.cart-item {
  display: flex;
  flex-direction: row;
  position: relative;

  &.hide {
    display: none;
  }

  &-without-size-guide {
    .block-select {
      display: inline-block;

      .select-container .right .size-guide {
        display: none;
      }
    }
  }

  .push-tag {
    font-size: 12px;
    text-transform: uppercase;
    margin: 8px 8px 0;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    z-index: 2;
  }

  .remove-button {
    font-size: 12px;
    text-decoration: underline;
    color: $grey;
  }

  .not-available {
    background: $light-grey-200;
    color: $black;
    font-size: 14px;
    padding: 6px 10px;
    width: fit-content;
  }

  .cart-item-form {
    .select-wrapper {
      display: block;
      border-bottom: none;

      label {
        white-space: nowrap;
        vertical-align: middle;
      }

      select {
        width: auto;
        border-bottom: 1px solid $black;
        background: url('../../icons/icon-nav-arrow-down.svg') right no-repeat;
        background-size: 16px;
        padding: 4px 4px 8px;
      }

      .select-wrapper-icon {
        display: none;
      }

      .size-guide {
        display: none;
      }
    }

    .button.small {
      margin-top: 20px;
    }

    .cart-item-engraving {
      margin-top: 10px;

      @media (max-width: $screen-small) {
        margin-top: 0;
      }
    }

    @media (max-width: $screen-medium) {
      margin-top: 13px;
    }

    .cart-item-form-configurable-product {
      display: flex;
      flex-direction: column;

      @media (max-width: $screen-medium) {
        gap: 12px;
      }
    }
  }

  .form-select {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    label {
      margin-right: 0;
    }
  }

  .block-select {
    margin: 24px 0 0;
    border: none;
    padding: 0;

    @media (max-width: $screen-medium) {
      margin: 0;
      width: auto;
    }

    .select-container {
      justify-content: flex-start;
      align-items: flex-end;
      height: auto;
      gap: 0;

      > * {
        padding-bottom: 3px;
      }

      label,
      .placeholder {
        @media (max-width: $screen-medium) {
          font-size: 14px;
        }
      }

      .right {
        margin: 0;
        height: auto;
      }
    }

    label,
    .placeholder {
      padding-right: 8px;
    }

    .placeholder,
    .right {
      border-bottom: 1px solid $black;
    }

    ul {
      @media (max-width: $screen-small) {
        width: calc(100vw - 34px);
      }
    }
  }

  .button {
    &.button--small {
      font-size: 14px;
    }
  }

  .button-availability {
    display: flex;
    gap: 12px;
  }

  > a,
  .head > a {
    display: block;
    flex: 0 0 307px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    @media (max-width: $screen-large) {
      flex: 0 0 230px;
    }

    @media (max-width: $screen-medium) {
      flex: 0 0 168px;
    }
  }

  .small {
    &:not(.button) {
      margin-top: 9px;
      font-size: 12px;
      color: $grey;

      @media (max-width: $screen-medium) {
        margin-top: 0;
      }
    }

    &.carving-msg {
      margin-top: 20px;
      line-height: 17px;

      @media (max-width: $screen-small) {
        margin-top: 0;
      }
    }
  }

  .content {
    margin-left: 24px;
    flex: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: $screen-medium) {
      margin-top: 22px;
      margin-left: 10px;
    }

    h5 {
      @include clamp;
    }

    p {
      color: $grey;

      @media (max-width: $screen-medium) {
        font-size: 14px;
      }
    }

    .quantity {
      margin-top: 7px;
      color: $grey;
      display: flex;
      align-items: center;

      @media (max-width: $screen-medium) {
        margin-top: 14px;
        font-size: 14px;
      }
    }

    .price {
      flex: 2;
      color: $black;
      font-size: 18px;
      font-weight: 600;

      @media (max-width: $screen-medium) {
        font-size: 16px;
      }
    }

    .cart-item-outofstock-label {
      margin-left: 10px;
      padding: 8px;
      background-color: $light-grey-200;
      font-weight: 400;
      font-size: 12px;
      text-transform: none;
      white-space: nowrap;

      @media (max-width: $screen-small) {
        display: inline-block;
        margin: 8px 0;
      }
    }

    .head {
      display: flex;
      gap: 12px;

      .title-left {
        flex: 1;

        a {
          text-decoration: none;
          color: $black;
          margin-bottom: 2px;
          align-items: center;
        }
      }

      .title-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex: 0 0 auto;
      }
    }

    .head + .cart-item-form {
      margin-top: 15px;
    }

    .infos-cart {
      .content-infos {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          font-size: 16px;
          color: $grey;
          line-height: 1.25;
          margin-right: 5px;

          &:not(:last-child)::after {
            content: ',';
            color: $grey;
            margin-left: 1px;
            margin-top: 2px;
          }

          @media (max-width: $screen-medium) {
            font-size: 14px;
          }
        }

        &.bracelet span::after {
          display: none;
        }
      }

      .content-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 24px;

        @media (max-width: $screen-medium) {
          margin-top: 16px;
        }

        .price {
          font-weight: 400;
          color: $black;

          @media (max-width: $screen-medium) {
            font-size: 14px;
          }
        }
      }

      .quantity {
        margin-top: 5px;
        color: $grey;
      }
    }
  }

  .cart-item-outofstock-buttons {
    display: flex;
    gap: 16px;
    margin-top: 15px;

    & > * {
      width: calc(50% - 8px);
      transform: translateZ(0);
    }

    @media (max-width: $screen-small) {
      flex-direction: column;
      gap: 8px;

      & > * {
        margin: 0;
        width: 100%;
      }
    }
  }

  .cart-item-engraving {
    .form-input {
      margin: 0;
      display: flex;
      align-items: center;

      label {
        white-space: nowrap;
        margin-right: 8px;

        @media (max-width: $screen-medium) {
          margin-right: 6px;
        }
      }

      input {
        color: $black;

        @media (max-width: $screen-medium) {
          position: relative;
          padding-bottom: 3px;
        }
      }

      .form-input-carving {
        position: relative;
        display: flex;

        input {
          background: $white;

          &.stick {
            @include primaryFont;
          }

          &.english {
            font-family: $kunstler;
            font-size: 2em;
          }
        }

        button {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);

          svg {
            width: 16px;
            height: 16px;

            @media (max-width: $screen-medium) {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }

  &.item-small {
    > a {
      display: block;
      flex: 0 0 168px;

      @media (max-width: $screen-medium) {
        flex: 0 0 109px;
      }

      img {
        width: 168px;
        height: 168px;

        @media (max-width: $screen-medium) {
          width: 109px;
          height: 109px;
        }
      }
    }

    .content {
      margin-left: 19px;

      .cart-item-error {
        color: $red;
        margin-top: 8px;
      }
    }
  }

  &.cart-item-mobile {
    flex-direction: column;
    position: relative;

    .head {
      display: flex;
      flex-direction: row;

      .price {
        margin-top: 14px;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .cart-item-form {
      display: flex;
      flex-flow: column nowrap;
      gap: 4px;

      .select-wrapper {
        label {
          white-space: nowrap;
        }
      }

      & > *:first-child {
        flex: 1;
      }

      input {
        background: $white;
      }

      .craving-input {
        & > *:first-child {
          flex: 2;
        }

        p {
          display: block;
        }
      }

      .cart-item-form-configurableproduct {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $screen-medium) {
          margin-bottom: 16px;
          justify-content: start;
          align-items: end;
          gap: 25px;
        }
      }
    }
  }

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $transparent-grey-10;
  }
}
