.arrow-icon {
  height: 12px;
  min-width: 12px;
  width: 12px;
}

.cart-page {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding-bottom: 57px;

  @media (max-width: $screen-medium) {
    gap: 22px;
    padding-bottom: 32px;
  }

  .block-select.disabled {
    pointer-events: inherit;
  }

  .title-h4 {
    margin-top: 30px;

    @media (max-width: $screen-medium) {
      margin-top: 16px;
    }
  }

  .grid {
    > div:not(.cart-page-resume) {
      .margin-top {
        margin-top: 6px;
      }
    }
  }

  .grids {
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 30px;
    margin-top: 10px;

    @media (max-width: $screen-medium) {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .cart-page-resume {
      background: $white;

      @media (max-width: $screen-medium) {
        &.cart-page-resume-block {
          position: sticky;
          z-index: 10;
          left: 0;
          bottom: 0;
          width: 100%;

          .cart-resume.open {
            height: auto;
          }
        }
      }

      .cart-resume-detail-section {
        display: none;
      }
    }

    .grid {
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: hidden;

      &:first-child {
        margin-right: 30px;

        @media (max-width: $screen-medium) {
          margin-right: 0;
        }
      }
    }
  }

  .link-with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: $grey;
    width: fit-content;
    text-decoration: none;
    cursor: pointer;

    @media (max-width: $screen-medium) {
      font-size: 14px;
    }

    .icon {
      transform: rotate(180deg);
      height: auto;
      min-width: 16px;
      width: 16px;
    }
  }

  .cart-page-ctn {
    @media (max-width: $screen-medium) {
      margin-bottom: 151px;
    }
  }

  .cart-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: $screen-medium) {
      gap: 32px;
      margin-top: 30px;
    }

    .block-select .select-container .right .size-guide {
      display: none;
    }

    .cart-item {
      animation: fadein 2s;
    }

    @keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .item-small .content {
      h5 {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }

      p,
      .quantity {
        font-size: 14px;
      }

      .content-footer {
        .price {
          color: $black;
          font-size: 16px;
        }
      }
    }
  }

  .cart-resume {
    padding: 38px 40px;
    background-color: $white;
    border: 1px solid $transparent-black-15;
    position: relative;

    .margin-top {
      margin-top: 0;
    }

    .information-frame {
      &.align-center.flex-row {
        align-items: center;

        .container {
          text-align: left;
        }
      }
    }

    .cart-resume-title {
      margin-bottom: 19px;
      text-transform: uppercase;

      @media (max-width: $screen-medium) {
        margin-bottom: 10px;
      }
    }

    .cart-resume-mobile-arrow {
      position: absolute;
      top: 16px;
      right: 16px;
      transform: rotate(-90deg);
      transition: all 0.1s ease;
      cursor: pointer;

      path {
        stroke: $black;
      }
    }

    .cart-resume-informations {
      color: $black;
      margin-bottom: 24px;

      @media (max-width: $screen-medium) {
        margin-bottom: 10px;
      }

      a,
      span,
      label,
      p {
        font-size: 16px;
        color: $grey;

        @media (max-width: $screen-medium) {
          font-size: 14px;
        }
      }

      .cart-resume-informations-item {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 24px;

        @media (max-width: $screen-medium) {
          margin-bottom: 19px;

          &.free-shiping {
            margin-bottom: 25px;
          }
        }

        &.cart-resume-informations-item-advantage-code:last-child {
          @media (max-width: $screen-medium) {
            margin-bottom: 0;
          }
        }

        .information-frame-summary {
          color: $black;
          font-size: 18px;

          @media (max-width: $screen-medium) {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }

      a {
        color: $black;
      }
    }

    .cart-resume-advantage-code {
      .cart-resume-informations-item-advantage-code {
        cursor: pointer;

        span {
          margin-right: 8px;
          vertical-align: middle;
        }

        svg {
          transform: rotate(90deg);
          vertical-align: middle;
          transition: all 0.1s ease;

          path {
            stroke: $black;
          }
        }
      }

      .advantage-form {
        margin-bottom: 16px;
        position: relative;
        display: none;

        input[type='text'] {
          @include primaryFont;

          border: none;
          border-bottom: 1px solid $grey;
          padding: 7px 0;
          width: 100%;
          color: $grey;
        }

        .advantage-form-button {
          @include primaryFont;

          border: none;
          background-color: $transparent;
          cursor: pointer;
          color: $grey;
          position: absolute;
          top: 7px;
          right: 0;

          @media (max-width: $screen-medium) {
            padding: 0;
          }
        }
      }

      &.advantage-form-open {
        .advantage-form {
          display: block;
        }

        .cart-resume-informations-item-advantage-code {
          svg {
            transform: rotate(-90deg);
            height: 12px;
            min-width: 16px;
            width: 16px;
          }
        }
      }
    }

    .advantage-cart-list-open {
      @media (max-width: $screen-medium) {
        margin-top: -10px;
        display: block;
      }

      .cart-list {
        margin-top: -11px;

        @media (max-width: $screen-medium) {
          margin-top: 0;
          gap: 16px;
        }

        .cart-item {
          .title-h5 a {
            @include primaryFont;

            font-weight: 600;
          }

          .container-picture {
            width: 91px;
            display: block;
            flex: none;
          }

          picture {
            width: 91px;
            height: 91px;
            display: block;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              aspect-ratio: 1/1;
            }
          }

          .content {
            @media (max-width: $screen-medium) {
              margin-top: 0;
            }

            .infos-cart {
              .quantity {
                margin-top: 0;
              }

              .content-footer {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }

    .cart-resume-subtotal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $black;
      margin-bottom: 24px;

      @media (max-width: $screen-medium) {
        margin: 20px 0 0;

        &:not(:last-child) {
          margin-bottom: 17px;
        }
      }

      .cart-resume-subtotal-title {
        font-size: 18px;
        font-weight: bold;

        @media (max-width: $screen-medium) {
          font-size: 16px;
        }
      }

      .cart-resume-subtotal-old {
        color: $black;
        text-decoration: line-through;
        font-size: 12px;
        margin-right: 12px;
      }

      .cart-resume-subtotal-current {
        font-weight: 600;
      }

      .cart-resume-subtotal-value {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
      }
    }

    .button {
      width: 100%;
      font-weight: 500;
      letter-spacing: 1px;
    }

    .cart-resume-error-advantage-code {
      color: $red;
      margin-bottom: 12px;
    }

    @media (max-width: $screen-medium) {
      margin-left: -30px;
      margin-right: -30px;
      padding: 16px;
      height: auto;

      &.folded {
        height: auto;

        .cart-resume-mobile-arrow {
          transform: rotate(90deg);
        }

        .fold-on-mobile {
          max-height: 0;
        }
      }

      .container-arrow {
        width: 100%;
        gap: 9px;
        align-items: center;
        margin: 0 0 19px;
        padding: 0;

        .cart-resume-mobile-arrow {
          display: block;
          position: relative;
          top: inherit;
          right: inherit;
        }

        .title-h5 {
          font-size: 16px;
          padding: 0;
          margin: 0;
        }
      }

      .fold-on-mobile {
        max-height: 1000px;
        overflow: hidden;
        transition: all 0.1s ease;
      }
    }

    @media (max-width: $screen-small) {
      margin-left: -17px;
      margin-right: -17px;
    }
  }

  .resume-address {
    margin-bottom: 21px;

    @media (max-width: $screen-medium) {
      margin-bottom: 0;
    }

    p {
      font-size: 18px;

      @media (max-width: $screen-medium) {
        font-size: 17px;
      }
    }

    p + p {
      margin-top: 4px;
    }

    .resume-address-fullname {
      text-shadow: 0 0 0.05rem $black;
    }

    .resume-address-fulladdress,
    .resume-address-phonenumber {
      color: $grey;
    }
  }

  // INFOS

  .information-cart {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .information-frame {
      padding: 40px;
    }
  }

  .information-frame-help {
    margin-top: 10px;

    p {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 1.4;

      @media (max-width: $screen-medium) {
        font-size: 14px;
      }
    }

    p:first-child {
      margin-bottom: 22px;
      color: $grey;

      @media (max-width: $screen-medium) {
        margin-bottom: 13px;
      }
    }

    p:not(:first-child) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      gap: 0 15px;
    }

    a {
      text-decoration: underline;
      color: $black;
      font-size: 16px;

      @media (max-width: $screen-medium) {
        font-size: 14px;
      }
    }
  }

  .information-frame-shipping {
    margin-top: 10px;

    p,
    a {
      font-size: 16px;
      line-height: 1.31;
      color: $grey;

      @media (max-width: $screen-medium) {
        font-size: 14px;
      }
    }

    p:first-child {
      color: $black;
      margin-bottom: 23px;

      @media (max-width: $screen-medium) {
        margin-bottom: 20px;
      }
    }

    p:last-child {
      display: flex;
      flex-wrap: wrap;
    }

    a {
      text-decoration: underline;
    }
  }

  .information-frame-payment {
    margin-top: 10px;

    p {
      font-size: 16px;
      margin-bottom: calc(16px / 2);

      @media (max-width: $screen-medium) {
        margin-bottom: 13px;
        font-size: 14px;
      }
    }

    .cb-icons {
      display: inline-flex;
      flex-direction: row;
      gap: 8px;
    }

    p:last-child {
      color: $grey;
      margin-top: 16px;
    }
  }
}
