@import '../../../../node_modules/swiper/modules/effect-fade';
@import '../../../../node_modules/swiper/swiper';

.page-homepage {
  .page-main {
    padding: 0;
  }

  // HERO
  .header-homepage {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      width: 100%;
      height: 17%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: linear-gradient(to bottom, 
        rgb(0 0 0 / 0%) 0%, 
        rgb(0 0 0 / 5%) 20%, 
        rgb(0 0 0 / 10%) 40%, 
        rgb(0 0 0 / 20%) 60%, 
        rgb(0 0 0 / 35%) 80%, 
        rgb(0 0 0 / 50%) 100%
      );
    }

    .header-slider,
    .swiper,
    .header-media {
      height: 100vh;
      width: 100%;
      position: relative;
    }

    .header-media-source {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $transparent-black-15;
      }

      .video{
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $transparent-black-15;
        }
      }

      picture,
      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      video,
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .header-media-content {
      max-width: 650px;
      width: 100%;
      text-align: center;
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -34%, 0);
      color: $white;

      &.title-black {
        div .title-h1 {
          color: $black;
        } 
      }

      &.subtitle-1-black {
        div .subtitle-1 {
          color: $black;
        }
      }

      &.subtitle-2-black {
        div .subtitle-2 {
          color: $black;
        }
      }

      @media (max-width: $screen-medium) {
        max-width: 90%;
      }

      .subtitle-1 {
        font-size: 24px;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 10px;
      }

      .subtitle-2 {
        font-size: 18px;
      }

      .button {
        margin: 30px auto 0;
      }
    }

    .header-homepage-explorer {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      padding: 40px 16px 32px;
      z-index: 3;
      color: $white;
      cursor: pointer;

      .underline {
        text-transform: uppercase;
        background-image: linear-gradient($white, $white);
        text-align: center;
      }
    }

    .header-slider-arrows {
      position: absolute;
      right: 29px;
      bottom: 26px;
      z-index: 2;

      @media (max-width: $screen-medium) {
        right: 16px;
        bottom: 16px;
      }

      button {
        margin: 0;
        padding: 0;
      }

      .custom-arrow-navigation {
        padding: 8px 12px;
        background-color: $transparent-white-20;
        cursor: pointer;
        transition: all 0.1s ease;
        margin-left: 4px;

        @include transition;

        @media (max-width: $screen-medium) {
          padding: 4px 8px;
        }

        &:hover {
          background-color: $transparent-black-62;

          @include transition;
        }

        path {
          stroke: $white;
        }

        &.custom-arrow-navigation-left {
          margin-right: 4px;
          padding: 8px 12px;

          @media (max-width: $screen-medium) {
            padding: 4px 8px;
          }
        }
      }
    }
  }

  // LIST CATEGORIES
  section.list-categories {
    margin-top: 10px;

    @media (max-width: $screen-medium) {
      margin-top: 7px;
    }

    .swiper-slide-visible:not(.swiper-slide-prev, .swiper-slide-next, .swiper-slide-active)::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $transparent-black-62;
      z-index: 2;
    }

    .swiper-slide {
      height: auto;

      article {
        height: 100%;

        a {
          height: 100%;
        }
      }
    }

    article.push {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        display: block;

        @include transition;
      }

      a {
        position: relative;
        display: block;
        overflow: hidden;
      }

      a:hover {
        figure img, figure video {
          transform: scale(1.1);
        }
      }

      figure,
      picture {
        position: relative;
        height: 100%;
        width: 100%;
        display: block;
      }

      figcaption {
        bottom: 50px;
        position: absolute;
        width: 100%;
        z-index: 2;

        @media (max-width: $screen-medium) {
          bottom: 23px;
        }
      }

      .title-h3 {
        color: $white;
        text-align: center;
        letter-spacing: inherit;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        background: linear-gradient(to bottom, 
          rgb(0 0 0 / 0%) 0%, 
          rgb(0 0 0 / 5%) 20%, 
          rgb(0 0 0 / 10%) 40%, 
          rgb(0 0 0 / 20%) 60%, 
          rgb(0 0 0 / 35%) 80%, 
          rgb(0 0 0 / 50%) 100%
        );
        z-index: 1;
        display: block;
      }
    }
  }

  // LIST SELECTION
  .list-selection {
    margin-top: 87px;

    @media (max-width: $screen-medium) {
      margin-top: 37px;
    }

    .title-h2 {
      text-align: center;
      letter-spacing: -2px;
    }

    .list-selection-wrapper {
      margin-top: 34px;

      @media (max-width: $screen-medium) {
        margin-top: 10px;
      }
    }
  }

  // BLOCK MOOVE
  .block-moove {
    margin-top: 16px;

    @media (max-width: $screen-medium) {
      margin-top: 8px;
    }

    ul.wrapper-push {
      display: flex;
      flex-flow: row wrap;
      place-items: flex-start flex-start;
      gap: 16px;

      @media (max-width: $screen-medium) {
        gap: 8px;
      }

      li {
        width: calc(50% - 8px);

        @media (max-width: $screen-medium) {
          width: 100%;
        }
      }
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      color: $white;

      img {
        @include transition;
      }

      &:hover img {
        transform: scale(1.1);
      }
    }

    figure {
      position: relative;
      height: 100%;
      overflow: hidden;

      img,
      video {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 601px;
        object-fit: cover;

        @media (max-width: $screen-medium) {
          min-height: inherit;
          max-height: 442px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        background: linear-gradient(to bottom, 
          rgb(0 0 0 / 0%) 0%, 
          rgb(0 0 0 / 5%) 20%, 
          rgb(0 0 0 / 10%) 40%, 
          rgb(0 0 0 / 20%) 60%, 
          rgb(0 0 0 / 35%) 80%, 
          rgb(0 0 0 / 50%) 100%
        );
        z-index: 1;
        display: block;
      }
    }

    figcaption {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 10px;
      bottom: 50px;
      position: absolute;
      width: 100%;
      z-index: 2;

      @media (max-width: $screen-medium) {
        bottom: 20px;
      }
    }
  }

  // ACTUALITES
  .list-news {
    position: relative;
    margin-top: 141px;

    @media (max-width: $screen-medium) {
      padding: 0;
      margin-top: 63px;
    }

    .title-h2 {
      text-align: center;
    }

    .swiper {
      margin-top: 34px;
      padding: 0;

      @media (max-width: $screen-medium) {
        margin-top: 11px;
      }

      .list-news-swiper {
        margin-bottom: 13px;

        @media (max-width: $screen-medium) {
          margin-bottom: 0;
        }
      }
    }

    .news-item {
      a {
        text-decoration: none;
        overflow: hidden;

        &:hover {
          img {
            height: 125%;
            width: 125%;

            @include transition;
          }
        }
      }

      picture {
        position: relative;
        display: block;
        margin-bottom: 19px;
        overflow: hidden;

        &::before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 114%;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;

          @include transition;
        }
      }

      h5,
      h3 {
        @include primaryFont;

        color: $black;
        text-transform: uppercase;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        font-size: 18px;
        line-height: normal;

        @media (max-width: $screen-medium) {
          font-size: 14px;
        }
      }

      p,
      div {
        @include primaryFont;

        color: $grey;
        font-size: 16px;
        font-weight: 400;
        text-align: left !important;
        line-height: 20px;
        max-height: 8rem;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden !important;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;

        @media (max-width: $screen-medium) {
          font-size: 12px;
        }
      }
    }
  }

  // SERVICES
  .services {
    text-transform: uppercase;
    text-align: center;
    margin: 85px 0 78px;

    @media (max-width: $screen-small) {
      margin: 44px 0 16px;
    }

    .services-content {
      display: flex;
      align-items: center;
      vertical-align: middle;
      gap: 14px;
      flex-direction: row;
      margin-top: 22px;

      @media (max-width: $screen-small) {
        flex-direction: column;
        margin-top: 15px;
      }

      article {
        width: 100%;

        figure {
          position: relative;

          picture {
            position: unset;

            img {
              display: block;
              position: relative;
              width: 100%;
              height: 100%;
            }

            &::after {
              content: '';
              background: $transparent-black-50;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }

          p {
            @include secondaryFont;

            font-size: 50px;

            @media (max-width: $screen-medium) {
              font-size: 31px;
            }
          }

          .button {
            margin: 20px auto 0;

            @media (max-width: $screen-medium) {
              margin-top: 12px;
            }
          }

          figcaption {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translate(0, -44%);
            color: $white;
            z-index: 1;

            @media (max-width: $screen-medium) {
              transform: translate(0, -46%);
            }
          }
        }
      }
    }
  }

  .video-element {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include transition;
  }


}
