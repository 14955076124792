// TODO rework style, because this css is coming from legacy project

.cms-block-noheadless.page-products.catalog-category-view .breadcrumbs {
  @media (max-width: $legacy-screen-l) {
    margin: 60px 0 41px 0;
  }
}

.catalog-category-view {
  &.page-products .page-main {
    padding: 0;
    @media (max-width: $screen-medium) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .Block-01 .Item-02 .Item-title {
    text-align: center;
    font-size: 60px;

    @media (max-width: $legacy-screen-l) {
      font-size: 12vw;
    }
  }

  .Block-01 .Item-02 .Item-subtitle {
    text-align: center;
  }

  &.page-layout-1column-subcategory-landing {
    .widget {
      &.block {
        .Block {
          &-09 {
            width: 100%;
            max-width: 1166px;
            margin: 0 auto;
            padding: 0 105px;
            box-sizing: border-box;

            @media (max-width: $legacy-screen-l) {
              padding: 0 10px;

              &.Block .fat-title {
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  &.page-products.page-layout-1column .products-grid .product-item .tocart {
    &::before {
      display: none;
    }

    &:hover {
      color: $white !important;
      text-decoration: underline;

      span {
        color: $white !important;
      }
    }
  }

  .hero-container .hero-image,
  .page-products .hero-container .hero-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.cms-block-noheadless.page-products {
  @media (max-width: $screen-medium) {
    .breadcrumbs {
      margin: 0;
    }

    .hero-description,
    .hero-description p {
      color: $black;
      font-size: 16px;
      margin-top: 0;
      line-height: 1;
      margin: 0 auto;
      margin-bottom: 20px;
      font-family: $futura;
      text-align: center;
      font-weight: 100;
    }

    &.catalog-category-view .Block-02 {
      margin: 0 -10px;
      width: 100vw;
    }

    .hero-container .hero-image img.mobile,
    .hero-container .hero-video img.mobile {
      display: block;
    }

    .hero-container .hero-image img,
    .hero-container .hero-video img {
      display: none;
    }
  }
}
