.accordion {
  article {
    border-bottom: 1px solid $light-grey-200;

    .accordion-item-button {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .accrodion-title {
        @media (max-width: $screen-medium) {
          font-size: 17px;
        }
      }

      svg {
        width: 24px;
        height: 24px;

        @include transition;
      }
    }

    .content {
      overflow: hidden;
      transition: max-height 0.1s cubic-bezier(0, 1, 0, 1), opacity 0.1s cubic-bezier(0, 1, 0, 1);
      height: auto;
      max-height: 0;
      opacity: 0;
    }

    .title-h5 {
      text-align: left;
    }

    &.open:not(.animated),
    &.animated {
      .content {
        margin: 0 0 16px;
        transition: max-height 0.3s ease-out, opacity 0.3s ease-in-out;
        max-height: inherit;
        opacity: 1;
      }
    }

    &.open {
      .accordion-item-button {
        svg {
          transform: rotate(180deg);

          @include transition;
        }
      }
    }
  }

  @keyframes rotateReverse {
    from {
      transform: rotate(180deg);
    }

    to {
      transform: rotate(0deg);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(180deg);
    }
  }
}
