.modal-geoip {
  text-align: center;
  position: relative;
  margin: 20px auto 0;
  min-width: 312px;
  max-width: 312px;

  @media (max-width: $screen-medium) {
    min-width: 100%;
  }

  p {
    padding: 10px 0 20px;

    span {
      font-weight: 500;
    }

    br {
      @media (max-width: $screen-medium) {
        display: none;
      }
    }
  }

  .modal-geoip-links {
    width: 100%;
    padding: 0;

    .button {
      margin: 10px auto;
      display: block;
      width: 150px;
    }
  }

  &-links {
    display: flex;
    flex-flow: column nowrap;
    padding: 20px 30%;

    > * {
      margin: 5px 0;
    }
  }

  &-others {
    background-color: $white;
    padding: 39px 0;

    .footer-selects {
      label {
        display: none;
      }

      .footer-select {
        position: relative;
        margin: 10px 0;

        &::after {
          z-index: 2;
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          background-image: url('../../icons/icon-nav-arrow-down.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      select {
        @include primaryFont;

        padding: 10px 20px;
        width: 100%;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 15px;
        background-color: $white;
        appearance: none;
        border: 1px solid $black;
        border-radius: 0;
        color: $black;
      }
    }
  }
}
