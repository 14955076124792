.wrapper-typography {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  h1, h2, h3, h4, h5, p {
    padding: 10px 0;
    margin: 0;
  }
}

