// TODO rework style, because this css is coming from legacy project

.cms-block-noheadless {
  .collection-list-wrapper {
    margin-top: 80px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1166px;

    @media (min-width: $legacy-screen-l) {
      margin-bottom: 70px;
    }

    @media (max-width: $legacy-screen-l) {
      display: block;
      margin-top: 0;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 30px;
    }

    .collection-wrapper {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      @media (max-width: $legacy-screen-l) {
        overflow: initial;
      }

      a {
        display: block;
        height: 100%;

        &:hover {
          text-decoration: none;
        }

        &:visited {
          color: $black !important;
        }
      }

      img {
        display: block;
        transform-origin: center;
        transition: all 0.3s ease;

        &.only-mobile {
          display: none;
        }

        &:hover {
          text-decoration: none;
        }

        @media (max-width: $legacy-screen-l) {
          min-width: initial;

          &.only-desktop {
            display: none;
          }

          &.only-mobile {
            display: block;
          }
        }
      }

      .collection-title {
        font-size: 64px;
        color: $black;
        @include secondaryFont;
        position: absolute;
        bottom: 50%;
        left: -35px;
        text-transform: uppercase;
        width: 200vh;
        text-align: center;
        margin: 0;
        font-weight: normal;
        z-index: -1;
        transform-origin: center;
        line-height: unset;

        span {
          display: block;
          padding: 10px 0;
          transform-origin: center;
          transform: translate3d(-50.4%, 50%, 0) rotate(-90deg);

          @media (max-width: $legacy-screen-l) {
            padding: 7px 0;
            transform: translate3d(-49%, 50%, 0) rotate(-90deg);
          }
        }

        @media (max-width: $legacy-screen-l) {
          left: -37px;
        }
      }

      &:hover {
        img {
          transform: scale(1.05);
        }

        .cta-button {
          color: $white;
          text-decoration: none;
          background: $white;

          &::before {
            transform: scaleY(1);
          }

          &.white {
            color: $black;

            a {
              color: $black;
            }
          }
        }
      }

      .image-container {
        position: relative;
        width: 100%;
        padding-top: 133.33%;

        img {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .cta-button {
          padding: 6px 25px;
          font-size: 17px;
          @include primaryFont;
          font-weight: 500;
          text-transform: uppercase;
          position: absolute;
          left: 50%;
          bottom: 15%;
          transform: translateX(-50%);
        }
      }

      .image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        height: 100%;
      }

      .content-wrapper {
        text-align: center;
        padding: 25px 10px;
        @include primaryFont;
        font-weight: 300;

        h2,
        h3,
        h4 {
          color: $black;
          font-size: 17px;
          @include primaryFont;
          margin-bottom: 12px;
          text-transform: uppercase;
          text-decoration: none;
          font-weight: 500;

          @media (max-width: $legacy-screen-l) {
            margin-bottom: 5px;
          }
        }

        p {
          color: $black;
          @include primaryFont;
          font-size: 17px;
          font-weight: 300;
          text-decoration: none;
        }
      }

      @media (max-width: $legacy-screen-l) {
        width: 100%;
        flex-basis: 100%;
        padding: 0 40px;

        .collection-title {
          font-size: 32px;
        }
      }

      @media (min-width: $legacy-screen-l) {
        width: 50%;
        flex-basis: 50%;
        padding: 0 4% 0 8%;

        &:nth-child(even) {
          margin-top: 8%;
          padding: 0 8% 0 4%;

          .collection-title {
            left: auto;
            right: -200vh;

            span {
              transform: translate3d(-47.6%, 50%, 0) rotate(-90deg);
            }
          }
        }
      }
    }
  }
}
