.modal .modal-cart {
  padding-top: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $screen-medium) {
    padding-top: 20px;
    height: 88vh;
  }

  .summary-cart {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 24px;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: $screen-small) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .cart-list {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .summary {
    width: calc(100% - 80px);
    padding-top: 16px;
    margin-left: 40px;
    margin-right: 40px;
    border-top: 1px solid rgb(0 0 0 / 10%);
    box-shadow: 1px -13px 20px 0 $white;
    z-index: 999;
    position: relative;
    height: 190px;

    @media (max-width: $screen-small) {
      width: 100%;
      padding: 15px;
      margin: 0;
    }

    .summary-total {
      display: flex;

      p {
        line-height: 21px;
      }

      .total-title,
      .total {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
      }

      .total-title {
        flex: 1;
        margin-bottom: 16px;
      }

      .total {
        display: flex;
        justify-content: flex-end;
      }
    }

    .small {
      color: $grey;
    }

    .button-group {
      .button.disabled {
        color: $white !important;
      }
    }
  }
}
