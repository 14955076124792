body.has-message-push .forgot-password {
  margin: 0 0 140px;

  @media (max-width: $screen-medium) {
    margin: 0 0 120px;
  }
}

.forgot-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin: 250px 0 140px;

  @media (max-width: $screen-medium) {
    margin: 150px 0 120px;
  }

  .check-token-loading {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  form .text-wrapper .error {
    height: auto;
    bottom: -30px;
  }

  .title-h3 {
    text-align: center;
  }

  .paragraph {
    font-size: 14px;
    text-align: center;
    font-weight: lighter;
    margin-bottom: 16px;
  }

  input {
    width: calc(100% - 16px);
  }

  .inputs .text-wrapper:first-child {
    margin-bottom: 48px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 48px;
  }
}

.account-page {
  display: flex;
  margin: 0 40px 50px;
  gap: 60px;
  justify-content: space-between;

  @media (max-width: $screen-medium) {
    flex-direction: column;
    justify-content: inherit;
    gap: 40px;
    margin: 0 20px 70px;
  }

  .empty {
    display: flex;
    align-items: center;
    margin: 50px 0;

    p {
      margin-left: 15px;
    }
  }

  .navigation {
    width: 24%;
    min-width: 300px;
    border-right: 1px solid $black-transparent-10;

    @media (max-width: $screen-medium) {
      width: auto;
      min-width: inherit;
      padding: 0;
    }
  }

  .container {
    margin-right: 40px;
    width: 100%;

    @media (max-width: $screen-medium) {
      margin: 0;
    }

    h1 {
      font-size: 32px;
      margin-bottom: 28px;

      @include hx;

      @media (max-width: $screen-medium) {
        text-align: center;
      }
    }
  }
}
