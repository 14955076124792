.StyleGuide {
  padding: 50px;

  * {
    margin: 20px 0;
  }
}

.no-hero {
  margin: 0 0 50px;

  &:has(.benefit) {
    margin: 0;
  }

  @media (max-width: $screen-medium) {
    margin: 0 0 30px;
  }
}

// BG
.bg-black {
  background-color: $black;
  padding: 30px;
}

@mixin underline {
  text-decoration: none;
  background-image: linear-gradient(currentcolor, currentcolor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.3s;

  &:hover,
  &:focus {
    background-size: 100% 1px;
  }
}

// H
@mixin hx {
  @include secondaryFont;

  text-transform: uppercase;
}

.title-h1 {
  font-size: 85px;
  line-height: 74px;
  font-weight: 400;

  &.text-black {
    color: $black;
  }

  @include hx;

  @media (max-width: $screen-medium) {
    font-size: 47px;
    line-height: 60px;
  }
}

.title-h2 {
  font-size: 83px;
  line-height: 96px;
  letter-spacing: -3.4px;
  font-weight: 400;

  @include hx;

  @media (max-width: $screen-medium) {
    line-height: 43px;
    font-size: 47px;
    letter-spacing: -2px;
  }
}

@mixin titleH3 {
  font-size: 47px;
  line-height: 60px;
  letter-spacing: -1.5px;
  font-weight: 400;

  @include hx;

  @media (max-width: $screen-medium) {
    font-size: 30.5px;
    line-height: 38px;
    letter-spacing: -1px;
  }
}

.title-h3 {
  @include titleH3;

  &.text-black {
    color: $black;
  }
}

.title-h4 {
  font-size: 30.5px;
  line-height: 38px;
  font-weight: 400;

  @include hx;

  @media (max-width: $screen-medium) {
    font-size: 21px;
    line-height: 26px;
  }
}

.title-h5 {
  @include primaryFont;

  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  font-weight: 600;

  @media (max-width: $screen-medium) {
    font-size: 14.5px;
    line-height: 18px;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $black;
}

// BUTTONS

button {
  cursor: pointer;
}

@keyframes snake-animation-1-black {
  0% {
    transform: translateX(-100%) translateY(calc(-100% + 1px));
  }

  25% {
    transform: translateX(1px) translateY(calc(-100% + 1px));
  }

  30% {
    transform: translateX(1px) translateY(-1px);
  }

  50% {
    transform: translateX(1px) translateY(-1px);
  }

  100% {
    transform: translateX(1px) translateY(-1px);
  }
}

@keyframes snake-animation-2-black {
  0% {
    transform: translateX(100%) translateY(calc(100% - 1px));
  }

  30% {
    transform: translateX(100%) translateY(calc(100% - 1px));
  }

  51% {
    transform: translateX(0) translateY(calc(100% - 1px));
  }

  56% {
    transform: translateX(0) translateY(0);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

@keyframes snake-animation-1-white {
  0% {
    transform: translateX(-100%) translateY(calc(-100% + 4px));
  }

  25% {
    transform: translateX(4px) translateY(calc(-100% + 4px));
  }

  30% {
    transform: translateX(4px) translateY(-4px);
  }

  50% {
    transform: translateX(4px) translateY(-4px);
  }

  100% {
    transform: translateX(4px) translateY(-4px);
  }
}

@keyframes snake-animation-2-white {
  0% {
    transform: translateX(100%) translateY(calc(100% - 4px));
  }

  30% {
    transform: translateX(100%) translateY(calc(100% - 4px));
  }

  51% {
    transform: translateX(0) translateY(calc(100% - 4px));
  }

  56% {
    transform: translateX(0) translateY(0);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

.button {
  @include primaryFont;

  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  line-height: inherit;
  border: 1px solid;
  cursor: pointer;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
  display: block;
  width: fit-content;
  padding: 9px 14px;
  letter-spacing: 1.5px;

  @media (max-width: $screen-medium) {
    font-size: 12px;
    padding: 8px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    background: $black;
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }

  &:hover,
  &:focus,
  &:active {
    color: $white !important;
  }

  &:hover::before,
  &:focus::before,
  &:active::before {
    transform: scaleY(1);
    transition-timing-function: ease-in-out;
  }

  // LOADING
  &--loading {
    opacity: 1;

    .snake {
      inset: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;

      &__overlay {
        position: absolute;
        inset: 1px;
        z-index: -1;
      }

      &::before,
      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.black {
      border-color: $white !important;
      color: $black !important;

      .snake {
        &::before {
          animation: snake-animation-1-black 4s linear infinite;
        }

        &::after {
          animation: snake-animation-2-black 4s linear infinite;
        }

        &::before,
        &::after {
          background-color: $black;
        }

        &__overlay {
          background-color: $white;
        }
      }
    }

    &.white, &.border-black {
      background-color: $black !important;
      border-color: $black !important;
      color: $white !important;

      &::before {
        background-color: $black !important;
      }

      &::after {
        border: 3px solid $black;
        content: ' ';
        inset: 0;
        position: absolute;
      }

      .snake {
        &::before {
          animation: snake-animation-1-white 4s linear infinite;
        }

        &::after {
          animation: snake-animation-2-white 4s linear infinite;
        }

        &::before,
        &::after {
          background-color: $white;
        }

        &__overlay {
          inset: 4px;
          background-color: $black;
        }
      }
    }

    &::before {
      transform: scaleY(1);
      transition-timing-function: ease-in-out;
      background-color: $white;
    }
  }

  // SIZE
  &.small {
    font-size: 12px;
  }

  &.simple-underline {
    background-color: $transparent;
    text-decoration: underline;
    color: $black !important;
    border: none;
    text-transform: inherit;
    letter-spacing: inherit;

    &::before {
      display: none;
    }

    &:hover {
      background-color: $transparent;
      text-decoration: underline;
      color: $black !important;
    }
  }

  // COLORS
  &.black {
    background: $black;
    color: $white;
    border: 1px solid $black;

    &::before {
      background-color: $white;
    }

    &:hover,
    &:focus,
    &:active {
      color: $black !important;
    }
  }

  &.white {
    background: $white;
    color: $black;
    border: 1px solid $white;

    &:hover,
    &:focus,
    &:active {
      color: $white !important;
      border: 1px solid $black;
    }
  }

  &.border-white {
    background: $transparent;
    color: $white !important;
    border: 1px solid $white;

    &:hover,
    &:focus,
    &:active {
      color: $black !important;
    }

    &::before {
      background-color: $white;
    }
  }

  &.border-black {
    background: $transparent;
    color: $black;
    border: 1px solid $black;

    &:hover,
    &:focus,
    &:active {
      color: $white !important;
    }

    &::before {
      background-color: $black;
    }
  }

  &.border-grey {
    background: $transparent;
    color: $black !important;
    border: 1px solid $light-grey-200;

    &:hover,
    &:focus,
    &:active {
      color: $black !important;
    }

    &::before {
      background-color: $light-grey-200;
    }
  }

  &.transparent {
    background: $transparent-white-10;
    color: $white !important;
    border: 1px solid $white;

    &:hover,
    &:focus,
    &:active {
      color: $black !important;
    }

    &::before {
      background-color: $white;
    }
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    background: $grey;
    color: $white !important;
    border: 1px solid $grey;
  }
}

// RESPONSIVE
.only-desktop {
  display: block !important;

  @media (max-width: $screen-medium) {
    display: none !important;
  }
}

.only-mobile {
  display: none !important;

  @media (max-width: $screen-medium) {
    display: block !important;
  }
}

// Use this news breakpoints the older is plug on older website
.mobile {
  display: none !important;

  @media (max-width: $screen-small) {
    display: block !important;
  }
}

.desktop {
  display: block !important;

  @media (max-width: $screen-small) {
    display: none !important;
  }
}

#launcher {
  z-index: 80 !important;
}

// ANIMATIONS
.underline {
  position: relative;

  @include underline;
}

@mixin transition {
  transition: all 0.3s ease;
}

// CLASS

.flex {
  display: flex;
}

.margin {
  margin: 20px;
}

em {
  font-style: italic;
}

// CLAMP
@mixin clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.error-message {
  font-size: 14px;
  color: $red;
  text-align-last: left;
  width: 100%;
  margin-top: 5px;
}

.disabled {
  color: $grey !important;
  cursor: default !important;

  &::after {
    display: none;
  }
}

.push-tag {
  color: $transparent-black-50;
}

.center {
  display: block;
  margin: 10px auto 0;
}

.text-underline {
  text-decoration: underline;
}

// override Reservio button css
.reservio-booking-button {
  .btn-book {
    @include primaryFont;

    border: unset !important;
    background: $transparent !important;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;
    text-shadow: 0 0 0.05rem $grey;
    margin-bottom: 0;
    padding: 0;
    color: $black !important;

    @media (max-width: $screen-medium) {
      font-size: 17px;
      height: auto;
    }

    &:hover {
      box-shadow: unset !important;
    }

    // span is the icon left to the text
    span {
      display: none;
    }
  }
}

.cms-page-noheader {
  margin-top: 166px;

  @media (max-width: $screen-medium) {
    margin-top: 75px;
  }
}

// HIGHLIGHTING ELEMENTS
@media (max-width: $screen-medium) {
  label,
  input,
  textarea,
  select,
  button,
  a {
    -webkit-tap-highlight-color: transparent;
  }
}
