.information-frame {
  background: $light-grey-200;
  display: flex;
  flex-direction: column;
  gap: calc(16px / 2);
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: $screen-medium) {
    justify-content: flex-start;
  }

  &.frame-small {
    padding: calc(16px / 2);
  }

  &.frame-medium,
  &.frame-large {
    padding: 16px;
  }

  svg {
    height: auto;
    min-width: 24px;
    width: 24px;
  }

  &.flex-row {
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  &.align-center {
    align-items: center;

    .information-frame-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: $screen-medium) {
        text-align: left;
      }
    }
  }

  .information-frame-container {
    width: 100%;

    h5 {
      margin-bottom: 10px;

      @media (max-width: $screen-medium) {
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .color-dark {
      color: $black;
    }
  }
}

p.information-frame-summary {
  margin: 0;
  color: $black;
  font-size: 18px;
  line-height: normal;

  @media (max-width: $screen-medium) {
    font-size: 16px;
  }
}
