.header-search-input-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  position: relative;
  padding: 0 11px 5px;
  margin: 14px 20px 20px;
  border-bottom: 1px solid rgba($grey, 0.2);

  input[type='text'] {
    border: none;
    padding: 7px 0 8px 42px;
    font-size: 14px;
    width: stretch;

    &::placeholder {
      color: $black;
    }
  }

  .header-search-input-mobile-serchicon {
    width: 25px;
    height: 24px;
    position: absolute;
    pointer-events: none;
  }

  .header-search-mobile-close {
    color: $grey;
    font-size: 14px;
  }
}

.header-search {
  .header-search-input {
    display: flex;
    background-color: $white;
    padding: 0 32px;

    input[type='text'] {
      flex: 1;
      border: none;
      padding: 0;
    }

    @media (max-width: $screen-medium) {
      padding: 0 24px;
    }
  }
}

.header-search-results {
  background-color: $white;
  display: flex;
  border-top: 1px solid $light-grey-200;
  border-bottom: 1px solid $light-grey-200;
  margin-top: 35px;

  .mfs {
    margin-bottom: 24px;
    display: flex;
    gap: 10px;
  }

  .mfs-item button {
    padding: 0;
    text-decoration: underline;
  }

  @media (max-width: $screen-medium) {
    margin-top: 0;
  }

  em {
    text-decoration: underline;
    font-style: normal;
    color: $black;
  }

  .header-search-results-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
  }

  .header-search-results-left {
    width: 75%;
    border-right: 1px solid $light-grey-200;

    .header-search-results-title {
      margin-bottom: 24px;

      @media (max-width: $screen-medium) {
        margin-left: 20px;
      }
    }

    .header-search-results-products {
      padding: 32px;
      height: 100%;

      .header-search-results-products-list {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        margin-bottom: 24px;

        @media (max-width: $screen-medium) {
          gap: 0 !important;
          justify-content: space-between;
          padding: 20px;
          margin-top: -20px;
        }

        & > .header-search-results-products-item {
          width: calc(50% - 8px);

          @media (max-width: $screen-medium) {
            width: 47% !important;
            margin-bottom: 20px;
            display: block;
          }

          & > a {
            display: block;
            text-decoration: none;
            color: $black;

            em {
              font-style: normal;
            }

            h5 > em {
              font-weight: 600;
            }

            .cart-item-descgrey {
              font-size: 14px;
              color: $light-grey-300;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &:hover {
              background-color: $light-grey-200;

              @media (max-width: $screen-medium) {
                background-color: $transparent;
              }
            }
          }

          .cart-item {
            @media (max-width: $screen-medium) {
              display: block;
            }

            .content {
              min-width: 0;
              gap: 8px;
              width: 50%;

              @media (max-width: $screen-medium) {
                margin: 10px 0 0 !important;
                text-align: center;
                width: 100%;
              }
            }

            picture {
              width: 120px;
              height: 120px;
              aspect-ratio: 1/1;
              position: relative;

              @media (max-width: $screen-medium) {
                display: block;
                width: 100%;
                height: 100%;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }

      .header-search-results-products-button {
        text-align: center;
        margin-top: 30px;

        .button {
          margin: 0 auto;
          display: block;
          color: $white;

          @media (max-width: $screen-medium) {
            margin-bottom: 30px;
          }

          &:hover {
            color: $black;
          }
        }
      }

      &.noresult {
        background-color: $light-grey-200;

        .noresult-title {
          margin-bottom: 32px;
        }

        .noresult-link {
          a {
            color: $grey;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .header-search-results-right {
    padding: 32px;
    width: 25%;

    .header-search-results-title {
      margin-bottom: 16px;
    }

    .header-search-results-categories {
      margin-bottom: 24px;

      .header-search-results-categories-list {
        .header-search-results-categories-item {
          a {
            color: $black;
            font-size: 15px;
            text-decoration: none;
            display: inline-block;
            width: 100%;
            padding: 8px;

            &:hover {
              background-color: $light-grey-200;
            }
          }
        }
      }

      &.noresult {
        .noresult-title {
          font-size: 14px;
          color: $grey;
          font-style: italic;
        }
      }
    }

    .header-search-results-pages {
      .header-search-results-pages-list {
        .header-search-results-pages-item {
          a {
            color: $black;
            text-decoration: none;
            display: block;
            padding: 8px;

            &:hover {
              background-color: $light-grey-200;
            }

            .header-search-results-pages-item-title {
              font-size: 15px;
            }

            .header-search-results-pages-item-desc {
              font-size: 14px;
              color: $light-grey-300;
              margin-top: 4px;
            }
          }
        }
      }

      &.noresult {
        .noresult-title {
          font-size: 14px;
          color: $grey;
          font-style: italic;
        }
      }
    }
  }

  @media (max-width: $screen-medium) {
    flex-direction: column;

    .header-search-results-left {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $light-grey-200;

      .header-search-results-products {
        padding: 0;

        &.noresult {
          padding: 32px;
        }

        .header-search-results-products-list {
          gap: 16px;

          .cart-item {
            picture {
              width: 110px;
              height: 100px;

              img {
                width: 100% !important;
                height: 100% !important;
                object-fit: cover;
              }
            }

            .content {
              margin-left: 8px;
              margin-top: 0;
            }
          }
        }
      }
    }

    .header-search-results-right {
      width: 100%;
      padding: 24px;
    }
  }

  @media (max-width: $screen-small) {
    .header-search-results-left {
      .header-search-results-products {
        .header-search-results-products-list {
          & > .header-search-results-products-item {
            width: 100%;
          }
        }
      }
    }
  }
}
