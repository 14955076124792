body.has-message-push {
  .register-page {
    margin: 0 0 200px;

    @media (max-width: $screen-medium) {
      margin: 0;
    }
  }
}

.login-page {
  margin: 0 0 270px;

  @media (max-width: $screen-medium) {
    margin: 0 0 50px;
  }

  section.login {
    margin: 0 auto;

    @media (max-width: $screen-large) {
      margin: 0 30px;
    }

    @media (max-width: $screen-small) {
      margin: 0 0 30px;
    }

    .layout {
      max-width: 1064px;
    }

    .title-h3 {
      margin-bottom: 120px;
      text-align: center;

      @media (max-width: $screen-medium) {
        margin-bottom: 60px;
      }
    }

    .blocs {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;
      text-align: center;

      @media (max-width: $screen-medium) {
        flex-direction: column;
        gap: 50px;
      }

      .separator {
        width: 1px;
        height: 217px;
        background-color: $transparent-black-15;

        @media (max-width: $screen-medium) {
          display: none;
        }
      }

      .bloc {
        align-items: center;

        @media (max-width: $screen-medium) {
          margin: 0 auto;
        }

        &.bloc-form-login {
          max-width: 385px;
          width: 100%;

          @media (max-width: $screen-medium) {
            width: 80%;
            max-width: inherit;
          }

          @media (max-width: $screen-small) {
            width: 100%;
          }

          iframe {
            margin-bottom: 35px;
          }
        }

        &.bloc-register {
          max-width: 432px;
          position: relative;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;

          @media (max-width: $screen-medium) {
            width: 80%;
            max-width: inherit;
          }

          @media (max-width: $screen-small) {
            width: 90%;
          }

          .container-text {
            p {
              font-size: 16px;
              color: $grey;
              text-align: center;
              line-height: 21px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 6;
              overflow: hidden;
            }

            @media (max-width: $screen-medium) {
              margin-top: 20px;
            }
          }

          .new-client {
            @media (max-width: $screen-medium) {
              margin-top: 20px;

              .button {
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }

    .flex {
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;

      .container-switch {
        height: 17px;

        &.passwordError {
          margin-top: 20px;
        }

        label {
          color: $black;
        }
      }

      a.forgoten-password {
        text-decoration: underline;
        font-size: 12px;
        color: $black;
        margin-left: auto;
        margin-top: 3px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .forgot-password-modal {
    position: absolute;
    top: 100px;
    left: 0;
    background-color: $black;
    color: $white;
    z-index: 99;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    opacity: 0;
    animation: fade-in 0.5s ease-in-out 1s forwards, fade-out 0.5s ease-in-out 5s forwards;
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}
