.page-collection,
.page-landingceline,
.landing-page {
  .content-cms-bloc {
    &:last-child {
      padding-bottom: 120px;

      @media (max-width: $screen-medium) {
        padding-bottom: 64px;
      }

      .Block-07 {
        padding-bottom: 0;

        @media (max-width: $screen-medium) {
          padding-bottom: 0;
        }
      }
    }

    &:not(:last-child) {
      > div:not(.padding, .move-container):last-child {
        padding-bottom: 120px;

        @media (max-width: $screen-medium) {
          padding-bottom: 64px;
        }
      }
    }

    &:empty {
      @media (max-width: $screen-medium) {
        padding: 32px 0;
      }
    }
  }
}

.page-collection {
  .header-media-category {
    min-height: 550px;
    height: 550px;
    margin-bottom: 0;

    @media (min-width: $legacy-screen-xl) and (max-height: $legacy-screen-sm) {
      min-height: 550px;
      height: 550px;
    }

    .header-media-content {
      @media (min-width: $legacy-screen-xl) and (max-height: $legacy-screen-sm) {
        padding-top: 149px;
        transform: translate3d(-50%, -55%, 0);
      }

      .header-media-descr-wrapper {
        margin-top: 8px;
      }

      .descr p,
      .descr {
        font-size: 18px;
        line-height: 1.2;
        font-weight: 400;
      }

      .title-h1 {
        font-size: 85px;
        line-height: 74px;
        font-weight: 400;
        text-transform: uppercase;
        margin: 0;

        @media (max-width: $screen-medium) {
          font-size: 47px;
          line-height: 45px;
        }
      }

      .subtitle {
        margin: 0;
        font-weight: 400;
      }
    }
  }

  .breadcrumbs {
    margin-top: 30px;
    padding: 0;

    @media (max-width: $screen-medium) {
      margin-top: 25px;
    }
  }

  .collection-content {
    margin-top: 27px;

    @media (min-width: $legacy-screen-xl) and (max-height: $legacy-screen-sm) {
      margin-top: 20px;
    }

    &.grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(100px, auto));
      gap: 11px 16px;

      @media (max-width: $screen-medium) {
        gap: 29px 10px;
        grid-template-columns: repeat(2, minmax(100px, auto));
      }
    }

    .collection-mosaic {
      grid-column: span 2;
      grid-row: span 2;
      height: 97%;
      width: 100%;
      overflow: hidden;

      @media (max-width: $screen-medium) {
        width: 100%;
        min-height: 501px;
      }
    }

    // .selection-item-with-animation {
    //   //transform: translateY(20px);
    //   //opacity: 0;

    //   &.leave {
    //     animation: slide-down 1s ease forwards;
    //   }

    //   // &.enter {
    //   //   animation: slide-up 0.5s ease forwards;
    //   // }
    // }

    // @keyframes slide-up {
    //   to {
    //     opacity: 1;
    //     transform: translateY(0);
    //   }
    // }

    // @keyframes slide-down {
    //   from {
    //     opacity: 1;
    //     transform: translateY(0);
    //   }

    //   to {
    //     opacity: 0;
    //     transform: translateY(20);
    //   }
    // }
  }

  .gift {
    margin-top: 97px;
  }

  .story {
    padding: 184px 0 92px;
  }

  // .benefit {
  //   padding: 94px 0 63px;

  //   @media (max-width: $screen-medium) {
  //     padding-top: 50px;
  //     padding-bottom: 34px;
  //   }
  // }
}

.page-landingceline {
  .header-media-category {
    .header-media-content {
      .header-media-descr-wrapper {
        margin-top: 8px;
      }

      .descr p {
        font-size: 18px;
        line-height: 1.2;
      }
    }
  }
}
