.confirmation-page {
  .header-media-category {
    margin-bottom: 0;
    min-height: 600px;
    height: 600px;

    @media (max-width: $screen-medium) {
      min-height: inherit;
      height: 400px;
    }

    .header-media-content {
      max-width: inherit;
      top: 46%;

      .header-media-descr-wrapper {
        margin-top: 24px;
      }

      .title-h1 {
        font-size: 80px;
        letter-spacing: -2px;
        padding: 0 50px;
        max-width: 1200px;
        margin: 0 auto;

        @media (max-width: $screen-medium) {
          margin-bottom: 20px;
          font-size: 30px;
          line-height: 1.1;
          max-width: inherit;
          padding: 0;
          letter-spacing: 0;
        }
      }

      strong {
        font-weight: 500;
      }

      .descr {
        @media (max-width: $screen-medium) {
          width: 100%;
        }
      }

      .subtitle,
      .descr,
      .descr p {
        @include primaryFont;

        font-size: 18px;
        margin: 0 auto;
      }
    }
  }

  .confirmation {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (max-width: $screen-medium) {
      margin-top: 32px;
    }

    .confirmation-summary {
      display: flex;
      justify-content: center;
      max-width: 850px;
      margin: 29px auto 0;
      width: 100%;

      section:first-child {
        border: 1px solid $black-transparent-10;
        padding: 0 40px;

        @media (max-width: $screen-medium) {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }

    .small {
      text-align: center;
      color: $grey;
      font-size: 12px;
      width: 840px;
      margin: 0 auto;
      line-height: 15px;

      @media (max-width: $screen-medium) {
        width: 100%;
        margin-top: -15px;
      }
    }

    .confirmation-register {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      text-align: center;
      margin-top: 63px;

      @media (max-width: $screen-medium) {
        gap: 0;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 24px;
      }

      h3 {
        letter-spacing: 0;
      }

      .info {
        color: $grey;
        font-size: 18px;
        text-align: center;
        line-height: 1.2;
        margin-top: -5px;

        @media (max-width: $screen-medium) {
          font-size: 16px;
          margin-top: 24px;
          margin-bottom: 17px;
          line-height: 21px;
        }

        span {
          display: block;

          @media (max-width: $screen-medium) {
            font-size: 16px;
          }
        }
      }
    }

    .button {
      font-weight: 500;
      letter-spacing: 1px;
      margin-top: 5px;

      &.button--large {
        padding: 10px 16px;

        @media (max-width: $screen-medium) {
          padding: 10px;
          margin-top: 7px;
        }
      }

      @media (max-width: $screen-medium) {
        margin-top: 7px;
      }
    }

    .frame-newsletter {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: -16px;

      .info {
        margin-bottom: 15px;
      }
    }

    .confirmation-newsletter {
      background: $light-grey-100;
      display: flex;
      flex-direction: column;
      padding: 24px 30px 38px;
      gap: 32px;
      align-items: center;
      text-align: center;
      max-width: 850px;
      margin: 0 auto;

      @media (max-width: $screen-medium) {
        padding-left: 0;
        padding-right: 0;
      }

      .information-frame {
        padding: 16px 40px;
        background: $transparent;

        @media (max-width: $screen-medium) {
          padding: 16px 15px;
        }

        &.align-center .container {
          @media (max-width: $screen-medium) {
            text-align: center;
          }
        }
      }

      .information-frame .container h5 {
        margin-bottom: 18px;

        @media (max-width: $screen-medium) {
          font-size: 16px;
        }
      }

      p {
        color: $grey;
      }

      .info {
        font-size: 18px;
        text-align: center;
        line-height: 1.2;

        @media (max-width: $screen-medium) {
          font-size: 16px;
          line-height: 21px;
        }
      }

      .frame-newsletter {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: -16px;

        .info {
          margin-bottom: 16px;

          &.message {
            margin-top: 15px;
            margin-bottom: 0;
          }
        }
      }
    }

    .confirmation-button {
      display: flex;
      justify-content: center;
      margin-bottom: 100px;

      @media (max-width: $screen-medium) {
        margin-bottom: 60px;
      }
    }

    label.switch {
      gap: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      span.label {
        font-size: 16px;
        margin: 0;

        @media (max-width: $screen-medium) {
          font-size: 14px;
        }
      }

      span.decorator {
        height: 26px;
        width: 50px;
        margin-bottom: 3px;

        &::before {
          width: 100%;
          height: 100%;
        }

        &::after {
          top: 2px;
          left: 2px;
          width: 21px;
          height: 21px;
        }
      }

      input:checked + span.decorator::after {
        transform: translate3d(26.5px, 2.5px, 0);
      }
    }
  }
}
