[data-aos] {
  transition-duration: 0.4s;
}

[data-aos="fade-up"] {
  transform: translate3d(0,100px,0);
}

[data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos-duration="800"] {
  transition-duration: 0.8s;
}

[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0; 
  transition-property: opacity,transform;

  &.aos-animate {
    opacity: 1;
    transform: translateZ(0);
  }
}
