.select-wrapper {
  position: relative;
  font-weight: 400;

  select {
    border: none;
    font-size: 16px;
    appearance: none;
    background-color: $transparent;
  }

  a {
    color: $light-grey-300;
    position: absolute;
    right: 20px;
    font-size: 14px;
  }

  .select-wrapper-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    pointer-events: none;

    @media (max-width: $screen-medium) {
      width: 20px;
      height: 20px;
    }
  }

  &.default {
    .select-wrapper-label {
      position: absolute;
      font-size: 12px;
      bottom: 100%;
      left: 0;
    }

    select {
      border-bottom: 1px solid $black;
      color: $black;
      padding: 7px 32px 7px 0;
      border-radius: 0;
    }
  }

  &.inline {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $black-transparent-10;
    font-size: 16px;

    label {
      color: $grey;

      &::after {
        margin: 0 5px;
        content: ':';
      }
    }

    select {
      width: 100%;
      padding: 10px 32px 10px 0;
    }
  }

  span.error {
    color: $red;
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 12px;
    height: 10px;
  }
}
