@import 'config/fonts';
@import 'config/variables';
@import 'config/resetCss';
@import 'config/base';
@import 'config/theme';
@import 'config/layout';
@import 'config/typography';
@import 'config/accessibility';
@import 'config/legacy-variables';
@import '../../../node_modules/leaflet/dist/leaflet.css';
@import '../../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';

// LEGACY
@import 'legacy/some-cms-legacy'; // TODO LEGACY
@import 'legacy/actualites-legacy'; // TODO LEGACY
@import 'legacy/celebrities-legacy'; // TODO LEGACY
@import 'legacy/celibrity-legacy'; // TODO LEGACY
@import 'legacy/contact-legacy'; // TODO LEGACY
@import 'legacy/la-maison-messika-legacy'; // TODO LEGACY
@import 'legacy/services-legacy'; // TODO LEGACY
@import 'legacy/cms-page-view-legacy'; // TODO LEGACY
@import 'legacy/faq-legacy'; // TODO LEGACY
@import 'legacy/category-legacy'; // TODO LEGACY
@import 'legacy/statics-legacy'; // TODO LEGACY
@import 'legacy/blog-legacy'; // TODO LEGACY
@import 'legacy/block-fromlegacy'; // TODO LEGACY
@import 'legacy/block-hero-fromlegacy'; // TODO LEGACY
@import 'legacy/block-item-fromlegacy'; // TODO LEGACY
@import 'legacy/block-grid-fromlegacy'; // TODO LEGACY
@import 'legacy/block-slider-fromlegacy'; // TODO LEGACY
@import 'legacy/animation-fromlegacy'; // TODO LEGACY

// PAGES
@import 'pages/homepage';
@import 'pages/landingpage';
@import 'pages/errorpage';
@import 'pages/suivi';
@import 'pages/sellerspage';
@import 'pages/newsletter';
@import 'pages/searchpage';
@import 'pages/alma';
@import 'pages/collection';
@import 'pages/product';
@import 'pages/checkout';
@import 'pages/confirmation';
@import 'pages/landingCeline';
@import 'pages/cart';
@import 'pages/boutique';
@import 'pages/dashboardProfil';
@import 'pages/sitemap';
@import 'pages/login';
@import 'pages/logout';

// ALL
@import 'components/arrowNavigation';
@import 'components/contrast';
@import 'components/form';
@import 'components/headerAlertNotification';
@import 'components/headerNavigationDesktop';
@import 'components/headerNavigationMobile';
@import 'components/infoPush';
@import 'components/inputCheckbox';
@import 'components/inputRadio';
@import 'components/inputText';
@import 'components/loading';
@import 'components/logo';
@import 'components/myMoveCollection';
@import 'components/myOrders';
@import 'components/nav';
@import 'components/select';
@import 'components/selectslot';
@import 'components/selectWrapper';
@import 'components/seller-item';
@import 'components/sliderNavigation';
@import 'components/sliderPagination';
@import 'components/switch';
@import 'components/contact';
@import 'components/register';
@import 'components/login';
@import 'components/textWrapper';
@import 'components/loader';
@import 'components/account';
@import 'components/modalReturn';
@import 'components/animate';
@import 'components/recaptcha';
@import 'components/defaultShippingMethod';
@import 'components/alerts';
@import 'components/placesAutocomplete';
@import 'components/listSelection';

// CLEAN BLOCKS
@import 'block/block-dotsAnimation';
@import 'block/block-goToTop';
@import 'block/block-footer';
@import 'block/block-header';
@import 'block/block-benefit';
@import 'block/block-hero';
@import 'block/block-selection-item';
@import 'block/block-mosaic';
@import 'block/block-gift';
@import 'block/block-story';
@import 'block/block-filter-product';
@import 'block/block-media';
@import 'block/block-reassure';
@import 'block/block-navigation-container';
@import 'block/block-productMyMove';
@import 'block/block-cartItem';
@import 'block/block-informationFrame';
@import 'block/block-addressForm';
@import 'block/block-summary';
@import 'block/block-breadcrumb';
@import 'block/block-headerSearch';
@import 'block/block-modal';
@import 'block/block-modalCarving';
@import 'block/block-modalCart';
@import 'block/block-modalNotifyInStock';
@import 'block/block-modalNewsletter';
@import 'block/block-modalDeleteProduct';
@import 'block/block-modalContrast';
@import 'block/block-modalGeoip';
@import 'block/block-accordion';
@import 'block/block-select';
@import 'block/block-minicart';
@import 'block/block-seoContent';
@import 'block/block-personal-information';
@import 'block/block-newsletter';
@import 'block/block-resumeAddress';
@import 'block/block-defaultAddress';
@import 'block/block-wishlist';
@import 'block/block-edit-customer';
@import 'block/block-customerAddress';
@import 'block/block-modalDeleteCustomerAddress';
@import 'block/block-list-selection-grid';
@import 'block/block-move';
@import 'block/block-termsOfUse';

// LANG
@import 'lang/ar';

// utilities
@import 'utilities';
@import 'aos';
