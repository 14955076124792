// TODO rework style, because this css is coming from legacy project
@mixin crossIconTitleAccordion {
  &::before {
    content: '';
    position: absolute;
    transform-origin: center;
    height: 20px;
    width: 2px;
    background-color: $black;
    display: block;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    transform-origin: center;
    right: 50px;
    top: 50%;
    height: 20px;
    width: 2px;
    background-color: $black;
    display: block;
    transform: translateY(-50%) rotate(90deg);
  }
}

.faq-page {
  background-color: #f5f5f5;

  @media (max-width: $screen-medium) {
    overflow: hidden;
    width: 100vw;
  }

  .bg-white {
    background-color: $white;
    padding: 0 0 20px;
  }
}

.faq-page-container {
  overflow: hidden;

  .page-main {
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;

    @media (max-width: $screen-medium) {
      padding: 0 15px;
      max-width: 100vw;
      overflow: hidden;
    }

    .breadcrumbs {
      padding: 0;
    }

    .title-h1 {
      font-size: 40px;
      @include primaryFont;
      font-weight: 300;
      margin-top: 18px;
      @media (max-width: $screen-medium) {
        font-size: 26px;
      }
    }
  }

  .mageprince-faq-collection {
    p {
      color: $black;
      font-size: 16px;
      line-height: 1.5;
      @include primaryFont;
      font-weight: 300;
      margin: 0;
    }

    .faq-groups-wrapper {
      &::before {
        content: '';
        display: block;
        width: 110%;
        background-color: $white;
        position: absolute;
        left: 0;
        height: 55px;
        transform: translateX(0%);
      }
    }

    .faq-groups {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
      display: flex;
      justify-content: flex-start;
      background-color: $white;

      .faq-group {
        display: inline-block;
        margin-right: 40px;

        &:last-of-type {
          margin-right: 0;
        }

        .faq-groupname {
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          margin: 0;

          .group-link {
            display: inline-block;
            padding: 20px 0;
            font-weight: 500;
            letter-spacing: 0.75px;
            color: $black;
            font-size: 15px;
            position: relative;
            @include primaryFont;
            @media (max-width: $screen-medium) {
              font-size: 12px;
              line-height: 17px;
              padding: 15px 0;
            }

            &.active,
            &:hover {
              &::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                height: 3px;
                width: 100%;
                background-color: $black;
              }
            }
          }
        }
      }
    }

    .faq-content {
      .faq-accordion {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .faq-accordion-subGroup {
          .faq-accordion-subGroup-title {
            background-color: $white;
            cursor: pointer;
            position: relative;
            margin: 0;
            padding: 40px;
            padding-right: 72px;
            font-size: 20px;
            color: $black;
            text-transform: uppercase;
            @include primaryFont;
            span {
              font-weight: 100;
            }

            @include crossIconTitleAccordion;

            @media (max-width: $legacy-screen-l) {
              padding: 30px;
              padding-right: 72px;
              font-size: 16px;
              line-height: 20px;
            }
          }

          .faq-accordion-subGroup-content {
            overflow: hidden;
            max-height: 0;
            background-color: $white;
            position: relative;
            transition: all 1.5s ease;

            // html from BO
            .subFaqAccordion {
              padding: 0 40px;

              .ui-accordion-header {
                background-color: $white;
                cursor: pointer;
                position: relative;
                margin: 0;
                padding: 40px;
                padding-right: 72px;
                font-size: 20px;
                color: $black;
                @include primaryFont;
                font-weight: 300;

                .fa-icon-cross {
                  @include crossIconTitleAccordion;
                }

                &.active {
                  .fa-icon-cross::before {
                    height: 0;
                  }
                }

                @media (max-width: $legacy-screen-l) {
                  padding: 30px;
                  padding-right: 72px;
                  font-size: 16px;
                  line-height: 20px;
                }

                &::before {
                  position: absolute;
                  content: '';
                  top: 0;
                  left: 0;
                  height: 1px;
                  background-color: #e7e7e7;
                  width: 100%;
                }

                svg {
                  transition: all 0.5s ease;
                }

                &.active {
                  svg {
                    transform: rotate(180deg);
                  }
                }
              }

              .ui-accordion-content {
                padding-left: 60px;
                padding-right: 60px;
                max-height: 0;
                overflow: hidden;
                transition: all 1.5s ease;
                margin-bottom: 0;

                &.active {
                  max-height: fit-content;
                  margin-bottom: 40px;
                }
              }
            }
          }

          &.active {
            .faq-accordion-subGroup-title {
              &::before {
                height: 0;
              }
            }

            .faq-accordion-subGroup-content {
              max-height: 1500px;
              @media (max-width: $screen-medium) {
                max-height: inherit;
              }
            }
          }
        }
      }
    }

    .faq-block {
      display: inline-block;
      box-sizing: border-box;
      margin-bottom: 16px;
      width: 100%;
      position: relative;

      hr {
        width: 100%;
        height: 0;
        border: 0;
        border-bottom: 0.5px solid white;
        margin: 24px 0 32px;
      }

      p {
        color: $black;
        font-size: 16px;
        line-height: 1.5;
        @include primaryFont;
        font-weight: 300;
        margin: 0;
      }

      a[type='phone'] {
        font-size: 16px;
        text-transform: uppercase;
        @include primaryFont;
        font-weight: 300;
        color: $white;
      }

      &-01 {
        background-color: $black;
        padding: 50px;

        .faq-title-01 {
          margin-bottom: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        .faq-title-01,
        a[type='phone'],
        p {
          color: $white;
        }
      }

      &-02 {
        background-color: $black;
        padding: 30px 50px;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        @include primaryFont;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white !important;

        &:hover {
          text-decoration: none !important;
        }
      }

      &-03 {
        background-color: $black;
        padding: 50px;

        .faq-title-01 {
          margin-bottom: 12px;
        }

        .faq-title-01,
        a[type='phone'],
        p {
          color: $white;
        }
      }
    }

    @media (min-width: $legacy-screen-l) {
      // GRID TEMPLATE
      display: grid;
      grid-template-columns: 1fr 400px;
      grid-column-gap: 16px;
      grid-template-rows: auto;
      grid-template-areas:
        'header header'
        'left right';

      .faq-groups-wrapper {
        grid-area: header;
        width: 100%;
        overflow: auto;
        white-space: nowrap;
      }

      .faq-content {
        margin-bottom: 100px;
        grid-area: left;
        grid-row: span 100;
      }

      .faq-block {
        grid-area: right;
        grid-row: auto;
        width: 400px;

        &-01 {
          padding-bottom: 0;

          &--mobile {
            display: none;
          }
        }

        &-03 {
          padding-top: 0;
          margin-top: -16px;
        }
      }
    }

    @media (max-width: $legacy-screen-l) {
      display: flex;
      flex-direction: column;

      //GRID TEMPLATE
      > * {
        order: 3;
      }

      .faq-groups-wrapper {
        width: 100%;
        overflow: auto;
        white-space: nowrap;
        order: 1;
      }

      .faq-block {
        margin-bottom: 8px;
        padding: 30px;

        &-01 {
          hr {
            display: none;
          }

          order: 2;
          margin-bottom: 20px;
        }

        &-03 {
          padding: 30px;
          margin-bottom: 8px;
        }
      }

      .faq-content {
        padding: 0;
        margin-bottom: 50px;
        margin-top: 20px;
      }

      .faq-groups {
        order: 1;
        background-color: $white;
        position: relative;
      }

      .faq-groupname .group-link {
        font-size: 12px;
        line-height: 17px;
        padding: 15px 0;
      }

      .subFaqAccordion {
        .ui-accordion-header {
          padding: 30px 52px 30px 20px;

          &.ui-accordion-header-active {
            &::after {
              display: none;
            }
          }
        }

        .ui-accordion-content {
          padding-left: 40px;
          padding-right: 50px;

          p {
            font-size: 14px;
          }

          &.active {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

.faq-block {
  .whatsap-icon {
    display: block;
    margin-left: 10px;
  }
}

.faq-page-group {
  .container-slider-nav-faq {
    width: 100%;
    position: relative;
    padding: 40px 0 0;
    margin-bottom: 20px;
    background-color: $white;
    @media (max-width: $screen-medium) {
      padding: 0;
      margin-bottom: 40px;
    }

    &:before {
      content: '';
      display: block;

      background-color: $white;
      width: 250%;
      height: 100%;

      position: absolute;
      left: -100%;
      top: 0;
    }

    &:has(.arrow-navigation .faq-groups-arrow-left:not(.disabled)) .swiper {
      padding-left: 30px;
    }

    &:has(.arrow-navigation .faq-groups-arrow-right:not(.disabled)) .swiper {
      padding-right: 30px;
    }
  }

  .swiper {
    width: 100%;
  }

  .swiper-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .swiper-slide {
    width: auto;

    &:last-child {
      margin-right: 0 !important;
    }

    a.group-link {
      display: block;
      width: auto;
      font-weight: 500;
      letter-spacing: 0.75px;
      color: $black;
      font-size: 15px;
      position: relative;
      text-transform: uppercase;
      padding-bottom: 20px;

      @include primaryFont;

      @media (max-width: $screen-medium) {
        font-size: 12px;
        line-height: 17px;
      }

      &.active,
      &:hover {
        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          height: 3px;
          width: 100%;
          background-color: $black;
        }
      }
    }
  }

  .arrow-navigation {
    .faq-groups-arrow {
      width: 25px;

      padding: 4px 6px 1px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      z-index: 1;
      transform: translate3d(0, -4%, 0);
      @media (max-width: $screen-medium) {
        transform: translate3d(0, -94%, 0);
      }

      &.disabled {
        display: none;
      }

      &:after {
        content: '';
        display: block;
        width: 25px;
        height: 20px;
        position: absolute;
        top: 0;
        z-index: -1;
      }

      &.faq-groups-arrow-left {
        left: -6px;

        &:after {
          background: linear-gradient(90deg, $white 0%, $white 50%, rgba(255, 255, 255, 80%) 100%);
        }
      }

      &.faq-groups-arrow-right {
        right: -6px;

        &:after {
          background: linear-gradient(270deg, $white 0%, $white 50%, rgba(255, 255, 255, 80%) 100%);
        }
      }
    }
  }
}
