.cms-block-noheadless {
  .swiper-container {
    .swiper-slide {
      max-height: 100%;

      @media (max-width: $legacy-screen-l) {
        width: 100% !important;
      }

      .content-wrapper {
        .slide-title {
          @include secondaryFont;
          font-size: 80px;
          color: $white;
          opacity: 0.5;
          text-transform: uppercase;
          line-height: 56px;

          @media (max-width: $legacy-screen-l) {
            font-size: 12vw;
            line-height: 9vw;
          }

          &.wHash {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            width: auto;
            max-width: min-content;
            position: relative;

            span {
              display: inline-block;
              position: relative;
            }

            &::before {
              display: block;
              content: '#';
              @include secondaryFont;
              font-size: 160px;
              text-transform: uppercase;
              color: #fff6;
              position: absolute;
              top: 50%;
              transform: translate3d(-57px, -50%, 0);
            }
          }
        }

        .slide-subtitle {
          @include primaryFont;
          font-size: 14px;
          color: $white;
          text-transform: uppercase;
          font-weight: lighter;
        }

        p {
          width: 66%;
          color: $white;
          font-size: 18px;

          @media (max-width: $legacy-screen-l) {
            width: 100%;
            margin: 0;
          }
        }

        .cta-button {
          margin-top: 60px;

          @media (max-width: $legacy-screen-l) {
            margin-top: 20px;
          }
        }
      }

      &.menu-transparent-dark {
        .content-wrapper {
          .slide-title {
            color: $black;
          }

          .slide-subtitle {
            color: $black;
          }

          p {
            color: $black;
          }

          .cta-button {
            margin-top: 60px;

            @media (max-width: $legacy-screen-l) {
              margin-top: 20px;
            }
          }
        }
      }
    }

    .swiper-button-prev {
      left: 25px;
      transform: translate(-1px, -25px);

      &::after {
        position: absolute;
        content: '';
        width: 60px;
        height: 1px;
        bottom: -14px;
        left: -25px;
        background: $white;
      }
    }

    .swiper-button-next {
      left: 25px;
      transform: translate(-1px, 25px);
    }

    .swiper-button-next svg,
    .swiper-button-prev svg {
      width: 8px;
      position: relative;
    }

    .swiper-pagination.swiper-pagination-bullets {
      display: flex;
      align-items: center;
      justify-content: center;
      width: initial;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: $legacy-screen-l) {
        bottom: 18px;
      }
    }

    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
      background-color: $white;
      margin-left: 7px;
      margin-right: 7px;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      width: 7px;
      height: 7px;
      background-color: $transparent;
      border: 1px solid white;
    }

    .swiper-counter {
      display: none;
      top: 50%;
      left: 80px;
      font-size: 12px;
      @include primaryFont;
      font-weight: 500;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    &-01 {
      width: 100%;
      box-sizing: border-box;
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: $white;

        /* Center slide text vertically */
        display: -webkit-box;
        display: flexbox;
        display: flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: auto;
      }
    }

    &-02 {
      width: 100%;
      box-sizing: border-box;

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: $white;

        /* Center slide text vertically */
        display: -webkit-box;
        display: flexbox;
        display: flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        align-items: flex-start;
      }
    }
  }
}
