.product-template .pictures .product-assets {
  &.product-assets-my-move .product-assets-list {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;

    @media (max-width: $screen-medium) {
      flex-direction: inherit;
      flex-wrap: inherit;
      gap: 0;
    }

    .product-assets-item {
      width: calc(50% - 8px);
      margin-bottom: 0;

      @media (max-width: $screen-medium) {
        width: 100%;
      }

      &:first-child {
        width: 100%;
      }

      &.hide {
        display: none;

        @media (max-width: $screen-medium) {
          display: block;
        }
      }
    }
  }

  .product-assets-seeall {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 0 auto;
    }

    @media (max-width: $screen-medium) {
      display: none;
    }
  }
}
