.modal-delete-product {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  margin: 20px;
  width: min-content;

  @media (max-width: $screen-medium) {
    margin: 0;
    width: 100%;
  }

  picture {
    width: 100%;
    min-width: 300px;

    @media (max-width: $screen-small) {
      min-width: 200px;
    }

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    color: $grey;

    @media (max-width: $screen-medium) {
      font-size: 18px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .button-group {
    display: flex;
    gap: 24px;

    button {
      margin: 0;
    }

    @media (max-width: $screen-medium) {
      gap: 8px;
    }
  }
}
