.footer {
  background-color: $black;
  padding: 137px 80px 40px;
  position: relative;
  overflow-x: hidden;

  @media (max-width: $screen-medium) {
    padding: 64px 15px 50px;
  }

  &.footer-small {
    padding: 0 80px;

    @media (max-width: $screen-medium) {
      padding: 0 15px;
    }
  }

  .footer-logo {
    text-align: center;

    .icon--logo-footer {
      fill: $white;
      height: auto;
      width: 8em;

      @media (max-width: $screen-medium) {
        width: 4.5em;
      }

      path {
        fill: $white;
      }
    }
  }

  .footer-nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 19px;
    justify-content: center;
    margin: 136px 0 62px;
    text-align: center;

    @media (max-width: $screen-medium) {
      grid-template-columns: 1fr 1fr;
      margin: 52px 0 12px;
    }

    ul {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: $screen-medium) {
        align-items: center;
        margin-bottom: 21px;
      }

      li {
        margin-bottom: 4px;

        @media (max-width: $screen-medium) {
          margin-bottom: 0;
        }
      }

      &.footer-socials {
        flex-flow: row;
        gap: 8px;
        flex-wrap: wrap;
        margin-top: 2px;

        @media (max-width: $screen-small) {
          justify-content: center;
          margin: 0 auto;
          max-width: 120px;

          svg {
            width: 19px;
          }
        }

        img {
          height: 24px;
          width: 24px;

          @media (max-width: $screen-small) {
            height: 20px;
            width: 20px;
          }
        }
      }

      .nav-title {
        @include primaryFont;

        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 13px;

        @media (max-width: $screen-medium) {
          font-size: 16px;
          padding-bottom: 0;
          text-align: center;
          margin-bottom: 10px;
        }
      }

      .nav-link {
        @include primaryFont;

        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        padding: 8px 0;
        position: relative;

        @media (max-width: $screen-medium) {
          font-size: 14px;
          padding: 6px 0;
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 0;
          right: 0;
          width: 0;
          bottom: 6px;
          background-color: $white;
          height: 1px;
          transition-property: width;
          transition-duration: 0.3s;
          transition-timing-function: ease-out;
        }

        &:hover::after,
        &:focus::after,
        &:active::after {
          left: 0;
          right: auto;
          width: 100%;
        }
      }

      .nav-title,
      .nav-link {
        color: $white;
      }
    }
  }

  .footer-links {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    padding: 42px 0;
    gap: 16px 32px;
    border-top: 1px solid $grey;

    @media (max-width: $screen-medium) {
      padding: 16px 0;
      flex-wrap: wrap;
    }

    .title {
      display: none;
    }

    .legal-links {
      display: flex;
      flex-flow: row wrap;
      gap: 10px 40px;
      align-items: center;
      flex: 1;

      @media (max-width: $screen-medium) {
        gap: 3px 10px;
      }

      li:first-child {
        text-transform: uppercase;
      }

      .legal-link {
        @include primaryFont;

        font-size: 15px;
        font-weight: 400;
        text-decoration: none;
        color: $white;
        position: relative;

        @media (max-width: $screen-medium) {
          font-size: 13px;
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 0;
          right: 0;
          width: 0;
          bottom: 0;
          background-color: $white;
          height: 1px;
          transition-property: width;
          transition-duration: 0.3s;
          transition-timing-function: ease-out;
        }

        &:hover::after,
        &:focus::after,
        &:active::after {
          left: 0;
          right: auto;
          width: 100%;
        }
      }

      .footer-locales {
        margin-left: auto;
      }
    }
  }

  .footer-link-small {
    border: none;
  }

  .footer-selects {
    gap: 28px;

    @media (max-width: $screen-medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    &.footer-selects-items {
      top: 0;
    }

    @media (max-width: $screen-small) {
      &.footer-selects-items {
        padding-bottom: 23px;
        gap: 20px;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .footer-select {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      label {
        color: $light-grey-300;
        font-size: 14px;
        margin-right: 11px;
        white-space: nowrap;
      }

      select {
        @include primaryFont;

        padding-right: 16px;
        border: none;
        color: $white;
        font-size: 14px;
        margin-left: -4px;
        position: relative;
        background: url('../../icons/icon-nav-arrow-down-white.svg') right no-repeat;
        background-size: 10px;
        appearance: none;

        @media (max-width: $screen-medium) {
          font-size: 13px;
        }

        option {
          background-color: $black;
        }
      }
    }
  }

  .footer-selects-items {
    position: relative;
    top: -2px;
    display: flex;
    align-items: flex-start;
  }

  @media (max-width: $screen-small) {
    .footer-selects-items {
      display: flex;
    }
  }
}
