.cms-page-view {
  .top-container.breadcrumbs-wrapper {
    margin: 35px 0;

    @media (max-width: $legacy-screen-l) {
      margin: 0;
    }
  }

  video,
  embed,
  object {
    max-width: 100%;
  }

  .Video {
    &-wrapper {
      @media (max-width: $legacy-screen-l) {
        &.remote-opened {
          .js-video-remote {
            transform: translate3d(-50%, 0%, 0);
          }
        }
      }

      @media (min-width: $legacy-screen-l) {
        &:hover {
          .js-video-remote {
            transform: translate3d(-50%, 0%, 0);
          }
        }
      }
    }
  }

  .js {
    &-video {
      &-remote {
        display: flex;
        padding: 8px 20px;
        background-color: $black;
        position: absolute;
        font-size: 20px;
        bottom: 0;
        left: 50%;
        color: $white;
        transform: translate3d(-50%, 100%, 0);
        transition: transform 0.25s ease-in-out;
        width: auto;

        > * {
          display: inline-block;
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }
  }

  // CMS page suivi-commande title
  .page-main > .page-title-wrapper > h1.page-title {
    display: block;

    > .base {
      font-size: 40px;
      @include primaryFont;
      line-height: 1.1;
      font-weight: 300;
      text-transform: uppercase;
      text-align: center;
      margin: 70px auto 35px;
      display: block;
    }
  }

  .hero-container {
    position: relative;
    overflow: hidden;

    .test {
      display: block;
    }

    .hero-image,
    .hero-video {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $black;
      height: 50vh;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      picture {
        height: 100%;
        width: 100%;
      }

      .ultranoir-container-video {
        width: 100%;
        height: 100%;

        @media (max-width: $legacy-screen-l) {
          max-height: none;
          min-height: auto;
          height: auto;
          position: relative;

          .ultranoir-container-video {
            flex: 1;
          }
        }
      }

      img {
        max-width: none;
        width: 100%;
        display: block;
        position: relative;
        height: 100%;
        object-fit: cover;

        @media (max-width: $legacy-screen-l) {
          display: none;
        }

        &.mobile {
          display: none;

          @media (max-width: $legacy-screen-l) {
            display: block;
          }
        }
      }

      video {
        max-width: none;
        width: 100%;
        display: block;

        @media (max-width: $legacy-screen-l) {
          max-width: 100%;
          height: auto;
          width: auto;
          position: relative;
          display: inline-block;
          user-select: none;
        }
      }

      div.cover {
        min-width: 100%;
        height: 650px;
        background-size: cover;
        background-position: center;
      }
    }

    .hero-video {
      @media (max-width: $legacy-screen-l) {
        min-height: auto;
      }
    }

    .hero-content-wrapper {
      position: absolute;
      inset: 0;

      @media (max-width: $legacy-screen-l) {
        pointer-events: none;
      }

      .breadcrumbs {
        @media (max-width: $legacy-screen-l) {
          pointer-events: all;
        }
      }
    }

    .hero-flex-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding: 0 11.6vw;
      transform: translateY(-50%);
      position: relative;
      top: calc(50% + 65px);

      @media (max-width: $legacy-screen-l) {
        width: 90%;
        padding: 0;
        margin: auto;
        justify-content: flex-end;
        text-align: center;
        margin-bottom: 25px;
        position: relative;
        bottom: 25px;
        top: auto;
        transform: none;
      }

      // Tweenmax animation
      & > div {
        opacity: 0;
      }
    }

    .hero-content {
      position: relative;
      height: 100%;

      .breadcrumbs {
        margin-left: 0;
        color: $white;
        position: absolute;
        top: 20px;
        left: 0;

        ul {
          li {
            color: $white;

            a {
              color: $white;
              font-weight: 600;
            }

            &:last-child {
              color: $white;
            }
          }
        }
      }

      h2,
      p {
        color: $white;
      }

      h2 {
        @include primaryFont;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 1px;

        margin-top: 20px;

        &.page-title {
          margin: 0;
        }

        @media (max-width: $legacy-screen-l) {
          font-size: 18px;
        }

        @media (max-width: $legacy-screen-s) {
          font-size: 14px;
        }
      }

      .page-title-wrapper {
        h2 {
          margin: 0;

          @media (max-width: $legacy-screen-l) {
            margin-top: 15px;
            font-size: 17px;
          }
        }
      }

      p {
        font-size: 16px;
        max-width: 420px;
        padding-top: 2px;
        margin-bottom: 25px;
        font-weight: 600;

        @media (max-width: $legacy-screen-l) {
          font-size: 18px;
          margin-top: 0;
          line-height: 1;
          margin: 0 auto;
          margin-bottom: 20px;
          @include primaryFont;
          text-align: center;
          font-weight: 300;
        }
      }

      .cover-titles {
        @media (max-width: $legacy-screen-l) {
          margin: 0;
        }

        .css-gradient-text {
          background-clip: text;
          -webkit-text-fill-color: $transparent;
        }

        &.no-margin {
          margin-left: 0 !important;
        }

        .prefix {
          font-size: 30px;
          margin-right: -10px;

          @media (max-width: $legacy-screen-l) {
            font-size: 22px;
            margin-right: -5px;
          }

          &:empty {
            display: none;
          }
        }

        span {
          &:last-child {
            padding-left: 32px;

            @media (max-width: $legacy-screen-l) {
              padding: 0;
            }
          }
        }

        div,
        div > span {
          color: $white;
          font-size: 80px;
          @include secondaryFont;
          text-transform: uppercase;
          line-height: 1;

          @media (max-width: $legacy-screen-l) {
            font-size: 54px;
            text-align: center;
          }

          &:last-child {
            padding-left: 32px;

            &:first-child {
              padding-left: 0;
            }

            @media (max-width: $legacy-screen-l) {
              padding: 0;
            }
          }
        }
      }

      .primary-cat {
        h1 {
          color: $white;
          font-size: 12px;
          @include primaryFont;
          font-weight: 500;
          text-transform: uppercase;
          line-height: 16px;
          margin-bottom: 20px;
          max-width: 700px;

          @media (max-width: $legacy-screen-l) {
            font-size: 12px;
            margin-left: 0;
            line-height: 1;
          }

          span:nth-child(2) {
            display: none;
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        display: flex;
      }
    }

    @media (max-width: $legacy-screen-l) {
      max-height: calc(100vh - 190px);
    }

    /* iphone X : Portrait and Landscape */
    @media only screen and (minwidth: 375px) and (device-width <= 812px) and (-webkit-min-device-pixel-ratio: 3) {
      max-height: calc(100vh - 190px);
    }

    @media (min-width: $legacy-screen-l) {
      height: calc(100vh - 185px);
      min-height: 590px;
    }
  }

  .hero-infos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 15px;
    overflow: hidden;
    margin-top: 50px;

    h1,
    p {
      color: $black;
    }

    h1 {
      @include primaryFont;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 1px;
      margin-top: 20px;

      @media (max-width: $legacy-screen-l) {
        font-size: 18px;
      }
    }

    .page-title-wrapper {
      margin-bottom: 5px;

      h1 {
        margin: 0;

        @media (max-width: $legacy-screen-l) {
          margin-top: 15px;
          font-size: 17px;
        }
      }
    }

    p {
      font-size: 16px;
      max-width: 60%;
      padding-top: 2px;
      margin: 0 auto;
      @include primaryFont;
      font-weight: 300;

      @media (max-width: $legacy-screen-l) {
        font-size: 16px;
        margin-top: 0;
        line-height: 1;
        margin: 0 auto;
        margin-bottom: 20px;
        @include primaryFont;
        text-align: center;
        font-weight: 300;
      }
    }

    @media (max-width: $legacy-screen-l) {
      margin-top: 0;
      margin-bottom: 10px;

      .hero-description {
        p {
          padding-bottom: 0;
          margin-bottom: 0;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .collection-list-wrapper {
    margin-top: 80px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1166px;

    @media (min-width: $legacy-screen-l) {
      margin-bottom: 70px;
    }

    @media (max-width: $legacy-screen-l) {
      display: block;
      margin-top: 0;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 30px;
    }

    .collection-wrapper {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      @media (max-width: $legacy-screen-l) {
        overflow: initial;
      }

      a {
        display: block;
        height: 100%;

        &:hover {
          text-decoration: none;
        }

        &:visited {
          color: $black !important;
        }
      }

      img {
        display: block;
        transform-origin: center;
        transition: all 0.3s ease;

        &.only-mobile {
          display: none;
        }

        &:hover {
          text-decoration: none;
        }

        @media (max-width: $legacy-screen-l) {
          min-width: initial;

          &.only-desktop {
            display: none;
          }

          &.only-mobile {
            display: block;
          }
        }
      }

      .collection-title {
        font-size: 64px;
        color: $black;
        @include secondaryFont;
        position: absolute;
        bottom: 50%;
        left: -35px;
        text-transform: uppercase;
        width: 200vh;
        text-align: center;
        margin: 0;
        font-weight: normal;
        z-index: -1;
        transform-origin: center;
        line-height: unset;

        span {
          display: block;
          padding: 10px 0;
          transform-origin: center;
          transform: translate3d(-50.4%, 50%, 0) rotate(-90deg);

          @media (max-width: $legacy-screen-l) {
            padding: 7px 0;
            transform: translate3d(-49%, 50%, 0) rotate(-90deg);
          }
        }

        @media (max-width: $legacy-screen-l) {
          left: -37px;
        }
      }

      &:hover {
        img {
          transform: scale(1.05);
        }

        .cta-button {
          color: $white;
          text-decoration: none;
          background: $white;

          &::before {
            transform: scaleY(1);
          }

          &.white {
            color: $black;

            a {
              color: $black;
            }
          }
        }
      }

      .image-container {
        position: relative;
        width: 100%;
        padding-top: 133.33%;

        img {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .cta-button {
          padding: 6px 25px;
          font-size: 17px;
          @include primaryFont;
          font-weight: 500;
          text-transform: uppercase;
          position: absolute;
          left: 50%;
          bottom: 15%;
          transform: translateX(-50%);
        }
      }

      .image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        height: 100%;
      }

      .content-wrapper {
        text-align: center;
        padding: 25px 10px;
        @include primaryFont;
        font-weight: 300;

        h2,
        h3,
        h4 {
          color: $black;
          font-size: 17px;
          @include primaryFont;
          margin-bottom: 12px;
          text-transform: uppercase;
          text-decoration: none;
          font-weight: 500;

          @media (max-width: $legacy-screen-l) {
            margin-bottom: 5px;
          }
        }

        p {
          color: $black;
          @include primaryFont;
          font-size: 17px;
          font-weight: 300;
          text-decoration: none;
        }
      }

      @media (max-width: $legacy-screen-l) {
        width: 100%;
        flex-basis: 100%;
        padding: 0 40px;

        .collection-title {
          font-size: 32px;
        }
      }

      @media (min-width: $legacy-screen-l) {
        width: 50%;
        flex-basis: 50%;
        padding: 0 4% 0 8%;

        &:nth-child(even) {
          margin-top: 8%;
          padding: 0 8% 0 4%;

          .collection-title {
            left: auto;
            right: -200vh;

            span {
              transform: translate3d(-47.6%, 50%, 0) rotate(-90deg);
            }
          }
        }
      }
    }
  }

  .page-main {
    .column.main {
      width: 100%;

      > * {
        box-sizing: border-box;
      }

      .Block-08 {
        margin: 0 auto;
        max-width: 1166px;
        padding: 0 50px 120px;

        &::nth-of-type(1) {
          padding-top: 0;
        }

        @media (max-width: $legacy-screen-l) {
          padding: 45px 50px;
          padding-top: 0;

          .Item {
            &-05,
            &-06 {
              width: 100%;
              flex-basis: 100%;
              box-sizing: border-box;
            }
          }
        }
      }

      .Block-09 {
        margin: 0 auto;
        max-width: 1166px;
        padding: 0 50px 120px;

        &::nth-of-type(1) {
          padding-top: 0;
        }

        @media (max-width: $legacy-screen-l) {
          padding: 45px 50px;
          padding-top: 0;

          .Item {
            &--col_1-2 {
              width: 100%;
              flex-basis: 100%;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }

  .banner-info-push {
    .info-wrapper {
      flex: 1;

      .content {
        text-align: center;
        font-size: 1.5rem;
      }
    }
  }

  &[class*='page-layout-1column'] {
    &:not(.cms-nuestras-boutiques, .cms-our-boutiques, .cms-unsere-boutiquen, .cms-nos-boutiques) {
      .page-main {
        .column.main {
          position: relative;
          width: 100%;
          box-sizing: border-box;

          @media (max-width: $legacy-screen-l) {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  &.messika-la-maison .top-container.breadcrumbs-wrapper {
    @media (max-width: $legacy-screen-l) {
      padding-top: 10px;
      z-index: 10;
      position: relative;
    }
  }

  /* CMS SEO LANDING - TEMP */
  &.page-layout-1column-fullwidth-content {
    .hero-container {
      margin: 0 -10px;

      @media (max-width: $legacy-screen-l) {
        margin: 0 -10px 20px;
      }
    }

    .hero-infos {
      margin-bottom: 65px;
    }

    .top-container.breadcrumbs-wrapper,
    #maincontent > .page-title-wrapper {
      display: none;
    }

    .List {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .cta-button {
        + .cta-button {
          margin-top: 15px;
        }

        @media (max-width: $legacy-screen-l) {
          padding: 8px 15px;
        }
      }

      &-05 {
        margin-top: 80px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 auto;
        max-width: 1166px;

        .swiper-container {
          width: 100%;
        }

        .Item-wrapper {
          position: relative;
          box-sizing: border-box;

          @media (max-width: $legacy-screen-l) {
            overflow: initial;
          }

          a {
            display: block;
            height: 100%;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }

            &:visited {
              color: $black !important;
            }
          }

          img {
            display: block;
            transform-origin: center;
            transition: all 0.3s ease;

            &.only-mobile {
              display: none;
            }

            &:hover {
              text-decoration: none;
            }

            @media (max-width: $legacy-screen-l) {
              min-width: initial;

              &.only-desktop {
                display: none;
              }

              &.only-mobile {
                display: block;
              }
            }
          }

          &:hover {
            img {
              transform: scale(1.05);
            }

            .cta-button {
              color: $white;
              text-decoration: none;
              background: $white;

              &::before {
                transform: scaleY(1);
              }

              &.white {
                color: $black;

                a {
                  color: $black;
                }
              }
            }
          }

          .Image-container,
          .Video-container {
            position: relative;
            width: 100%;
            padding-top: 133.33%;

            img,
            iframe,
            video {
              position: relative;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .cta-button {
              padding: 5px 20px;
              font-size: 17px;
              @include primaryFont;
              font-weight: 500;
              text-transform: uppercase;
              position: absolute;
              left: 50%;
              bottom: 9.4%;
              transform: translateX(-50%);
              line-height: 24px;
              z-index: 2;
            }
          }

          .Image-wrapper,
          .Video-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            height: 100%;
          }

          .Video-ratio-wrapper,
          .js-manage-ratio {
            height: 100%;
          }

          .js-manage-ratio {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
          }

          .content-wrapper {
            text-align: center;
            padding: 25px 10px;
            @include primaryFont;
            font-weight: 300;

            @media (max-width: $legacy-screen-l) {
              padding: 20px 0;
              max-width: unset;
            }

            h2,
            h3 {
              color: $black;
              font-size: 17px;
              @include primaryFont;
              text-transform: uppercase;
              text-decoration: none;
              font-weight: 500;
              margin: 0;

              @media (max-width: $legacy-screen-l) {
                margin-bottom: 5px;
              }
            }

            p {
              color: $black;
              @include primaryFont;
              font-size: 17px;
              font-weight: 300;
              text-decoration: none;
              width: 100%;
            }
          }

          @media (max-width: $legacy-screen-l) {
            width: 100%;
            flex-basis: 100%;
            padding: 0 10px;
            margin: 0 !important;
          }

          @media (min-width: $legacy-screen-l) {
            width: 50%;
            flex-basis: 50%;
            padding: 0 2% 0 4%;

            &:nth-child(even) {
              margin-top: 8rem;
              padding: 0 4% 0 2%;
              flex-basis: 50%;
              margin-right: 0 !important;
            }

            &:nth-child(odd) {
              flex-basis: 50%;
              padding: 0 2% 0 4%;
              margin: 0 !important;

              .Image-container,
              .Video-container {
                margin-bottom: 5.2rem;
              }
            }
          }
        }

        .fake-item {
          @media (max-width: $legacy-screen-l) {
            &--mobHidden {
              display: none !important;
            }
          }
        }

        .item.fake-text-item {
          box-sizing: border-box;

          @media (max-width: $legacy-screen-l) {
            width: 100%;
            flex-basis: 100%;
            margin-bottom: 30px;
          }

          @media (min-width: $legacy-screen-l) {
            width: 50%;
            flex-basis: 48%;
            margin-bottom: 40px;

            &:nth-child(even) {
              margin-top: 8%;
            }
          }

          .text-item {
            width: 200% !important;
            position: relative;
            left: 100%;
            display: inline-block;
            transform: translateX(-50%);

            @media (max-width: $legacy-screen-l) {
              width: 100% !important;
              transform: translateX(-100%);
            }
          }

          h3.text-item {
            @include primaryFont;
            color: $black;
            text-transform: uppercase;
            font-size: 35px;
            position: relative;
            transform: translate(-50%, 0);
            text-align: center;
            width: 80vw;

            @media (max-width: $legacy-screen-l) {
              font-size: 21px;
              transform: translate(-100%, 0);
            }
          }
        }

        @media (min-width: $legacy-screen-l) {
          .swiper-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            height: auto;
          }
        }

        @media (max-width: $legacy-screen-l) {
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;
          margin-top: 65px;

          .swiper-container {
            width: 100%;
            overflow: visible;
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        flex-direction: column;
      }

      @media (min-width: $legacy-screen-l) {
      }
    }

    .Block-01 .Item-02 .Item-title {
      text-align: center;
      font-size: 60px;

      @media (max-width: $legacy-screen-l) {
        font-size: 12vw;
      }
    }

    .Block-01 .Item-02 .Item-subtitle {
      text-align: center;
    }

    .page-main {
      @media (min-width: $legacy-screen-l) {
        padding: 0 !important;
      }

      @media (max-width: $legacy-screen-l) {
        box-sizing: border-box;
      }
    }

    .widget {
      &.block {
        .Block {
          &-09 {
            width: 100%;
            max-width: 1166px;
            margin: 0 auto;
            padding: 0 105px;
            box-sizing: border-box;

            .Video-wrapper iframe {
              height: 100%;
            }

            @media (max-width: $legacy-screen-l) {
              padding: 0 10px;

              &.Block .fat-title {
                left: 0;
              }
            }
          }
        }
      }
    }

    .Block {
      &-06 {
        @media (max-width: $legacy-screen-l) {
          width: 100%;
        }
      }

      &-08 {
        .Item-07 {
          width: calc(100vw - 40px);
          padding: 0;

          @media (min-width: $legacy-screen-l) {
            padding: 0 50px;
            display: flex;
            margin: 0;
            align-items: flex-start;

            .Item {
              &-infos {
                align-self: flex-start;
              }

              &-title {
                margin-top: 0;
              }
            }
          }
        }

        @media (max-width: $legacy-screen-l) {
          padding: 0 50px !important;
        }

        @media (min-width: $legacy-screen-l) {
          padding: 0 50px !important;
          margin: 100px auto !important;
        }
      }

      &-09 {
        .Video-wrapper {
          > div[id^='video-'] {
            width: 100%;
            height: 100%;
          }
        }

        .Video,
        .Image {
          &-infos {
            padding: 15px 0;
          }
        }

        &.Block--full {
          .Video-infos,
          .Image-infos {
            p {
              margin-top: 10px;
            }

            @media (min-width: $legacy-screen-l) {
              width: 100%;
              max-width: 680px;
              box-sizing: border-box;
              padding: 25px 50px;
            }
          }

          video,
          iframe {
            object-fit: cover;
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
          }

          iframe {
            height: 56vw;
          }

          @media (min-width: $legacy-screen-l) {
            max-width: unset !important;
            padding: 0 !important;
          }

          @media (max-width: $legacy-screen-l) {
            .Video-wrapper,
            .Image-wrapper {
              padding-top: 120%;
              margin-left: -20px;
              width: calc(100% + 40px);

              img,
              video,
              .Video-container {
                object-fit: cover;
                height: 100%;
              }
            }

            .Image-wrapper {
              img {
                object-fit: cover;
                height: 100%;
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                top: 0;
                width: 100%;
              }
            }

            .Video-wrapper {
              video,
              .Video-container {
                object-fit: cover;
                height: 100%;
              }

              .Video-ratio-wrapper,
              video {
                height: 100%;
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                top: 0;
                width: 100%;
              }

              div[id^='video-'] {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    }

    .page-main {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
