.modal-notify-in-stock {
  .modal {
    max-width: 560px;
    overflow: hidden;
    height: auto;

    @media (max-width: $screen-medium) {
      padding: 30px 20px;
    }

    .modal-content {
      overflow: inherit;
    }
  }

  .modal-notify-in-stock-form {
    margin: 10px 0;

    @media (max-width: $screen-medium) {
      .text-wrapper span.error,
      .checkbox-wrapper span.error {
        bottom: -27px;
        font-size: 12px;
        height: 25px;
      }
    }

    .modal-notify-in-stock-form-title {
      margin-bottom: 24px;
      text-align: center;
      font-size: 36px;
      line-height: 33px;
    }

    .modal-notify-in-stock-form-description {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-weight: 100;
      width: 90%;
      margin: 0 auto 20px;

      p {
        font-size: 16px;
        width: 85%;
        margin: 0 auto;
        line-height: 24px;
      }
    }

    .modal-notify-in-stock-form-inputs {
      display: flex;
      flex-wrap: wrap;
      gap: 25px 10px;
      justify-content: space-between;

      & > div {
        width: 48%;

        label {
          width: 100%;
          overflow: hidden;
        }

        input,
        select {
          width: 100%;

          @media (max-width: $screen-medium) {
            font-size: 14px;
            padding: 9px 0;
          }
        }

        select {
          margin-top: 2px;

          @media (max-width: $screen-medium) {
            margin: 0;
            height: 44px;
          }
        }
      }

      .select-wrapper {
        span.error {
          bottom: -15px;
        }
      }
    }

    .modal-notify-in-stock-form-terms {
      margin-bottom: 25px;
      font-weight: 100;
      font-size: 12px;
      margin-top: 35px;

      label {
        color: $black;
        gap: 10px;
      }
    }

    .modal-notify-in-stock-form-submit {
      margin-bottom: 24px;
      text-align: center;

      button[type='submit'] {
        display: inline-block;

        &:disabled {
          cursor: default;
        }
      }
    }

    .modal-notify-in-stock-form-errorrequest {
      color: $red;
      margin-bottom: 16px;
      text-align: center;
    }

    .modal-notify-in-stock-form-condition {
      margin-bottom: 16px;
      font-size: 12px;
      font-weight: 100;
    }

    .modal-notify-in-stock-form-mandatoryfields {
      font-size: 12px;
      font-weight: 100;
    }
  }
}
