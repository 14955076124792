.customer-edit-page {
  & > :not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
  }
}

section.edit-customer-block {
  display: flex;
  flex-direction: column;
  gap: 17px;

  @media (max-width: $screen-small) {
    margin: 0;
  }

  p {
    margin: 24px 0;
    font-size: 18px;
  }

  .title-h3 {
    font-size: 50px;

    @media (max-width: $screen-medium) {
      font-size: 30.5px;
    }
  }

  .input-checkbox {
    gap: 16px;

    span.checkbox-case {
      border: 1px solid black;
    }
  }

  .bloc {
    .email-form {
      .password {
        grid-column: 1;
      }
    }

    &.id {
      margin-top: 60px;

      .title-h5 {
        margin-bottom: 33px;
      }
    }

    .title {
      display: flex;
      flex-wrap: wrap;
      gap: 60px;
      margin: 24px 0;

      @media (max-width: $screen-medium) {
        justify-content: space-between;
        gap: 20px 20px;
      }
    }

    .input-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: calc(16px * 2);

      @media (max-width: $screen-small) {
        grid-template-columns: repeat(1, 1fr);
        gap: 48px;
      }

      input {
        width: 100%;
      }

      label {
        color: $light-grey-300;
      }

      & > * {
        grid-column: span 1;
      }

      .date {
        position: relative;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $black;

        &::after {
          position: absolute;
          right: 0;
          bottom: 5px;
          content: '';
          display: block;
          width: 22px;
          height: 22px;
          padding-right: 5px;
          background-color: $white;
          background-image: url('../../icons/icon-calendar.svg');
          background-repeat: no-repeat;
          background-position: center right;
          background-size: contain;
          pointer-events: none;

          @media (max-width: $screen-small) {
            width: 19px;
            height: 19px;
          }
        }

        label {
          font-size: 12px;
          color: $light-grey-300;
          text-align: left;
        }

        input {
          border: none !important;
          color: $black;
          padding: 2px 0;
          font-size: 16px;
          position: relative;

          &:focus {
            outline: none;
          }

          @media (max-width: $screen-small) {
            font-size: 14px;
          }
        }
      }

      &--wechat {
        color: $grey;
        text-align: left;
        margin-top: calc(16px * 2);
      }
    }
  }

  .errors {
    padding: 16px 0;
    color: $red;
    text-align: left;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media (max-width: $screen-small) {
      margin-top: 30px;
    }
  }

  label.switch span.label {
    color: $black;
    font-size: 16px;
    margin-left: 11px;
  }
}
