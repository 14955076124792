.newsletter {
  h2 {
    @include primaryFont;

    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .switch {
    font-weight: 450;

    &.label {
      font-size: 18px;
    }
  }

  .info {
    margin-left: calc(34px + 15px);
    color: $grey;
    width: 73%;
    font-size: 18px;
    margin-top: 4px;
    line-height: 23px;

    span {
      margin-right: 3px;
    }

    a {
      margin-left: 5px;
    }
  }
}
