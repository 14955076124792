.form-input {
  margin: calc(16px / 2) 0;

  input {
    border: none;
    border-bottom: 1px solid $black;
    font-size: 16px;

    @media (max-width: $screen-medium) {
      font-size: 14px;
    }

    &:active {
      border: none;
    }
  }
}
