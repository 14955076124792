.message-push {
  background-color: $black;
  padding: 12px 28px;
  position: relative;

  p,
  a {
    @include primaryFont;

    color: $white;
    font-weight: 400;
  }

  a {
    text-decoration: underline;
    margin: 2px;
    display: inline-block;
  }

  .message-push-title {
    p {
      text-align: center;
      font-size: 16px;
      cursor: pointer;

      span {
        margin-right: 8px;
      }

      span,
      .message-push-arrow {
        vertical-align: middle;
      }
    }

    svg {
      stroke: $white;
    }
  }

  .message-push-content p {
    text-align: center;
    font-size: 12px;
    padding-top: 12px;
  }

  .message-push-arrow {
    transform: rotate(90deg);
    transition: all 0.1s ease;
  }

  .message-push-close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      fill: $white;
      display: block;
    }
  }

  .message-push-content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.1s ease;
  }

  &.message-push--active {
    .message-push-arrow {
      transform: rotate(-90deg);
    }

    .message-push-content {
      max-height: 50px;
    }
  }
}
