.header-navigation-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
  background-color: $white;
  z-index: 9999;

  @supports (-webkit-touch-callout: none) {
    height: stretch;
  }

  .header-navigation-mobile-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .header-navigation-mobile-close {
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 0;
    margin: 0;

    svg {
      width: 28px;
      height: 23px;
      margin-top: -5px;
    }
  }

  .header-navigation-mobile-menu {
    position: relative;
    overflow-x: hidden;
    flex: 1;
    margin-top: 20px;

    .header-navigation-mobile-menu-items {
      margin-bottom: 20px;

      li {
        padding: 0 20px;

        .header-navigation-mobile-level-submenu {
          ul:not(.header-navigation-mobile-categories-withimages) {
            > li:not(.see-all) {
              padding-left: 42px;
            }
          }
        }
      }
    }

    &:has(.header-navigation-mobile-search-results) {
      margin-top: 15px;
    }

    .header-navigation-mobile-categories-withimages {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding: 0 42px;

      li {
        padding-left: 22px;

        &:not(.header-navigation-mobile-seemore) {
          padding: 0;
        }
      }

      & > .header-navigation-mobile-category {
        width: 100%;
        margin-bottom: 0;

        &:first-child {
          margin-top: 23px;
        }
      }

      & > .header-navigation-mobile-seemore {
        width: 100%;
        margin: 20px 0;
      }

      & > .header-navigation-mobile-category-withimage {
        width: calc(50% - 10px);

        p {
          @include primaryFont;

          font-size: 15px;
          font-weight: 400;
        }

        a {
          text-decoration: none;
          color: $black;
          text-align: center;
          text-transform: uppercase;
        }

        picture {
          display: block;
          margin-bottom: 8px;
          overflow: hidden;
          background-color: $grey;
          aspect-ratio: 1/1;
          position: relative !important;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .header-navigation-mobile-pages {
    margin-bottom: 20px;
  }

  .header-navigation-mobile-contact {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &::before {
      content: '';
      display: block;
      width: calc(100% - 40px);
      margin: 0 auto 15px;
      border-top: 1px solid rgba($grey, 0.2);
    }

    a {
      text-decoration: none;
      color: $black;
      margin: 5px 0;
      padding: 0 20px;

      svg {
        margin-right: 16px;
        width: 24px;
      }

      span {
        font-size: 15px;
        font-weight: 400;
      }

      span,
      svg {
        vertical-align: middle;
      }
    }
  }

  .see-all {
    margin-bottom: 45px;

    a {
      @include primaryFont;

      text-decoration: none;
      color: $black;
      font-size: 15px;
      font-weight: 400;
      padding-left: 23px;
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px;

        path {
          stroke: $black;
        }
      }
    }
  }

  .header-navigation-mobile-search-results {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;

    .header-search-results {
      border-top: none;
    }
  }
}

.header-navigation-mobile-level:not(:last-child),
.header-navigation-mobile-item:not(:last-child),
.header-navigation-mobile-category:not(:last-child) {
  margin-bottom: 22px;
}

.header-navigation-mobile-blockcategories,
.header-navigation-mobile-categories {
  margin-top: 23px;
}

.header-navigation-mobile-blockcategories li a {
  border: none;
}

.header-navigation-mobile-level-submenu.active {
  z-index: 1;

  .header-navigation-mobile-link {
    &.bold {
      span {
        z-index: 1;
      }
    }
  }
}

.header-navigation-mobile-link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  color: $black;

  &.bold {
    font-weight: 700;
    padding: 0 20px;

    svg {
      display: block;
    }

    span {
      display: flex;
      font-weight: 500;
      padding-left: 0;
    }
  }

  span {
    @include primaryFont;

    font-size: 15px;
    font-weight: 400;
    padding-bottom: 10px;
  }

  .header-navigation-mobile-link-arrow {
    path {
      stroke: $black;
    }

    &.previous {
      transform: rotate(180deg);
      width: 15px;
      height: 15px;
      margin-left: 0;
      margin-right: 8px;
    }
  }

  &.no-arrow {
    .header-navigation-mobile-link-arrow {
      display: none;
    }
  }
}

.header-navigation-mobile-seemore {
  width: 100%;
  margin: 20px 0 40px;
  padding-left: 23px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $black;
    font-size: 15px;

    span {
      margin-right: 8px;
      font-size: 15px;
      font-weight: 400;
    }

    svg {
      height: 9px;

      path {
        stroke: $black;
      }
    }

    span,
    svg {
      vertical-align: middle;
    }
  }
}

.header-navigation-mobile-level {
  .header-navigation-mobile-level-submenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    transform: translateX(calc(100% + 24px));
    transition: all 0.1s ease;
    overflow: hidden auto;
    padding-bottom: 20px;

    &.active {
      transform: translateX(0%);
    }
  }
}
