.list-selection {
  container-type: inline-size;
  container-name: list-selection;

  .list-selection-swiper:not(.swiper-initialized) {
    .swiper-wrapper {
      gap: 8px;

      @media(min-width: $screen-medium) {
        gap: 16px;
      }
    }

    .swiper-slide {
      width: calc(50cqi - 20px);

      @media(min-width: $screen-medium) {
        width: calc(25cqi - 12px);
      }
    }
  }

}
