.customer-profil-page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  section {
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 32px;

    h2 {
      @include hx;
      @include primaryFont;

      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }
}
