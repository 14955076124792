.summary {
  width: 100%;

  .header-order {
    display: flex;
    padding: 33px 0 29px;
    justify-content: space-between;
    border-bottom: 1px solid $black-transparent-10;

    @media (max-width: $screen-small) {
      padding: 24px 0;
      gap: 3px;
      flex-direction: column;
    }

    .flex {
      @media (max-width: $screen-small) {
        margin-top: 10px;
      }
    }

    .total {
      font-size: 18px;

      @media (max-width: $screen-small) {
        font-size: 15px;
      }
    }

    .price {
      font-size: 18px;
      padding-left: 28px;
      font-weight: 600;

      @media (max-width: $screen-small) {
        font-size: 16px;
        padding-left: 26px;
      }
    }
  }

  .summary-list {
    border-bottom: 1px solid $black-transparent-10;
    padding-bottom: 15px;

    .summary-item {
      display: flex;
      margin-top: 25px;

      picture {
        width: 91px;
        height: 98px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }

      .container {
        flex-direction: column;
        width: 85%;
        display: flex;
        justify-content: center;
        margin-left: auto;

        @media (max-width: $screen-small) {
          width: 65%;
        }

        .content {
          .header-product {
            display: flex;
            justify-content: space-between;

            .price {
              font-size: 16px;
              font-weight: 400;
            }
          }

          .list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 5px;
            color: $grey;
            width: 84%;
            line-height: 20px;

            @media (max-width: $screen-medium) {
              font-size: 14px;
              width: 100%;
            }

            li {
              margin-right: 5px;
              flex: inherit;

              &:not(:last-child)::after {
                content: ',';
              }

              .space {
                margin-right: 5px;
              }
            }
          }

          .summary-price {
            font-size: 16px;
            font-weight: 400;
            margin-top: 5px;
          }
        }
      }
    }
  }

  .container-card-shiping {
    border-bottom: 1px solid $black-transparent-10;
    padding: 35px 0;
    gap: 24px;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 18px;
      font-weight: 500;

      @media (max-width: $screen-medium) {
        font-size: 15px;
      }
    }

    p {
      font-size: 16px;
      color: $grey;
      margin-top: 9px;
      line-height: 19px;

      @media (max-width: $screen-medium) {
        font-size: 15px;
        line-height: 18px;
        margin-top: 4px;
      }
    }

    .bloc {
      justify-content: space-between;

      @media (max-width: $screen-medium) {
        display: block;
      }

      .free {
        font-size: 16px;
        color: $black;

        @media (max-width: $screen-medium) {
          margin-top: 8px;
          font-size: 14px;
        }
      }
    }
  }

  .delivery-container {
    padding: 35px 0;
    gap: 28px;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 18px;
      font-weight: 500;

      @media (max-width: $screen-medium) {
        font-size: 15px;
      }
    }

    p {
      font-size: 16px;
      color: $grey;
      margin-top: 12px;

      @media (max-width: $screen-medium) {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}
