.Expander-wrapper {
  max-height: 94px;
  padding-bottom: 20px;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.Expander-container {
  position: relative;
}

.Expander.isOpened {
  .Expander-wrapper {
    max-height: none;
  }

  .js-expander-trigger {
    &::before {
      opacity: 0;
    }

    &::after {
      transform: rotate(180deg);
    }
  }
}

.Expander.Block-07 {
  p {
    font-size: 15px;
    line-height: 18px;
    color: $black;
    margin: 10px 0;
  }

  a {
    font-size: 15px;
    line-height: 18px;
    color: $black;
  }
}

.js-expander-trigger {
  cursor: pointer;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $white;

  &::before {
    position: absolute;
    content: '';
    display: block;
    bottom: 0;
    height: 45px;
    width: 100%;
    left: 0;
    background: $white;
    background: linear-gradient(
      0deg,
      rgba($white, 1) 0%,
      rgba($white, 1) 18%,
      rgba($white, 1) 40%,
      rgba($white, 0) 100%
    );
    background: linear-gradient(
      0deg,
      rgba($white, 1) 0%,
      rgba($white, 1) 18%,
      rgba($white, 1) 40%,
      rgba($white, 0) 100%
    );
    background: linear-gradient(
      0deg,
      rgba($white, 1) 0%,
      rgba($white, 1) 18%,
      rgba($white, 1) 40%,
      rgba($white, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    transition: opacity 0.3s ease-in-out;
  }

  &::after {
    content: '';
    position: relative;
    transform-origin: center;
    width: 0;
    height: 0;
    border-left: 4px solid $transparent;
    border-right: 4px solid $transparent;
    border-top: 4px solid $black;
    transition: transform 0.1s ease-in-out;
  }
}

.Block-07 {
  text-align: center;
  padding: 120px 0;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  color: $black;
  font-size: 12px;

  @media (max-width: $screen-medium) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  p {
    @include primaryFont;

    font-size: 12px;
    margin-top: 10px;
    font-weight: 300;
    display: inline-block;

    &:first-child {
      display: inline;
      margin-top: 0;
    }

    a {
      &:hover {
        color: $black;
      }
    }
  }

  a {
    color: $black;
    text-decoration: underline;
  }

  ul {
    display: inline;

    li {
      display: inline-block;
      padding: 0 7px;
      margin: 0;
    }
  }
}
