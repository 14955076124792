// STORY
.story {
  position: relative;
  text-align: center;
  background: url('../../img/messika-logo-background.png');
  background-repeat: repeat;
  padding: 84px 0;

  @media (max-width: $screen-medium) {
    padding: 56px 0;
  }

  &::before {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(0deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 65%);
  }

  article {
    position: relative;
    width: 60%;
    margin: 0 auto;

    @media (max-width: $screen-medium) {
      width: 80%;
    }
  }

  .text {
    text-align: center;
    margin-top: 14px;
    font-size: 18px;
    line-height: 31px;
    color: $grey;

    @media (max-width: $screen-medium) {
      font-size: 16px;
      line-height: 21px;
      margin-top: 10px;
    }
  }
}
