.product-template {
  position: relative;

  .product-page {
    display: flex;
    flex-flow: row wrap;
    gap: 0 90px;
    margin-top: 25px;

    @media (max-width: $screen-medium) {
      display: block;
      gap: 0;
      margin-top: 14px;
    }
  }

  // LEFT - PICTURES
  .pictures {
    flex: 1;
    display: flex;
    gap: 16px;
    flex-direction: column;
    grid-column: span 8;
    margin-left: -80px;
    width: 66.2%;
    max-width: 66.2%;

    @media (max-width: $screen-medium) {
      margin: 0;
      overflow: hidden;
      max-width: initial;
      width: auto;

      .wishlist_button {
        position: absolute;
        top: 45px;
        right: 32px;
        cursor: pointer;
        z-index: 1;
        padding: 0;
      }
    }

    .custom-slider-pagination {
      display: flex;
    }

    picture {
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        min-width: 100%;
        min-height: 100%;
        object-fit: contain;
      }
    }

    .push-tag {
      font-size: 17px;
      text-transform: uppercase;
      margin: 13px 19px 0;
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      z-index: 2;

      @media (min-width: $screen-medium) and (max-width: $screen-large) {
        left: 50px;
      }
    }

    .product-assets {
      .product-assets-list {
        display: block;

        @media (max-width: $screen-medium) {
          display: flex;
          margin-bottom: 16px;
        }

        li {
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: 15px;

            @media (max-width: $screen-medium) {
              margin: 0;
            }
          }

          &:not(:first-child) .push-tag {
            display: none;
          }
        }
      }

      .product-asset-image {
        picture {
          display: block;
          position: relative;

          img {
            display: block;
            width: 100%;
          }
        }
      }

      .product-asset-video {
        position: relative;

        picture {
          display: block;

          img {
            display: block;
            width: 100%;
          }
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: scale(2) translate(-50%, -50%);
        }
      }
    }
  }

  // RIGHT INFOS
  .product-informations {
    min-width: 33.8%;
    width: 33.8%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 14px;
    position: relative;

    @media (max-width: $screen-medium) {
      width: auto;
      margin-top: 24px;
    }

    .container-product-informations {
      position: sticky;
      top: 80px;
      padding-top: 5px;

      @media (max-width: $screen-medium) {
        position: relative;
        top: initial;
        margin: 0;
        padding-top: 0;
      }
    }

    // INFOS
    .product_configuration {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 12px;

      @media (max-width: $screen-medium) {
        margin-top: 0;
      }

      .title-h3 {
        line-height: 45px;
      }

      .block-select {
        margin-bottom: 16px;
        border-top-color: $light-grey-200;
        border-bottom-color: $light-grey-200;

        & + .block-select {
          margin-top: -25px;
        }

        .placeholder {
          text-shadow: 0 0 0.5px $black;
        }
      }

      .wishlist {
        text-align: end;
      }

      .label-price {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        color: $black;
        margin-bottom: 10px;
        margin-top: 3px;

        @media (max-width: $screen-medium) {
          margin-top: 0;
        }

        .product-subtitle {
          color: $grey;
        }

        .price {
          color: $black;
          font-weight: 600;

          .extra {
            margin-right: 3px;
            color: $grey;
            font-weight: normal;
          }
        }
      }

      .button {
        display: block;
        width: 100%;
      }

      .product-select {
        margin-bottom: 2px;
      }

      .product-select + button {
        margin-top: 12px;
      }

      .shipping {
        margin-top: 3px;
        text-align: right;
        font-size: 16px;
        color: $grey;

        @media (max-width: $screen-medium) {
          text-align: center;
          font-size: 14px;
        }
      }

      .block-select + #alma-widget {
        margin-top: 0;
      }

      #alma-widget {
        margin-top: 20px;

        > div {
          width: 100% !important;
        }
      }

      .product-configuration-maybeonstore-wording {
        font-size: 16px;
        color: $grey;
      }

      .container-infos {
        margin-bottom: 4px;
      }

      .carving-button {
        font-size: 12px;
      }

      .product_configuration-ctas {
        .button {
          font-size: 12px;
          margin-bottom: 8px;
        }

        #klarna {
          display: block;
          margin-top: 16px;
        }

        .affirm-as-low-as {
          margin-top: 16px;
        }
      }
    }

    // FORM
    .select-wrapper {
      display: flex;

      select {
        @media (max-width: $screen-medium) {
          font-size: 12px;
        }
      }

      label {
        margin-right: 0;
        white-space: nowrap;

        @media (max-width: $screen-medium) {
          font-size: 15px;
        }
      }
    }

    // ACCORDEON
    .accordion {
      margin-top: 10px;

      article {
        button {
          transition: none;
        }

        .content {
          > div,
          p:not(.rdv, .phone) {
            color: $grey;
            line-height: 1.28;
          }

          .phone {
            font-size: 18px;
            font-weight: 400;
            text-decoration: none;
            text-shadow: 0 0 0.05rem $grey;

            @media (max-width: $screen-medium) {
              font-size: 17px;
            }

            a {
              margin-left: 6px;
              text-decoration: underline;
              color: $black;
            }
          }

          .contact {
            margin-top: 8px;
            width: 80%;
            line-height: 21px;
            margin-bottom: 9px;

            @media (max-width: $screen-medium) {
              font-size: 14px;
              width: 100%;
              margin: 5px 0 16px;
            }
          }

          .contact-title,
          .rdv {
            text-align: left;
            font-size: 18px;
            font-weight: 400;
            text-decoration: underline;
            text-shadow: 0 0 0.05rem $grey;
            padding: 0;

            @media (max-width: $screen-medium) {
              font-size: 17px;
              height: auto;
            }
          }

          .contact-title {
            @include primaryFont;

            margin-bottom: 0;
          }

          .rdv {
            margin-top: 26px;
          }

          .light-text {
            line-height: 21px;
            margin-top: 8px;
          }

          button {
            margin: 21px 0 9px;
          }
        }

        > button {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 15px 0;
        }
      }

      a {
        color: $black;
        text-decoration: underline;
      }
    }

    .wishlist_button {
      cursor: pointer;
      display: block;
      margin-left: auto;
      padding: 0;

      @media (max-width: $screen-medium) {
        position: absolute;
        right: 0;
        top: -43px;
      }

      svg {
        width: 24px;
        position: relative;
        top: 2px;
      }
    }

    // GRAVURE
    .carving-recap {
      margin: 20px 0 17px;

      .carving-edit {
        display: flex;
        flex-direction: row;

        p {
          font-size: 16px;
          line-height: 26px;
        }

        button {
          margin: -3px 0 0 auto;
          padding: 0;
          font-size: 16px;
          text-transform: capitalize;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .carving-preview {
        @include primaryFont;

        width: 100%;
        background: $transparent-black-15;
        height: 40px;
        text-align: center;
        font-size: 15px;
        margin: 0 0 9px;
        border: none;
        margin-top: 5px;

        &.stick {
          @include primaryFont;

          font-size: 14px;
          margin-top: 6px;
        }

        &.english {
          font-family: $kunstler;
          font-size: 24px;
        }
      }

      .carving-desc {
        font-size: 12px;
        color: $grey;
        line-height: 14px;
        width: 79%;
      }
    }
  }

  // COMPLETE THE LOOK & RECENTLY VIEWED
  .list-cross-sell-products,
  .list-recent-viewed-products {
    margin-top: 92px;
    margin-bottom: 120px;

    @media (max-width: $screen-medium) {
      margin-top: 56px;
      margin-bottom: 64px;
    }

    .title-h3 {
      text-align: center;
    }

    .list-selection-wrapper {
      margin-top: 20px;

      @media (max-width: $screen-medium) {
        margin-top: 27px;
      }
    }
  }

  // BLOC CMS MEDIA
  .media {
    margin-top: 98px;
    margin-bottom: 120px;

    @media (max-width: $screen-medium) {
      margin-bottom: 64px;
    }

    @media (max-width: $screen-medium) {
      margin-top: 56px;
    }
  }

  // WIDGETS
  // TOTOP

  .add-to-cart {
    margin-top: 2px;

    .button {
      margin-top: 10px;
    }

    .shipping-container {
      margin-top: 10px;
    }

    .complet-options {
      font-size: 12px;
      text-align: center;
    }

    @media (max-width: $screen-medium) {
      &.sticky-add-to-cart {
        margin-top: 0;
        position: sticky;
        bottom: 0;
        left: 0;
        border-top: 1px solid $transparent-black-15;
        background-color: $white;
        width: 100%;
        z-index: 5;
        padding: 10px 17px;

        .button {
          width: 100%;
        }

        .complet-options {
          font-size: 12px;
        }

        .shipping {
          font-size: 14px;
          color: $grey;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: $screen-medium) {
    .to-top:not(.alone, .onleft) {
      bottom: 152px;
    }
  }
}

// FULL SCREEN VIEW

body.transition-fullscreen {
  .product-assets-fullscreen {
    opacity: 1;
    transform: scale(1);
  }
}

.product-fullscreen-modal {
  &.product-assets-fullscreen {
    cursor: grab;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $light-grey-100;
    z-index: 2;
    opacity: 0;
    transform: scale(0);

    .product-assets-fullscreen-close {
      position: absolute;
      top: 22px;
      right: 22px;
      z-index: 9;

      svg {
        width: 27px;
        height: 37px;
        stroke-width: 0.03rem;
        stroke: $black;
        stroke-linejoin: round;
      }
    }

    .product-assets-fullscreen-content {
      height: 100%;
    }

    @media (max-width: $screen-medium) {
      padding: 50px 0;

      .product-assets-fullscreen-close {
        top: 16px;
        right: 16px;
        z-index: 9;
        cursor: pointer;
      }
    }
  }

  .product-assets-fullscreen-carousel {
    height: 100%;
    position: relative;

    .custom-arrow-navigation {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      width: 12px;
      height: 24px;
      stroke-width: 0.07rem;
      stroke: $black;
      stroke-linejoin: round;

      &.custom-arrow-navigation-left {
        left: 30px;
        transform: translateY(-50%) rotate(180deg);
      }

      &.custom-arrow-navigation-right {
        right: 30px;
      }
    }

    .product-assets-fullscreen-carousel-swiper {
      height: 100%;
      overflow: hidden;
    }

    .product-assets-fullscreen-image,
    .product-assets-fullscreen-video {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;

      &--pointer-events-none {
        @media (min-width: $screen-medium) {
          pointer-events: none;
        }
      }
    }

    .product-assets-fullscreen-image {
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    @media (max-width: $screen-medium) {
      .product-assets-fullscreen-carousel-swiper {
        margin: 0;
      }

      .custom-arrow-navigation {
        display: none;
      }
    }
  }

  .product-assets-fullscreen-thumbnails {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    z-index: 3;

    .product-assets-fullscreen-thumbnails-list {
      display: flex;
      gap: 16px;

      .product-assets-fullscreen-thumbnails-item {
        height: 64px;
        width: 64px;
        cursor: pointer;
        border: 1px solid $black;
        opacity: 0.2;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .thumbnail-icon-play {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 16px;
          height: 16px;
        }

        &.active {
          opacity: 1;
        }
      }
    }

    @media (max-width: $screen-medium) {
      inset: auto auto 120px 50%;
      transform: translateX(-50%);
      max-height: max-content;
      max-width: calc(100% - 32px);
      overflow: auto hidden;
      z-index: 1;
      justify-content: flex-end;
      height: auto;

      .product-assets-fullscreen-thumbnails-list {
        flex-direction: row;
        max-width: 100%;

        .product-assets-fullscreen-thumbnails-item {
          flex: 0 0 64px;
        }
      }
    }
  }

  .product-assets-fullscreen-zoom-controls {
    display: flex;
    align-items: center;
    gap: 12px;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    &-slider {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0 auto;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      background: $transparent;
      border-radius: 5px;
      outline: none;
      border: none;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        background: $black;
        cursor: pointer;
        border-radius: 50%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        transform-origin: 50% 50%;
        border: none;
      }

      &::-moz-range-thumb {
        -moz-appearance: none;
        width: 16px;
        height: 16px;
        background: $black;
        cursor: pointer;
        border-radius: 50%;
        position: relative;
        border: none;
      }

      &::-ms-thumb {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        background: $black;
        cursor: pointer;
        border-radius: 50%;
        border: none;
      }

      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        width: 100%;
        height: 6px;
        background: $light-grey-400;
        border-radius: 5px;
      }

      &::-moz-range-track {
        -moz-appearance: none;
        width: 100%;
        height: 6px;
        background: $light-grey-400;
        border-radius: 5px;
      }

      &::-ms-track {
        width: 100%;
        height: 6px;
        background: $light-grey-400;
        border-radius: 5px;
      }
    }

    &.hide {
      display: none;
    }
  }
}

.product-page-main {
  #launcher {
    transition-property: opacity, top !important;

    @media (max-width: $screen-medium) {
      bottom: 165px !important;
    }
  }

  &.product-assets-fullscreen-open {
    #launcher {
      @media (max-width: $screen-medium) {
        bottom: 0 !important;
        right: -15px !important;
      }
    }
  }
}
