.customer-logout-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px;
  min-height: 40vh;

  @media (max-width: $screen-medium) {
    margin: 20px;
  }

  .customer-logout-page-title {
    font-size: 32px;
    margin-bottom: 23px;

    @include hx;
  }

  .customer-logout-page-text {
    color: $grey;
    text-align: center;
  }
}

body.has-message-push {
  .customer-logout-page {
    margin: 0 0 200px;

    @media (max-width: $screen-medium) {
      margin: 0;
    }
  }
}
