.sitemap-page {
  margin-top: 220px;

  @media (max-width: $screen-medium) {
    margin-top: 80px;
  }

  .container {
    margin-top: 40px;

    @media (max-width: $screen-medium) {
      margin-top: 30px;
    }
  }

  .container-flex {
    gap: 50px;

    @media (max-width: $screen-medium) {
      flex-wrap: wrap;
    }
  }

  .title-h3 {
    @include primaryFont;

    font-size: 40px;
    font-weight: 200;
    text-transform: none;
    text-transform: uppercase;

    @media (max-width: $screen-medium) {
      font-size: 28px;
    }
  }

  .sitemap-links {
    width: 100%;

    .title-links {
      border-bottom: 1px solid $black;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-size: 34px;
      font-weight: 500;
      float: none;
      letter-spacing: 1px;
      line-height: 32px;
      padding: 0 0 15px;
    }

    ul {
      li {
        margin-bottom: 20px;

        a {
          text-decoration: none;
          font-size: 18px;
          display: block;
          width: fit-content;
          line-height: 20px;
          color: $black;
        }
      }
    }
  }

  .general {
    display: flex;
    margin-top: 50px;

    ul {
      column-count: 3;

      @media (max-width: $screen-medium) {
        column-count: 1;
      }
    }
  }
}
