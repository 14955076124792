.default-shipping-method {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 24px;

  @media (max-width: $screen-medium) {
    margin-bottom: 10px;
  }

  a,
  span,
  label,
  p {
    font-size: 16px;
    color: $grey;

    @media (max-width: $screen-medium) {
      font-size: 14px;
    }
  }

  a {
    color: $black;
  }
}