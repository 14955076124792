.event-page-container {
  .Blog {
    &-container {
      &.thisism-container,
      &.messikatv-container {
        .Sticky-year {
          font-size: 136px;
          transform: translate3d(-34%, -50%, 0) rotate(-90deg);

          @media (max-width: $legacy-screen-l) {
            margin-left: auto;
            font-size: 68px;
            top: 16vw;
            transform: translate3d(-53%, -50%, 0) rotate(-90deg);
          }
        }
      }

      &-01 {
        position: relative;
        max-width: 1366px;
        margin: 0 auto;

        .blog-section-title {
          margin: 35px auto;
        }

        .Blog-list-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0 143px;
          width: 100%;
          box-sizing: border-box;
          position: relative;

          @media (max-width: $legacy-screen-l) {
            padding: 0 40px;
          }
        }

        .Blog-item {
          width: calc(50% - 10px);
          margin-bottom: 8px;
          transition: opacity 0.25s ease;

          @media (max-width: $legacy-screen-l) {
            width: 100%;
            margin-bottom: 40px;
          }

          .Blog-item-wrapper {
            display: flex;
            flex-direction: column;

            .Blog-item-image {
              background-color: #522323;
              width: 100%;

              a {
                display: block;
                position: absolute;
              }

              .content-wrapper {
                overflow: hidden;
              }

              img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: center;
                transition: transform 0.3s ease;
                will-change: transform;
              }
            }

            .Blog-item-video {
              background-color: #522323;
              width: 100%;

              video {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              iframe {
                position: relative;
                width: 100%;
                height: 100%;
              }
            }

            .Blog-item-info-wrapper {
              display: flex;
              position: relative;
              justify-content: center;
              flex-direction: column;
              width: 100%;
              text-align: center;
              padding: 36px 0;

              .Blog-item-type {
                font-size: 14px;
                @include primaryFont;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 10px;

                @media (max-width: $legacy-screen-l) {
                  margin-top: 30px;
                }
              }

              .Blog-item-date {
                text-transform: uppercase;
                @include primaryFont;
                font-weight: 300;
                font-size: 12px;
                letter-spacing: 0.12px;
                color: #00000080;
                position: absolute;
                top: 10px;
                right: 0;

                @media (max-width: $legacy-screen-l) {
                  top: 4px;
                }
              }

              .Blog-item-title {
                @include primaryFont;
                font-weight: 500;
                font-size: 17px;
                color: $black;
                margin: 0;
                margin-bottom: 4px;
                text-transform: uppercase;
                letter-spacing: 0.85px;
              }

              .Blog-item-description {
                font-size: 17px;
                color: $black;
                @include primaryFont;
                font-weight: 300;
              }

              @media (max-width: $legacy-screen-l) {
                padding: 23px 0;
              }
            }

            a {
              text-decoration: none;

              &:hover {
                .Blog-item-image {
                  img {
                    transform: scale(1.05);
                  }
                }

                .Blog-item-info-wrapper .Blog-item-title {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .cta-wrapper {
          margin-top: 50px;
          text-align: center;
        }

        .loading-container {
          width: 100%;

          .loader {
            display: none;
          }
        }
      }
    }
  }
}

.with-event-item-animation,
.with-messikatv-item-animation,
.with-thisism-item-animation {
  opacity: 0;
}
