.navigation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .explorer-animation .explorer-animation-bullet {
    background-color: $black;
  }

  .bloc-navigation {
    cursor: pointer;
    z-index: 3;
    transition: all 0.1s ease;
    margin: 0 20px;
    width: 45px !important;
    height: 45px !important;
    position: relative;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 23px;
    }

    &.bloc-navigation-left {
      display: block;
      left: 0;
      transform: rotate(180deg);
    }

    &.bloc-navigation-right {
      display: block;
      right: 0;
      margin-left: 10px;
    }
  }
}
