// TODO rework style, because this css is coming from legacy project
.cms-block-noheadless {
  overflow: hidden;

  picture {
    position: relative;
    display: block;
  }

  img {
    height: inherit;
    width: 100%;
    object-fit: cover;
  }

  &.collection-page-legacy .breadcrumbs {
    margin: 20px 0;
    padding: 0;
  }

  .list-selection-grid .list-selection-grid-wrapper .item {
    @media (max-width: $screen-medium) {
      margin-bottom: 10px;
    }
  }

  .Block {
    position: relative;
    box-sizing: border-box;

    .fat-title {
      @include secondaryFont;
      font-size: 64px;
      color: $black;
      position: absolute;
      bottom: 50%;
      left: -35px;
      text-transform: uppercase;
      width: 200vh;
      text-align: center;
      margin: 0;
      z-index: -1;
      transform-origin: center;
      line-height: unset;

      span {
        display: block;
        padding: 10px 0;
        transform-origin: center;
        transform: translate3d(-50.4%, 50%, 0) rotate(-90deg);
      }

      @media (max-width: $legacy-screen-l) {
        font-size: 40px;
        left: 0;
      }
    }

    .swiper-button {
      position: absolute;
      top: 50%;
      transform: rotate(0deg);
      transform-origin: center 40%;
      transition: all 0.4s ease;
      z-index: 1;

      &::before {
        display: block;
        position: relative;
        font-size: 15px;
        content: '\e913';
        font-family: icomoon !important;
        color: $black;
      }

      &-prev {
        left: -45px;
        transform: rotate(90deg);
      }

      &-next {
        right: -45px;
        transform: rotate(-90deg);
      }

      &::after {
        display: none;
      }
    }

    .swiper-slide {
      list-style: none;
    }

    .product-item {
      .title {
        color: $black;
        font-size: 17px;
        text-transform: uppercase;
        @include primaryFont;
        font-weight: 500;
      }

      .subtitle {
        color: $black;
        font-size: 16px;
      }

      .price-box,
      .price-box .price {
        font-size: 17px;
        color: $black;
        @include primaryFont;
        font-weight: 300;
      }

      &-addtoLinks {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.25s ease;
        opacity: 0;
        box-sizing: border-box;
        border: 1px solid black;
        background-color: $transparent-grey-10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 10;

        span {
          @include primaryFont;
          font-weight: 400;
          font-size: 17px;
          padding: 0 10px;
          max-width: 100%;
          display: inline-block;
          white-space: break-spaces;
        }

        .stock.unavailable {
          text-align: center;

          span {
            font-size: 18px;
            color: $black;
            @include primaryFont;
            font-weight: 500;
          }
        }

        .product-item-actions {
          max-width: 100%;

          .actions-primary {
            width: 100%;
          }
        }

        .action.primary {
          max-width: 100%;
        }
      }

      &-details {
        padding: 0 20px;
      }

      &:hover {
        .product-item-addtoLinks {
          opacity: 1;
          text-decoration: none;

          span {
            text-decoration: none;
            color: $black;
          }

          .tocart {
            span {
              color: $white;
            }
          }
        }
      }

      html[lang]:not([lang^='ar']) & {
        margin-left: 0 !important;
      }

      html[lang^='ar'] & {
        margin-right: 0 !important;
      }
    }

    @media (max-width: $legacy-screen-l) {
      .product-item {
        width: 100%;
      }
    }

    &-title {
      font-size: 68px;
      color: $black;
      text-transform: uppercase;
      text-align: center;

      @media (max-width: $legacy-screen-l) {
        font-size: 40px;
      }
    }

    &-01 {
      margin: 100px auto;
      width: 100%;
      max-width: 1100px;

      .Item {
        width: 41%;
        position: absolute;
        top: 50%;
        right: 5.8%;
        transform: translateY(-50%);
        text-align: center;

        &-subtitle {
          margin-top: 20px;
          display: block;
          text-align: left;
        }

        &-title {
          span {
            &:last-child {
              margin-left: 93px;
            }
          }
        }

        &-02 {
          .Item-title {
            z-index: 2;
            .catalog-category-view & {
              text-align: center;
              font-size: 60px;
            }
          }

          .Item-subtitle {
            .catalog-category-view & {
              text-align: center;
            }
          }
        }
      }

      @media (min-width: $legacy-screen-l) {
        .Item {
          border: 2px solid black;

          &-title {
            span {
              &:last-child {
                margin-left: 35px;
              }
            }
          }

          &-02 {
            .Item {
              &-title {
                font-size: 50px;
                z-index: 2;
              }
            }
          }
        }

        .img-container {
          width: 63%;
          margin-left: 5.8%;
        }
      }

      @media (max-width: $legacy-screen-l) {
        margin: 50px auto;
        max-width: 100%;
        padding: 0 11vw;

        .img-container {
          width: 100%;
        }

        .Item {
          position: relative;
          text-align: center;
          width: 100%;
          transform: none;
          top: auto;
          right: auto;

          &-title {
            font-size: 36px;
            width: 90%;
            text-align: center;
            padding: 30px;
            background-color: $white;
            margin: -40px auto 10px;
            margin-bottom: 0;

            span {
              margin: 0 !important;
              float: none;
              display: inline-block;
            }
          }

          &-subtitle {
            text-align: center;
            margin-top: 0;
          }

          p {
            text-align: center;
          }
        }

        .cta-button {
          margin-top: 20px;
        }
      }
    }

    &-02 {
      // block type COLLECTION home ( visual bg + desc 50% transparent on side )
      position: relative;
      width: 100%;
      padding: 0;
      margin: 100px auto;
      overflow: hidden;

      .img-container {
        position: relative;
        width: 100%;

        .content-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          .Item-title {
            margin: 14px 0;
            line-height: 1.2;
          }

          picture {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          > img,
          picture img {
            width: auto;
            height: 133%;
            max-width: unset;
            max-height: unset;
            margin-bottom: -5%;
          }
        }

        .title {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          width: auto;
          box-sizing: border-box;
          padding: 20px;
          margin: 0;
          max-width: 100%;
          font-size: 54px;
          text-transform: uppercase;
          color: rgb(0 0 0 / 70%);
          z-index: 1;

          .span {
            line-height: 1;

            &.align {
              display: inline-block;
              position: relative;
              width: 100%;

              &-l {
                text-align: left;
                padding-right: 40px;
              }

              &-r {
                text-align: right;
                padding-left: 40px;
              }
            }
          }
        }
      }

      .Item {
        position: absolute;
        top: 50%;
        left: 11%;
        transform: translateY(-50%);
        background-color: $transparent;
        z-index: 2;

        &-02 {
          .Item-title {
            z-index: 2;
            span {
              display: block;
              line-height: 1;

              &:last-child {
                margin-left: 93px;
              }
            }
          }

          .Item-title {
            font-size: 80px;
            color: $white;
          }
        }

        &-subtitle {
          font-size: 17px;
          color: $white;
          margin: 75px 0 2px;
        }

        p {
          margin-top: 0;
          width: 317px;
          color: $white;
        }

        .cta-button {
          margin: 25px 0 0 78px;

          .catalog-category-view & {
            display: none !important;
          }
        }
      }

      @media (min-width: $legacy-screen-l) {
        .only-mobile {
          display: none !important;
        }

        .Item-02 {
          padding: 0;

          .Item {
            &-title {
              color: $white;
            }

            &-subtitle {
              color: $white;
              margin-top: 30px;
            }

            p {
              color: $white;
            }
          }
        }

        .cta-button {
          display: inline-block;
          @include primaryFont;
          border: 2px solid $white;
          color: $black;
          text-transform: uppercase;
          transform: perspective(1px) translateZ(0);
          box-shadow: 0 0 1px $transparent;
          position: relative;
          transition-property: color;
          transition-duration: 0.2s;
          text-decoration: none;
          cursor: pointer;
          border-radius: 0;
          background: $white;
          font-weight: 300;
          margin: 0;
          letter-spacing: 1px;
          margin-top: 16px;
          font-size: 17px;
          text-align: center;
          transition: all 0.3s ease;
          padding: 5px 20px;

          &::before {
            content: '';
            position: absolute;
            z-index: -1;
            inset: 0;
            background-color: $black;
            transform: scaleY(0);
            transform-origin: 50% 100%;
            transition: transform 0.2s ease-out;
          }

          &:hover,
          &:active {
            color: $white;
            text-decoration: none;
            background: $black;
            border: 2px solid $white;

            &::before {
              transform: scaleY(1);
            }

            span {
              color: #fff !important;
            }
          }

          &:focus {
            background: $black;
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        .only-desktop {
          display: none !important;

          &.cta-button {
            display: none !important;
          }
        }

        margin: 50px auto;

        .img-container {
          .title {
            display: inline-block;
            color: $white;
            text-align: center;
            line-height: 1;
            font-size: 54px;
            @include secondaryFont;

            span {
              line-height: 1;
            }

            .align-l,
            .align-r {
              margin: 0;
              text-align: center;
            }
          }
        }

        .Item {
          background-color: $white;
          position: relative;
          top: auto;
          left: auto;
          transform: none;
          padding: 0 11vw;
          text-align: center;
          padding-top: 20px;

          &-title {
            display: none;
            color: $black;
          }

          &-subtitle {
            padding: 20px 0 0;
            margin: 0;
            color: $black;
          }

          p {
            padding: 10px 0;
            margin: 0;
            width: 100%;
            color: $black;
          }

          .cta-button.only-mobile {
            margin: 0;
            display: inline-block !important;
          }
        }

        .cta-button {
          &.only-mobile {
            margin-top: 20px;
          }
        }
      }
    }

    &-03 {
      // bloc type ACTU home ( visual bg + desc centered w/white bg )
      margin: 100px auto;
      overflow: hidden;

      .only-mobile {
        text-align: center;
        margin-bottom: 30px;

        .img-container {
          overflow: hidden;
        }

        .cta-button {
          margin-top: 36px;
          padding: 5px 20px;
          font-size: 17px;
          color: $black;
          text-transform: uppercase;
        }
      }

      .Item {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        min-width: 350px;
        max-width: 50%;
        text-align: center;
        background-color: rgb(255 255 255 / 85%);

        .alt-skin {
          &-01 & {
            background-color: $black;
            color: $white;
          }
        }

        &-title {
          text-align: center;
        }

        &-02 {
          @media (min-width: $legacy-screen-l) {
            padding: 29px 40px;
          }
        }
      }

      .img-container {
        position: relative;
        width: 100%;

        .content-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          picture {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          > img,
          picture img {
            width: auto;
            height: 150%;
            max-width: unset;
            max-height: unset;
            margin-bottom: -10%;
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        margin: 50px auto;
        min-width: auto;
        padding: 20px 25px;

        .Item {
          max-width: 233px;
          max-width: 80%;
          min-width: auto;
          width: 60%;
          text-align: center;

          &-title {
            font-size: 40px;
          }

          .cta-button {
            margin-top: 10px;
          }
        }
      }
    }

    &-04 {
      // bloc type LOOK home ( centered visual + side products )
      margin: 100px auto;
      max-width: 1166px;
      padding: 0 150px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;

      @media (max-width: $legacy-screen-l) {
        margin: 50px auto;
      }

      .wrapper {
        .img-container {
          background-color: #303446;
          width: 70%;
          position: relative;
          margin: 0 auto;

          img {
            position: relative;
            width: 100%;
            display: block;
          }

          a {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            text-decoration: none;
            color: $white;
            text-transform: uppercase;
            font-size: 17px;
            letter-spacing: 0.85px;
            cursor: pointer;
            @include primaryFont;
            font-weight: 500;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &.only-desktop {
          position: relative;
          width: 100%;

          .product-item {
            position: absolute;
            width: 27%;
            padding-top: 27%;
            overflow: hidden;
            z-index: 1;

            .product-image {
              &-wrapper {
                overflow: hidden;
              }

              &-photo {
                position: relative;
                display: block;
                transform: scale(1);
                transition: transform 0.2s ease;
                backface-visibility: hidden;
              }
            }

            &-info {
              width: 100%;
              position: absolute;
              height: 100%;
              top: 0;
              left: 0;
            }

            &-details {
              display: none;
            }

            &-photo {
              span {
                width: 100%;
                display: block !important;
              }
            }

            &-01 {
              bottom: 50px;
              left: 0;

              &:hover {
                .product-image-photo {
                  transform: scale(1.05);
                }
              }
            }

            &-02 {
              top: 50px;
              right: 0;

              &:hover {
                .product-image-photo {
                  transform: scale(1.05);
                }
              }
            }
          }
        }

        &.only-mobile {
          width: 100%;
          position: relative;
          padding: 0 50px;
          box-sizing: border-box;

          .swiper-container {
            overflow: inherit;
          }

          .img-container {
            margin: 0 -50px;
            width: calc(100% + 100px);
            margin-bottom: 30px;

            img {
              display: inline-block;
              width: 100%;
            }

            .rotated-title {
              z-index: 1;
              left: -23px;

              span {
                color: $white;
                transform: translate3d(-47%, 50%, 0) rotate(-90deg);
              }
            }
          }

          .cta-button {
            color: $white;
          }
        }
      }

      .product-item {
        background-color: $white;

        .product-item-addtoLinks {
          border-width: 2px;
          color: $black;

          span {
            color: $black;
          }
        }
      }

      .product {
        &s-grid {
          .product-item {
            margin-bottom: 0;
          }
        }

        &-item {
          &-photo {
            position: relative;
            width: 100%;
            padding-top: 100%;

            > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100% !important;
              height: 100%;
            }
          }
        }

        &-image {
          &-wrapper {
            padding-bottom: 0 !important;
            width: 100%;
            height: 100%;
            position: relative;
          }

          &-photo {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      @media (max-width: $legacy-screen-l) {
        padding: 0;

        .product-item-info {
          width: 100%;
          text-align: center;
        }

        .product-image {
          &-container {
            width: 100%;
          }

          &-wrapper {
            padding-bottom: 100%;
          }
        }
      }
    }

    &-05 {
      margin: 70px auto 60px;
      position: relative;
      max-width: 1100px;
      padding: 0 97px;

      .swiper-wrapper {
        justify-content: space-between;

        .swiper-slide {
          width: calc(33.33% - (40px / 3));
          box-sizing: border-box;
          margin: 0;

          @media (max-width: $legacy-screen-l) {
            width: 100%;
          }
        }
      }

      .rotated-title {
        @media (min-width: $legacy-screen-l) {
          span {
            margin: 0 !important;
          }
        }
      }

      .title--rotated-mobile {
        display: none;
      }

      .content-wrapper {
        .Item-title {
          margin: 14px 0;
          line-height: 1.2;
        }
      }

      @media (min-width: $legacy-screen-l) {
        .Block-title {
          margin-bottom: 5px;
        }
      }

      @media (max-width: $legacy-screen-l) {
        position: relative;
        width: 100vw;
        padding: 0 50px;

        .title--rotated-mobile {
          display: inline-block;
        }

        .Block-title {
          font-size: 40px;
        }

        .swiper-wrapper {
          padding: 0;
          margin: 0 auto;
          max-width: auto;

          .swiper-slide .content-wrapper {
            max-width: 100%;
          }
        }

        .fat-title {
          &.rotated-title {
            span {
              padding: 3px 0;
            }
          }
        }
      }
    }

    &-06 {
      margin: 100px auto;
      max-width: 1366px;

      .Block-title {
        margin-bottom: 45px;
        line-height: 1;
      }

      &.Block {
        .product-item {
          .title {
            margin-bottom: 10px;
            line-height: 1.2;
            margin-top: 20px;
          }

          .subtitle {
            margin-bottom: 5px;
            line-height: 1;
          }
        }
      }

      .block-content {
        position: relative;
        margin: 0 75px;
        @media (max-width: $legacy-screen-l) {
          margin: 0;
          padding: 0 50px;
        }
      }

      .swiper-container-01 {
        @media (min-width: $legacy-screen-l) {
          overflow: hidden;
        }
      }

      @media (max-width: $legacy-screen-l) {
        width: 100vw;
        margin: 50px auto;
        max-width: 100vw;
        overflow: inherit;

        .products-grid .product-item {
          width: 100%;
        }
      }

      .product {
        &s-grid {
          .product-item {
            margin-bottom: 0;
          }
        }

        &-item {
          .tocart {
            &.action.primary {
              span {
                font-size: 17px;
              }
            }
          }

          &-photo {
            position: relative;
            width: 100%;
            padding-top: 100%;

            > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100% !important;
              height: 100%;
            }
          }

          &-name {
            margin-bottom: 0;
          }

          &-title {
            line-height: 1.2;
            margin-bottom: 4px;
          }

          &-subtitle {
            line-height: 1.2;
          }

          .price-box {
            margin-top: 5px;
          }
        }

        &-image {
          &-wrapper {
            padding-bottom: 0 !important;
            width: 100%;
            height: 100%;
            position: relative;
          }

          &-photo {
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }

    &-07 {
      text-align: center;
      padding: 34px;
      width: 100%;
      margin: 0 auto;

      box-sizing: border-box;
      color: rgb(0 0 0 / 50%);
      font-size: 12px;

      p {
        font-size: 12px;
        margin-top: 10px;
        @include primaryFont;
        font-weight: 300;
        display: inline-block;

        &:first-child {
          display: inline;
          margin-top: 0;
        }

        a {
          &:hover {
            color: $black;
          }
        }
      }

      a {
        color: rgb(0 0 0 / 50%);
        text-decoration: underline;
      }

      ul {
        display: inline;

        li {
          display: inline-block;
          padding: 0 7px;
          margin: 0;
        }
      }
    }

    &-08 {
      padding: 100px 0 120px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .Item {
        &-05 {
          margin-top: 100px;
          padding: 0 0 0 91px;

          width: 42%;
          flex-basis: 42%;
          box-sizing: content-box;

          @media (min-width: $legacy-screen-l) {
            .Item-infos {
              display: none;
            }
          }

          @media (max-width: $legacy-screen-l) {
            width: 100%;
            flex-basis: 100%;
            box-sizing: border-box;
            margin: 0;
            padding: 0;

            .Item-title-01 span {
              font-size: 40px;
              line-height: 1;
              padding: 5px 0;
            }

            .Item-title {
              text-align: center;
              margin: 15px 0 2px;
            }

            .Item-infos {
              display: block;
              text-align: center;
            }
          }
        }

        &-06 {
          padding: 0 63px 0 0;

          width: 31%;
          flex-basis: 31%;
          box-sizing: content-box;

          @media (max-width: $legacy-screen-l) {
            width: 100%;
            flex-basis: 100%;
            box-sizing: border-box;
            margin: 0;
            padding: 0;

            .Item-infos {
              display: block;
            }
          }
        }

        &-07 {
          margin-top: 100px;
          padding: 0 63px;
          flex-basis: 100%;
          box-sizing: border-box;

          @media (max-width: $legacy-screen-l) {
            padding: 0;
            margin-left: -40px;
            width: calc(100vw - 20px);
            margin-top: 65px;

            .Item {
              &-infos {
                text-align: center;
              }

              &-title {
                text-align: center;
                margin: 15px 0 2px;
              }

              &-desc {
                text-align: center;
              }
            }
          }

          @media (min-width: $legacy-screen-l) {
          }
        }
      }

      .swiper {
        &-container {
          width: 100%;
        }

        @media (min-width: $legacy-screen-l) {
          &-container {
            overflow: initial;
          }

          &-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          &-slide {
            .Item {
              &-05 {
                width: 100%;
                flex-basis: 100%;
                box-sizing: border-box;
              }

              &-06 {
                width: 100%;
                flex-basis: 100%;
                box-sizing: border-box;
              }
            }

            &:first-child {
              width: 49.8%;
              flex-basis: 48%;
            }

            &:last-child {
              width: 36.37%;
              flex-basis: 36.37%;
            }

            &.full-slide {
              width: 50%;
              flex-basis: 48%;
            }
          }
        }

        @media (max-width: $legacy-screen-l) {
          &-container {
            overflow: inherit;

            .Item {
              &-05,
              &-06 {
                .Item-infos {
                  display: none;
                }
              }
            }
          }
        }
      }

      &--alt {
        .Item {
          &-05 {
            margin-top: 0;
            width: 31%;
            flex-basis: 31%;

            .category-cms & {
              width: 100%;
            }
          }

          &-06 {
            margin-top: 100px;
            width: 42%;
            flex-basis: 42%;

            .category-cms & {
              width: 100%;
            }
          }

          &-07 {
            .Item {
              &-infos {
                order: 2;
              }

              &-video {
                &-container {
                  order: 1;
                }
              }
            }
          }
        }

        @media (min-width: $legacy-screen-l) {
          .Item {
            &-05 {
              .Item-infos {
                display: block;
              }
            }

            &-06 {
              .Item-infos {
                display: none;
              }
            }
          }

          &-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          .swiper {
            &-slide {
              &:first-child {
                width: 36.37%;
                flex-basis: 36.37%;
              }

              &:last-child {
                width: 49.8%;
                flex-basis: 48%;
              }
            }
          }
        }

        @media (max-width: $legacy-screen-l) {
          .swiper-container {
            .Item {
              &-05,
              &-06 {
                .Item-infos {
                  display: none;
                }
              }
            }
          }

          .Item {
            &-05 {
              .Item-infos {
                display: block;
              }
            }

            &-06 {
              margin-top: 0;
            }

            &-07 {
              .Item {
                &-infos {
                  text-align: center;
                }

                &-title {
                  text-align: center;
                }

                &-desc {
                  text-align: center;
                }
              }
            }
          }

          .swiper {
            &-slide {
              &:first-child {
              }

              &:last-child {
              }
            }
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        display: block;
      }
    }

    &-09 {
      .Grid {
        &-wrapper {
          margin-bottom: 25px;
        }

        &-infos {
          padding: 30px 0;
          width: 80%;
          margin: 0 auto;
        }

        &-02 {
          .Item {
            &--3_4 {
              width: calc(33.333% - (28px / 3));
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              margin-top: 0;
            }

            @media (max-width: $legacy-screen-l) {
              &--3_4 {
                width: calc(33.33% - (10px / 3));
              }
            }
          }
        }

        &-03 {
          display: flex;
          justify-content: space-between;
          padding: 50px 0;

          .Item {
            margin-top: 0;
          }

          @media (max-width: $legacy-screen-l) {
            flex-direction: column;
            padding: 0 0 50px;

            .Item {
              margin-top: 20px;

              .Block-desc {
                padding: 0 20px;
              }
            }
          }
        }
      }

      .Item {
        margin-top: 14px;

        &--1_1,
        &--square {
          width: calc(50% - (14px / 2));
        }

        &--2_1 {
          width: 100%;
        }

        &--3_4 {
          width: calc(35.46% - (14px / 2));
        }

        &--305_221 {
          width: calc(64.53975% - (14px / 2));
        }

        &--col_1-2 {
          width: 48%;
          flex-basis: 48%;
        }

        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }

        @media (max-width: $legacy-screen-l) {
          margin-top: 5px;

          &--1_1,
          &--square {
            width: calc(50% - (5px / 2));
          }

          &--2_1 {
            width: 100%;
          }

          &--3_4 {
            width: calc(35.46% - (5px / 2));
          }

          &--305_221 {
            width: calc(64.53975% - (5px / 2));
          }

          &--col_1-2 {
            width: 100%;
            flex-basis: 100%;
            margin-top: 20px !important;

            &:first-child {
              margin-top: 0 !important;
            }
          }
        }
      }

      .Video,
      .Image {
        &-container {
          margin-bottom: 65px;
        }

        &-wrapper {
          max-height: calc(100vh - 55px);
          overflow: hidden;
          width: 100%;
          position: relative;

          video,
          iframe,
          img {
            position: relative;
            width: 100%;
            display: block;
          }

          iframe {
            height: 100%;
          }

          @media (max-width: $legacy-screen-l) {
            margin-left: -10px;
            width: calc(100% + 20px);
          }
        }

        &-title {
          margin: 0;
        }

        &-infos {
          padding: 25px 0;
          width: 100%;
          margin: 0 auto;
          text-align: center;

          .title,
          .Block-subtitle,
          .Item-title {
            margin: 0;
            box-sizing: border-box;
          }
        }
      }

      &.Block--full {
        .Video-wrapper {
          height: 56.5vw;
        }

        video,
        iframe {
          object-fit: cover;
          position: relative;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    &-10 {
      width: 100%;
      max-width: 1366px;
      margin: 0 auto;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      .fat-title {
        color: #0003;
        position: absolute;
        bottom: 50%;
        left: -100px;
        font-size: 136px;
        text-transform: uppercase;
        width: 100vh;
        text-align: center;
        margin: 0;
        transform-origin: center;
        transform: translate3d(-50%, 50%, 0) rotate(-90deg);
      }

      .Block {
        &-date {
          font-size: 17px;
          text-transform: uppercase;
          line-height: 1.4;
          color: $black;
          @include primaryFont;
          font-weight: 500;
          text-decoration: none;
          text-align: center;
          width: 100%;
          padding: 0 20px;

          @media (max-width: $legacy-screen-l) {
            padding: 0;
          }
        }
      }

      > .Item {
        &-08 {
          margin-top: 75px;
          width: 50%;
          padding: 0 121.5px;

          &:nth-of-type(1) {
            margin-top: 35px;
          }

          @media (max-width: $legacy-screen-l) {
            width: 100%;
            padding: 0;
            margin-top: 30px;
          }
        }
      }

      .Cols-2 {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 75px;

        .Item-08 {
          box-sizing: border-box;
          width: 50%;

          &:first-child {
            padding: 0 40px 0 203px;
          }

          &:last-child {
            padding: 0 203px 0 40px;
          }

          @media (max-width: $legacy-screen-l) {
            width: 100%;
            padding: 0 !important;

            &:last-child {
              margin-top: 20px;
            }
          }
        }

        @media (max-width: $legacy-screen-l) {
          margin-top: 30px;
          flex-direction: column;
        }
      }

      @media (max-width: $legacy-screen-l) {
        padding: 0 40px;
      }
    }

    &-11 {
      position: relative;
      width: 100%;
      max-width: 1366px;
      margin: 80px auto;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding: 0 115px;

      .fat-title {
        color: #0003;
        position: absolute;
        bottom: 50%;
        left: -18px;
        font-size: 136px;
        text-transform: uppercase;
        width: 100vh;
        text-align: center;
        margin: 0;
        z-index: -1;
        transform-origin: center;
        transform: none;

        span {
          transform-origin: center;
          transform: translate3d(-50%, 50%, 0) rotate(-90deg);
        }
      }

      > .Item {
        &-09 {
          width: calc(56.611% - 15px);

          @media (max-width: $legacy-screen-l) {
            width: 100%;
          }
        }
      }

      .Item {
        &:hover {
          img {
            transform: scale(1.05);
          }
        }

        &-image {
          &-container {
            .content-wrapper {
              overflow: hidden;
            }

            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
              display: block;
              transform-origin: center;
              transition: transform 0.3s ease;
              will-change: transform;
            }
          }
        }
      }

      .Grid {
        box-sizing: border-box;

        .swiper {
          &-wrapper {
            @media (min-width: $legacy-screen-l) {
              width: 100%;
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: stretch;
            }
          }

          &-slide {
            @media (min-width: $legacy-screen-l) {
              width: calc(50% - 15px) !important;

              > .Item {
                width: 100%;
              }

              &:nth-child(odd) {
                align-self: flex-start;
              }

              &:nth-child(even) {
                align-self: flex-end;
              }
            }
          }
        }

        @media (min-width: $legacy-screen-l) {
          position: relative;
          width: calc(43.391% - 15px);

          .swiper {
            position: relative;
            width: 100%;
            height: 100%;

            &-container-02 {
              position: relative;
              width: 100%;
              height: 100%;
            }

            &-wrapper {
              position: relative;
              width: 100%;
              height: 100%;
            }

            &-slide {
              height: auto;
            }
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        padding: 0;
        flex-direction: column;
        margin: 30px auto;

        .Item-09 {
          .Item-image-container {
            margin-top: 77px;

            .fat-title {
              transform: none;
              width: 100%;
              bottom: auto;
              top: -77px;
              z-index: -1;

              span {
                transform: none;
              }
            }
          }
        }

        .Grid {
          position: relative;
          width: 100vw;
          padding: 0 50px;

          .title--rotated-mobile {
            display: inline-block;
          }

          .Block-title {
            font-size: 40px;
          }

          .swiper-wrapper {
            padding: 0;
            margin: 0 auto;
            max-width: auto;

            .swiper-slide .content-wrapper {
              max-width: 100%;
            }
          }

          &-02 {
            .Item-10 {
              .Item--4_3 {
                &::before {
                  display: none;
                }

                .content-wrapper {
                  position: relative;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }

    &-12 {
      position: relative;
      width: 100%;
      max-width: 1366px;
      margin: 80px auto;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding: 0 115px;
      flex-direction: column;

      .fat-title {
        color: #0003;
        position: absolute;
        bottom: 50%;
        left: -18px;
        font-size: 136px;
        text-transform: uppercase;
        width: 100vh;
        text-align: center;
        margin: 0;
        z-index: -1;
        transform-origin: center;
        transform: none;

        span {
          transform-origin: center;
          transform: translate3d(-50%, 50%, 0) rotate(-90deg);
        }
      }

      > .Item {
        &-09 {
          width: 100%;

          .swiper-container-02 {
            .swiper-slide {
              -ms-flex-align: flex-start;
              align-items: flex-start;
            }
          }
        }
      }

      .Item {
        &:hover {
          img {
            transform: scale(1.05);
          }
        }

        &-image {
          &-container {
            .content-wrapper {
              overflow: hidden;
            }

            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
              display: block;
              transform-origin: center;
              transition: transform 0.3s ease;
              will-change: transform;
            }

            video,
            iframe {
              object-fit: cover;
              position: relative;
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
      }

      .Grid {
        box-sizing: border-box;

        .swiper {
          &-wrapper {
            @media (min-width: $legacy-screen-l) {
              width: 100%;
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;
              align-items: stretch;
              box-sizing: border-box;
            }
          }

          &-slide {
            @media (min-width: $legacy-screen-l) {
              width: calc(33.33% - 52px) !important;

              > .Item {
                width: 100%;
              }
            }
          }
        }

        @media (min-width: $legacy-screen-l) {
          position: relative;
          margin-top: 70px;

          .swiper {
            position: relative;
            width: 100%;
            height: 100%;

            &-container {
              position: relative;
              width: 100%;
              height: 100%;
            }

            &-wrapper {
              position: relative;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
            }

            &-slide {
              height: auto;
            }
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        padding: 0;
        flex-direction: column;
        margin: 30px auto;
        touch-action: pan-y;

        .Item-09 {
          .Item-image-container {
            margin-top: 77px;

            .fat-title {
              transform: none;
              width: 100%;
              bottom: auto;
              top: -77px;
              z-index: -1;

              span {
                transform: none;
              }
            }
          }
        }

        .Grid {
          position: relative;
          width: 100vw;
          padding: 0 50px;

          .title--rotated-mobile {
            display: inline-block;
          }

          .Block-title {
            font-size: 40px;
          }

          .swiper-wrapper {
            padding: 0;
            margin: 0 auto;
            max-width: auto;

            .swiper-slide .content-wrapper {
              max-width: 100%;
            }
          }

          &-02 {
            .Item-10 {
              .Item--4_3 {
                &::before {
                  display: none;
                }

                .content-wrapper {
                  position: relative;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }

    &-13 {
      padding: 30px;
      border: 2px solid rgb(0 0 0 / 20%);
    }

    &-14 {
      // bloc type SELECTION cms ( SELECTION OF 4 products, grid on mob )
      margin: 100px auto;
      max-width: 1366px;

      .Block-title {
        margin-bottom: 45px;
        line-height: 1;
      }

      &.Block {
        .product-item {
          .title {
            margin-bottom: 10px;
            line-height: 1.2;
            margin-top: 20px;

            @media (max-width: $legacy-screen-l) {
              margin: 0 0 5px;
            }
          }

          .subtitle {
            margin-bottom: 5px;
            line-height: 1;

            @media (max-width: $legacy-screen-l) {
              font-size: 12px;
              @include primaryFont;
              font-weight: 300;
            }
          }
        }
      }

      .block-content {
        position: relative;
        margin: 0 75px;
        @media (max-width: $legacy-screen-l) {
          margin: 0;
          padding: 0 10px;
        }
      }

      .swiper-container-01 {
        @media (min-width: $legacy-screen-l) {
          overflow: hidden;
        }
      }

      @media (max-width: $legacy-screen-l) {
        width: 100%;
        margin: 50px auto;
        max-width: 100vw;
        overflow: inherit;

        .products-grid .product-item {
          width: 100%;
        }
      }

      .product {
        &s-grid {
          .product-item {
            margin-bottom: 0;

            a:hover {
              text-decoration: none;

              .product-item-details * {
                text-decoration: none;
              }
            }
          }
        }

        &-items {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
        }

        &-item {
          height: auto;

          .tocart {
            &.action.primary {
              span {
                font-size: 17px;

                @media (max-width: $legacy-screen-l) {
                  font-size: 12px;
                }
              }
            }
          }

          .price-box {
            margin-top: 5px;

            @media (max-width: $legacy-screen-l) {
              margin: 0 0 25px;

              * {
                font-family: $futura !important;
                font-weight: 500 !important;
                font-size: 16px;
              }
            }
          }

          .stock.unavailable {
            text-align: center;

            span {
              @media (max-width: $legacy-screen-l) {
                font-size: 16px;
              }
            }
          }

          .title {
            hyphens: none;

            @media (max-width: $legacy-screen-l) {
              font-size: 16px;
              text-transform: none;
            }
          }

          &-info {
            display: flex;
            flex-direction: column;
            height: 100%;
            hyphens: none;
          }

          &-photo {
            position: relative;
            width: 100%;
            padding-top: 100%;

            > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100% !important;
              height: 100%;
            }
          }

          &-name {
            hyphens: none;
            margin-bottom: 0;

            @media (max-width: $legacy-screen-l) {
              margin: 15px 0 0;

              .title {
                margin-top: 0;
                font-size: 15px;
              }

              .subtitle {
                margin-top: 0;
                font-size: 15px;
              }
            }
          }

          &-title {
            hyphens: none;
            line-height: 1.2;
            margin-bottom: 4px;
          }

          &-subtitle {
            hyphens: none;
            line-height: 1.2;
          }

          &-details {
            text-align: center;
            flex: 1;
            display: flex;
            flex-direction: column;

            @media (max-width: $legacy-screen-l) {
              padding: 0 10px;
            }
          }

          @media (min-width: $legacy-screen-l) {
            width: 24% !important;
          }

          @media (max-width: $legacy-screen-l) {
            width: 48% !important;

            .title {
              margin-top: 0;
            }
          }
        }

        &-image {
          &-wrapper {
            padding-bottom: 0 !important;
            width: 100%;
            height: 100%;
            position: relative;
          }

          &-photo {
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }

    &-15 {
      // bloc type RELATED cms ( SELECTION OF 3 products, swiper 1 elt )
      margin: 100px auto;
      max-width: 1366px;

      .Block-title {
        margin-bottom: 45px;
        line-height: 1;
      }

      &.Block {
        .product-item {
          .title {
            margin-bottom: 10px;
            line-height: 1.2;
            margin-top: 20px;
          }

          .subtitle {
            margin-bottom: 5px;
            line-height: 1;
          }
        }
      }

      .block-content {
        position: relative;
        margin: 0 75px;

        @media (max-width: $legacy-screen-l) {
          margin: 0;
          padding: 0 20px;
        }
      }

      .swiper-container-01 {
        @media (min-width: $legacy-screen-l) {
          overflow: hidden;
        }

        @media (max-width: $legacy-screen-l) {
          overflow: visible;
        }
      }

      .product {
        &s-grid {
          .product-item {
            margin-bottom: 0;
          }

          @media (max-width: $legacy-screen-l) {
            .product-item {
              padding: 0 10px;
            }
          }
        }

        &-items {
          align-items: flex-start;

          @media (min-width: $legacy-screen-l) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            width: 100%;
          }
        }

        &-item {
          .tocart {
            &.action.primary {
              span {
                font-size: 17px;
              }
            }
          }

          .price-box {
            margin-top: 5px;
          }

          > a {
            width: 100%;

            &:hover {
              text-decoration: none;

              * {
                text-decoration: none;
              }
            }
          }

          &-photo {
            position: relative;
            width: 100%;
            padding-top: 100%;

            > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100% !important;
              height: 100%;
            }
          }

          &-name {
            hyphens: none;
            margin-bottom: 0;

            @media (max-width: $legacy-screen-l) {
              margin: px 0;
            }
          }

          &-title {
            hyphens: none;
            line-height: 1.2;
            margin-bottom: 4px;
          }

          &-subtitle {
            hyphens: none;
            line-height: 1.2;
          }

          &-details {
            text-align: center;
          }

          @media (min-width: $legacy-screen-l) {
            width: 31% !important;
          }

          @media (max-width: $legacy-screen-l) {
            .title {
              margin-top: 0;
            }
          }
        }

        &-image {
          &-wrapper {
            padding-bottom: 0 !important;
            width: 100%;
            height: 100%;
            position: relative;
            display: block;
          }

          &-photo {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            margin: auto;
            inset: 0;
          }
        }
      }

      @media (max-width: $legacy-screen-l) {
        width: 100%;
        margin: 50px auto;
        max-width: 100vw;
        overflow: inherit;

        .products-grid .product-item {
          width: 100%;
        }
      }
    }
  }

  .Block-08 {
    margin: 0 auto;
    max-width: 1166px;
    padding: 0 50px 120px;

    &::nth-of-type(1) {
      padding-top: 0;
    }

    @media (max-width: $legacy-screen-l) {
      padding: 45px 30px;
      padding-top: 0;

      .Item {
        &-05,
        &-06 {
          width: 100%;
          flex-basis: 100%;
          box-sizing: border-box;
        }
      }
    }
  }

  .Block-09 {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: $screen-medium) {
      max-width: inherit;
      padding: 0 30px;
    }

    @media only screen and (max-width: 1145px) {
      max-width: 860px;
    }

    @media (max-width: $screen-medium) {
      max-width: inherit;
      padding: 0 30px;
    }

    @media (max-width: $screen-small) {
      padding: 0 17px;
    }
  }

  .Block-title {
    @include secondaryFont;
    @media (max-width: $screen-medium) {
      line-height: 60px;
    }
  }

  .Item-07 .Item-title {
    margin: 20px 0;
  }

  .hero-description {
    color: $white;
    max-width: 420px;
    line-height: 22px;
  }

  &.page-cms-collection {
    .layout.full {
      padding: 0 20px;
      .page-main {
        margin-bottom: 50px;
      }
    }

    .item-name {
      .item-title {
        @include primaryFont;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        font-weight: 600;
        @media (max-width: $screen-medium) {
          font-size: 14.5px;
          line-height: 18px;
        }
      }
      .item-description {
        @include primaryFont;
        @include clamp;

        font-size: 16px;
        line-height: 18px;
        color: $grey;
        font-weight: 400;
      }
    }
  }

  .only-mobile {
    display: none !important;
    @media (max-width: $screen-medium) {
      display: block !important;
    }
  }

  .only-desktop {
    display: block !important;
    @media (max-width: $screen-medium) {
      display: none !important;
    }
  }

  picture {
    height: 100%;
    &:has(.only-mobile) {
      display: none;
      @media (max-width: $screen-medium) {
        display: block !important;
      }
    }
  }
}

.Blog-container-01 .Blog-item .Blog-item-wrapper .Blog-item-info-wrapper {
  .Blog-item-description,
  .Blog-item-description p,
  .Blog-item-description span {
    font-size: 17px;
    color: $black;
    @include primaryFont;
    font-weight: 100;
  }
}

.collection-page-legacy {
  margin: 0 0 70px 0;
  padding: 0;
  width: 100%;

  &:has(.benefit) {
    margin-bottom: 0;
  }

  .page-main {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  @media (max-width: $screen-medium) {
    margin: 0 0 50px 0;

    .hero-infos {
      padding: 0 15px;
      margin-bottom: 50px !important;
    }
    .hero-description {
      color: $black;
    }
  }
}
