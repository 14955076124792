.places-autocomplete {
  position: relative;

  &-list {
    z-index: 10;
    position: absolute;
    top: calc(100% + 2px);
    background: $white;
    width: 100%;
    box-shadow: 0 1px 2px $transparent-black-50;

    button {
      display: block;
      text-decoration: none;
      padding: 2px 5px;
      width: 100%;

      &:focus {
        background: $light-grey-100;
        border: none;
        outline: none;
      }
    }
  }
}
