.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  &--fullpage {
    height: 100vh;
  }

  &--small {
    .loader__container {
      height: 25px;
      width: 25px;
    }
  }

  img {
    object-fit: contain;
    max-height: 100%;
  }

  &__container {
    position: relative;
    height: 100px;
    width: 100px;
    overflow: hidden;
  }
}
