.filter-product-form {
  // ORDER BY
  .filter-product-form-menu-orderby {
    .filter-product-form-menu-orderby-button {
      span {
        font-weight: 700;
        margin-right: 5px;
        font-size: 14px;
        display: inherit;
        margin-top: -2px;
        text-transform: uppercase;
      }

      svg {
        position: relative;
        top: -1px;
        width: 16px;
        height: 16px;
      }

      span,
      svg {
        vertical-align: middle;
      }
    }

    .filter-product-form-orderby {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      flex-direction: column;
      gap: 12px;
      border: 1px solid $light-grey-200;
      z-index: 2;
      background-color: $white;

      .input-checkbox:has(input:checked) .checkbox-text {
        font-weight: 600;
      }

      label {
        span,
        input {
          vertical-align: middle;
        }

        input {
          margin: 0;
          margin-right: 10px;
        }

        span {
          font-size: 16px;
        }
      }

      input {
        appearance: none;
        width: 10px;
        height: 9px;

        &:checked {
          background-image: url('../../icons/icon-checked.svg');
        }
      }
    }

    &.open {
      .filter-product-form-orderby {
        display: flex;
        top: 39px;

        @media (max-width: $screen-medium) {
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          width: 160px;
          top: 35px;
        }
      }
    }

    .orderproducts {
      padding: 20px;
      border-bottom: 1px solid #f0f0f0;

      .orderproducts-fields {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .input-checkbox {
        gap: 10px;
      }
    }
  }

  // CHECKBOX
  .filter-product-form-fields-item {
    font-size: 14px;
    color: $black;
    margin-right: 0;
    gap: 8px;
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
    }

    input[type='checkbox'] {
      margin: 0;
      margin-right: 16px;
      appearance: none;
      width: 10px;
      height: 9px;

      &:checked {
        background-image: url('../../icons/icon-checked.svg');
        margin-left: 30px;
        margin-right: 8px;
      }
    }
  }

  .filter-product-form-pricecontroller {
    max-width: 400px;
    margin: 0 auto;

    .filter-product-form-price-controls {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;

      .price-control-wrapper {
        width: calc(50% - 8px);
        border: 1px solid $light-grey-200;
        padding: 8px;
        display: flex;
        align-items: center;

        input[type='number'] {
          width: 100%;
          border: none;
          appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
          }
        }
      }
    }

    .filter-product-form-rangeprice {
      position: relative;
      width: 98%;
      height: 7px;

      input[type='range'] {
        position: absolute;
        width: 100%;
        appearance: none;
        height: 1px;
        background-color: $light-grey-200;
        box-shadow: none;
        pointer-events: none;

        &.filter-product-form-rangeprice-from {
          z-index: 1;
          height: 0;
          top: 0.5px;
        }

        &::-ms-track {
          background: $transparent;
          border-color: $transparent;
          color: $transparent;
          cursor: pointer;
          width: 100%;
        }

        &::-webkit-slider-thumb {
          appearance: none;
          cursor: pointer;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $black;
          box-shadow: none;
          pointer-events: all;
        }

        &::-moz-range-thumb {
          border: none;
          cursor: pointer;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $black;
          box-shadow: none;
          pointer-events: all;
        }

        &::-ms-thumb {
          border: none;
          cursor: pointer;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $black;
          box-shadow: none;
          pointer-events: all;
        }
      }
    }
  }

  .orderproducts {
    padding: 20px;
    border-bottom: 1px solid #f0f0f0;

    .orderproducts-fields {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: $screen-medium) {
        span.checkbox-case {
          border: none !important;
        }
      }
    }
  }

  .filter-research-product-form {
    .custom-accordion {
      h5 {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
      }

      button {
        padding: 20px 24px;
      }

      article.open {
        .content {
          margin: 20px 24px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        gap: 20px;
      }

      &.custom-accordion-price {
        .filter-product-form-pricecontroller {
          max-width: 100%;
          margin: 0;
        }
      }
    }
  }

  .resume-selected-filters {
    padding-bottom: 20px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 20px;

    .resume-selected-filters-list {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .resume-selected-filters-item {
        padding: 4px 8px;
        border: 1px solid $light-grey-200;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .resume-selected-filters-item-title {
          font-size: 16px;
          font-weight: 700;
          margin-right: 10px;
        }

        .resume-selected-filters-item-value {
          font-size: 16px;
          display: flex;
          align-items: center;

          svg {
            width: 16px;
            height: 16px;
            display: block;
          }

          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
    }

    .resume-selected-filters-button {
      width: 100%;
      margin-bottom: 10px;

      .button {
        width: 100%;
      }
    }
  }

  // DESKTOP
  .filter-product-form-desktop {
    .filter-product-form-menu {
      padding: 0 80px;
      border-bottom: 1px solid $light-grey-200;
      display: flex;
      justify-content: space-between;

      .filter-product-form-menu-right {
        display: flex;
        align-items: center;
        position: relative;

        .filter-product-form-nbarticle {
          font-size: 14px;
          font-weight: 400;
          color: $grey;

          &:not(:last-child) {
            padding-right: 24px;
            margin-right: 8px;
            border-right: 1px solid #ccc;
          }
        }

        .filter-product-form-menu-orderby {
          padding-left: 16px;
        }
      }
    }

    .filter-product-form-menu-filterby {
      display: flex;
      align-items: center;

      .filter-product-form-menu-filterby-title {
        padding-right: 16px;
        color: $black;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .filter-product-form-menu-filterby-availableonline {
        padding: 0 16px;
        display: inline-flex;
        align-items: center;
        gap: 16px;
        font-size: 14px;
        text-transform: uppercase;

        // workaround
        label,
        span {
          font-size: 14px;
          margin-right: 0;
        }

        label {
          padding-top: 8px;
        }
      }

      .filter-product-form-menu-filterby-item {
        position: relative;
        padding: 20px 16px;
        color: $black;
        border-left: 1px solid $transparent;
        border-right: 1px solid $transparent;

        span,
        svg {
          vertical-align: middle;
        }

        span {
          margin-right: 12px;
          font-size: 14px;
          text-transform: uppercase;
        }

        svg {
          transition: all 0.1s ease;
          width: 16px;
          height: 16px;
          stroke: $black;

          path {
            stroke: $black;
          }
        }

        &.active {
          border-left-color: $light-grey-200;
          border-right-color: $light-grey-200;

          &::after {
            position: absolute;
            content: ' ';
            bottom: -1px;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: $white;
          }

          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }

    .filter-product-form-viewfields {
      overflow: hidden;

      &[data-open='true'] {
        padding: 20px 0;
        border-bottom: 1px solid $light-grey-200;
      }

      .filter-product-form-fields {
        display: flex;
        flex-flow: column wrap;
        align-content: flex-start;
        gap: 20px 100px;
        max-height: 200px;
      }
    }

    .filter-product-form-selectedfields {
      padding: 20px 24px;
      border-bottom: 1px solid $light-grey-200;

      .filter-product-form-selectedfields-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 24px;

        .filter-product-form-selectedfields-item {
          display: flex;
          align-items: center;
          gap: 8px;

          p {
            font-size: 14px;
            text-transform: uppercase;
          }

          button {
            svg {
              display: block;
              width: 12px;
              height: 12px;

              path {
                stroke: $light-grey-200;
              }
            }

            &:hover {
              svg {
                path {
                  stroke: $black;
                }
              }
            }
          }
        }

        .filter-product-form-selectedfields-clear {
          font-size: 14px;
          text-transform: uppercase;
        }
      }
    }

    .input-checkbox {
      input {
        &:checked + span + span {
          font-weight: 500;
        }

        &:checked + span.checkbox-case {
          display: block;
        }
      }
    }
  }

  // MOBILE
  .filter-product-form-mobile {
    .filter-product-form-menu {
      display: flex;
      border-bottom: 1px solid #f0f0f0;

      button {
        width: 50%;
        padding: 16px 0;
        color: $black;

        &:only-child {
          width: 100%;
        }

        span {
          font-size: 14px;
          font-weight: 700;
          margin-right: 24px;
          text-transform: uppercase;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        span,
        svg {
          vertical-align: middle;
        }

        &.filter-product-form-filterby-button {
          &:not(:last-child) {
            border-right: 1px solid #f0f0f0;
          }
        }
      }
    }

    .filter-product-form-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-bottom: 46px;
      background-color: $white;
      z-index: 999999;
      overflow: auto;

      .filter-product-form-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        padding: 24px;
        border-bottom: 1px solid #f0f0f0;

        .filter-product-form-modal-header-title {
          flex: 1;
          font-weight: 700;
          text-transform: uppercase;
        }

        .filter-product-form-modal-header-actions {
          span {
            margin-right: 12px;
          }

          span,
          button {
            vertical-align: middle;
          }
        }

        .filter-product-form-modal-header-close {
          svg {
            display: block;
          }
        }
      }
    }

    .filterproducts {
      .filterproducts-availableonline {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $light-grey-200;
        padding: 20px 24px;

        p {
          font-size: 14px;
          text-transform: uppercase;
        }

        // workaround
        label,
        span {
          margin-right: 0;
        }
      }

      // accordion module
      .accordion {
        .title-h5 {
          margin: 0;
          font-weight: 400;
          font-size: 14px;
        }

        button {
          padding: 20px 24px;
        }

        article.open {
          .content {
            margin: 20px 24px 40px;
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            gap: 20px;
          }
        }
      }

      .filter-product-form-fields {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        gap: 20px;
      }
    }

    .filterproducts-buttons {
      position: fixed;
      bottom: 0;
      width: 100%;
      display: flex;
      background-color: $white;

      & > div {
        width: 50%;

        button {
          width: 100%;
          padding: 16px;
        }
      }
    }

    .filterproducts-buttons-delete {
      .button:disabled {
        border-color: $grey;
        background-color: $white;
        color: $grey;
      }
    }

    .filter-product-form-fields-item {
      gap: 12px;
    }

    .input-checkbox {
      span.checkbox-case {
        border: 1px solid $black;
      }
    }

    .filter-product-form-pricecontroller {
      .filter-product-form-rangeprice {
        height: 20px;

        input[type='range'] {
          &::-webkit-slider-thumb {
            width: 15px;
            height: 15px;
          }

          &::-moz-range-thumb {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
