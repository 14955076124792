// TODO rework style, because this css is coming from legacy project

#cms-services-hub,
#cms-services-achat,
#cms-services-list {
  width: 100%;
  max-width: 1174px;
  margin: 0 auto;
  margin-bottom: 48px;
  text-align: left;

  .Title {
    font-size: 40px;
    @include primaryFont;
    font-weight: 300;
    line-height: 1.1;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: $legacy-screen-l) {
      font-size: 32px;
    }
  }

  .Desc {
    font-size: 16px;
    @include primaryFont;
    font-weight: 300;
  }

  .Link {
    display: inline-block;

    &-01 {
      @include primaryFont;
      font-weight: 400;
      text-align: left;
      padding: 0;
      margin-bottom: 30px;
      font-size: 20px;
      padding-left: 30px;
      position: relative;
      color: $black;
      text-decoration: none;
      text-transform: uppercase;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 36px;
        line-height: 18px;
        background-image: url('../../icons/icon-nav-arrow-right.svg');
        content: '';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
        color: $black;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: $legacy-screen-l) {
        font-size: 2.2rem;
      }
    }
  }

  .Item {
    display: block;

    &-title {
      @include secondaryFont;
      line-height: 1.1;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 40px;
      color: $white;
      margin: 0;
    }
  }

  .List {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    &-01 {
      li {
        flex-basis: 45%;
        list-style: none;

        @media (max-width: $legacy-screen-l) {
          margin-bottom: 5rem;
        }
      }

      .Item {
        display: block;

        &-infos {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          padding: 10%;
          top: 0;
          left: 0;
          flex-direction: column;
        }

        &-title {
          @include secondaryFont;
          line-height: 1.1;
          font-weight: 300;
          text-transform: uppercase;
          font-size: 40px;
          color: $white;
          margin: 0;

          @media (max-width: $legacy-screen-l) {
            font-size: 36px;
          }
        }

        &-visual {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          opacity: 0.8;

          &-wrapper {
            width: 100%;
            padding-top: 100%;
            background-color: $black;

            @media (max-width: $legacy-screen-l) {
              padding-top: 59%;
            }
          }
        }

        &:hover {
          .cta-button {
            color: $black;
            background-color: transparent !important;

            &::before {
              transform: scaleY(1);
              background-color: $white !important;
            }
          }
        }
      }

      .cta-button {
        @media (max-width: $legacy-screen-l) {
          padding: 8px 15px;
        }
      }
    }

    &-02 {
      list-style: none;
      flex-direction: column;

      .Item {
        display: flex;
        justify-content: space-between;

        &-visual {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;

          &-wrapper {
            position: relative;
            width: 38.31%;
            padding-top: 32.27%;
          }
        }

        &-infos {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 57.75%;
          text-align: left;

          @media (max-width: $legacy-screen-l) {
            align-items: center;
          }
        }

        &-title {
          @include primaryFont;
          font-weight: 400;
          text-align: left;
          color: $black;
          padding: 0;
          margin-bottom: 30px;
          font-size: 20px;

          @media (max-width: $legacy-screen-l) {
            font-size: 2.2rem;
          }
        }

        .cta-button {
          margin-top: 30px;
          line-height: 1.2;
          &:hover {
            color: $white;
          }
          &.isoW {
            width: 70%;

            @media (max-width: $legacy-screen-l) {
              width: 100%;
            }
          }
        }

        @media (max-width: $legacy-screen-l) {
          flex-direction: column;

          &-visual {
            &-wrapper {
              width: 100%;
              padding-top: 57.5%;
            }
          }

          &-infos {
            width: 100%;
            text-align: center;
          }

          .Desc {
            text-align: center;
            font-size: 19px;
          }

          &-title {
            text-align: center;
            margin: 30px 0;
            font-size: 2.2rem;
          }

          .cta-button {
            margin-top: 30px;
            width: 100%;
            letter-spacing: 0;
          }
        }
      }

      li {
        width: 100%;
        margin-bottom: 100px;

        &:nth-child(even) {
          .Item {
            @media (max-width: $legacy-screen-l) {
              flex-direction: column;
            }

            @media (min-width: $legacy-screen-l) {
              flex-direction: row-reverse;
            }
          }
        }

        @media (max-width: $legacy-screen-l) {
          margin-bottom: 80px;
        }
      }
    }

    .cta-button {
      + .cta-button {
        margin-top: 15px;
      }

      @media (max-width: $legacy-screen-l) {
        padding: 8px 15px;
      }
    }

    @media (max-width: $legacy-screen-l) {
      flex-direction: column;
    }
  }
}

#cms-services-hub {
  text-align: center;

  .Desc {
    width: 100%;
    max-width: 560px;
    display: inline-block;
  }
}
