.cms-block-noheadless {
  .Grid {
    &-wrapper {
      width: 100%;
      position: relative;
    }

    &-infos {
      padding: 30px 0;
      width: 80%;
      margin: 0 auto;
      text-align: center;

      .Item-title,
      .Block-subtitle,
      .title {
        box-sizing: border-box;
        margin: 0;
      }
    }

    &-01 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      .fat-title {
        width: 147% !important;

        @media (max-width: $legacy-screen-l) {
          width: 100% !important;
          position: relative !important;

          span {
            transform: none !important;
          }
        }
      }
    }

    &-02 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      .fat-title {
        width: 87% !important;

        @media (max-width: $legacy-screen-l) {
          width: 100% !important;
          position: relative !important;

          span {
            transform: none !important;
          }
        }
      }
    }
  }
}
